import React , { useState, useEffect } from 'react';

import { 
  Navbar, Container, Row,
  UncontrolledDropdown, DropdownToggle, 
  DropdownMenu, DropdownItem, Col,  
} from 'reactstrap';

import Account from '../icons/account.svg';
import Bars from '../icons/menu.svg';
import './header.css';
import logo from './logo_white.svg';

const Header = (props) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  let UserName = sessionStorage.getItem('userId');

  const setShowSide = props.setShowSide;

  let itms = [
    {label: 'Profile', command:() => profile()},
    {label: 'Change Password', command:() => changepassword()},
    {label: 'Notifications', command:() => notifications()},
    {label: 'Settings', command:() => settings()},
    {label: 'Logout', command:() => logout()},
  ];

  useEffect(() => {
    setShowSide(true); let list = JSON.parse(sessionStorage.getItem('roleJson'));

    if(list){
      if(list.menu['profile'].enabled===false){
        itms = itms.splice(0, 1);
      }else if(list.menu['changepassword'].enabled===false){
        itms = itms.splice(1, 1);
      }else if(list.menu['notifications'].enabled===false){
        itms = itms.splice(2, 1);
      }else if(list.menu['settings'].enabled===false){
        itms = itms.splice(3, 1);
      }
    };
  }, [setShowSide]);

  const handleSidebar = () => {
    if (!sidebarOpen) {
      setSidebarOpen(true); setShowSide(false);
    } else {
      setSidebarOpen(false); setShowSide(true);
    }
  };

  const localSiteData = JSON.parse(sessionStorage.getItem('id'));

  const logout = () => {
    sessionStorage.clear(); 
    props.history.push('/login');
  };

  const changepassword = () => {
    props.history.push('/changepassword');
  };

  const notifications = () => {
    props.history.push('/notifications');
  };

  const settings = () => {
    props.history.push('/settings');
  };

  const profile = () => {
    props.history.push(`/profile/${localSiteData}`);
  };

  return (
    <Navbar className="headerbody position-fixed px-3" style={sidebarOpen ? {boxShadow: '237px 2.5px 13px 0px rgba(0,0,0,0.26)'} : {boxShadow: '6px 2.5px 13px 0px rgba(0,0,0,0.26)'}}>
      <Container style={{margin: 0, padding: 0}}>
        <Row style={{ width: '99vw' }}>
          <Col xs={4} className="d-flex justify-content-start align-items-center">
            <div
              className='d-flex align-item-center'
              onClick={handleSidebar} 
            >
              <img src={Bars} style={{ color: "white", width: '26px', height: '24px' }} />
            </div>
          </Col>

          <Col xs={4}>
            <div className='text-center' onClick={() => props.history.push('/home')}>
              <img src={logo} style={{ width: '130px', height: 'auto' }} alt="logo" />
            </div>
          </Col>

          <Col xs={4} className="d-flex align-items-center justify-content-end">
            {/* <div onClick={() => setMenu(!menu)}>
              <div className="nav_username">
                <span style={{cursor: 'pointer'}}>{UserName}</span>
                <img 
                  src={Account} 
                  style={{ color:'#fff', width: '25px', height: '20px', marginLeft: '8px', cursor: 'pointer' }}
                />
              </div>
            </div> */}

            <UncontrolledDropdown
              className="me-2"
              direction="down"
            >
              <DropdownToggle
                caret
              >
              <div className="nav_username">
                <span style={{cursor: 'pointer'}}>{UserName}</span>
                <img 
                  src={Account} 
                  style={{ color:'#fff', width: '35px', height: '30px', marginLeft: '8px', cursor: 'pointer' }}
                />
              </div>
              </DropdownToggle>

              <DropdownMenu>
                {itms && itms.map((it, idx) => <DropdownItem key={idx+it.label} onClick={it.command}>
                  {it.label}
                </DropdownItem>)}
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
        </Row>
      </Container>
    </Navbar>
  )
};

export default Header;