import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    COUPON_LIST, ADD_COUPON, EDIT_COUPON,
    COUPON_PRIVACY_LIST, COUPON_TYPE_LIST,
    COUPON_VALUE_LIST, END_USERS_DROPDOWN_LIST,
    USER_COUPON_HISTORY, COUPONS_PLATFORM,
    Products_LIST, CATEGORIES_LIST_DROPDOWN
} from '../../config/config.js';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Form, Field } from 'react-final-form';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { MultiSelect } from 'primereact/multiselect';
import { classNames } from 'primereact/utils';
import { Paginator } from 'primereact/paginator';

import Add from '../icons/add.svg';
import Edit from '../icons/edit.svg';
import View from '../icons/view.svg';
import Delete from '../icons/delete.svg';

// import { Row, Col } from "react-bootstrap";
import { Container, Row, Col } from 'reactstrap';

let initialvalues = {
    Coupon_Name: '',
    Coupon_Code: '',
    Coupon_Value_Type: '',
    Coupon_Value: '',
    Coupon_Type: '',
    Coupon_Privacy_Type: '',
    User_List: '',
    Coupon_Catogories: '',
    Coupon_Products: '',
    Coupon_Platform_Type: '',
    Coupon_Min_Order_Value: '',
    Coupon_Max_Discount: '',
    Start_Date: '',
    End_Date: '',
    Discription: '',
    Trems_Conditions: ''
};

const Coupons = () => {
    let mainId = JSON.parse(sessionStorage.getItem('id'));

    const [tableData, setTableData] = useState([]);
    const [filter, setFilter] = useState('');
    const [load, setLoad] = useState(false);
    const UserId = JSON.parse(sessionStorage.getItem('id'));
    const [Coupon_Type_List, setCOUPON_TYPE_LIST] = useState();
    const [Coupon_Privacy_List, setCOUPON_PRIVACY_LIST] = useState([]);
    const [Coupon_Value_Type_List, setCOUPON_VALUE_TYPE_LIST] = useState([])
    const [Coupon_Catogories_List, setCoupon_Catogories_List] = useState([])
    const [Coupon_Products_List, setCoupon_Products_List] = useState([]);
    const [Coupon_Platform_Type_List, setCoupon_Platform_Type_List] = useState([])
    const [UserList, setUserList] = useState([])
    const [formData, setFormData] = useState({});
    const [Coupon_Privacy_Typeflag, setCoupon_Privacy_Typeflag] = useState(false);
    const [Coupon_Type_Categoriesflag, setCoupon_Type_Categoriesflag] = useState(false);
    const [Coupon_Type_Productsflag, setCoupon_Type_Productsflag] = useState(false);
    const [SelectedCoupondata, setSelectedCoupondata] = useState({});
    const [Coupon_Value_Type_Value, setCoupon_Value_Type_Value] = useState('');
    const [Coupon_Type_Value, setCoupon_Type_Value] = useState('');
    const [Coupon_Privacy_Type_Value, setCoupon_Privacy_Type_Value] = useState();
    const [Coupon_Platform_Type_Value, setCoupon_Platform_Type_Value] = useState();
    const [Selected_edit_ID, setSelected_edit_ID] = useState();
    const [Selected_edit_CouponCode, setSelected_edit_CouponCode] = useState();
    const [Transactionsdata, setTransactionsdata] = useState();
    const [editFlag, seteditFlag] = useState(false);
    const [DeleteFlag, setDeleteFlag] = useState(false);
    const [Viewflag, setViewflag] = useState(false);
    const [AddFlag, setAddFlag] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [first1, setFirst1] = useState(0);
    const [rows1, setRows1] = useState(10);
    const [TableTotalCount, setTableTotalCount] = useState(10);
    const [amnt, setAmnt] = useState(false);

    const onCustomPage1 = (event) => {
        setFirst1(event.first);
        setRows1(event.rows);
        setCurrentPage(event.page);
        getCouponData(event.page, filter, event.rows);
    };

    const template1 = {
        layout: 'RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport',
        'RowsPerPageDropdown': (options) => {
          const dropdownOptions = [
            { label: 10, value: 10 },
            { label: 25, value: 25 },
            { label: 50, value: 50 },
            { label: 100, value: 100 },
          ];
    
          return (
            <React.Fragment>
              <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
            </React.Fragment>
          );
        },
        'CurrentPageReport': (options) => {
          return (
            <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '150px', textAlign: 'center' }}>
              {options.first} - {options.last} of {options.totalRecords}
            </span>
          )
        }
    };

    useEffect(() => {
        setLoad(true); getCouponData(currentPage, filter, rows1);
        getCouponTypeData(); getCouponPrivacyData();
        getCouponValueTypeData();
        getEnduserData(); getProductData();
        getCategoryData(); getCouponsPlatformData();
    }, []);

    const tableHeader = () => {
        return <div className='DataTable'>
            <div className='m-2'>
                <h3> Coupons List</h3>
            </div>

            <div className='table-header-action'>
                <InputText className='m-2' value={filter} onChange={(e) => setFilter(e.target.value)} placeholder='Search...' />
                <Button variant="contained" className="table-button m-2" onClick={() => { setAddFlag(true); seteditFlag(false) }} icon={<img src={Add} className="mx-2" style={{width: '25px', height:'20px'}} />} >ADD</Button>
            </div>
        </div>
    };

    const actionHeader = (row) => {
        return <div className='d-flex flex-row '>
            <img
                src={View}
                className="mx-1 edit_icon"
                alt='View Image'
                onClick={() => { getCouponViewData(row.ID) }}
            />
            <img
                src={Edit}
                className="mx-1 edit_icon"
                alt='Edit Image'
                onClick={() => { handleEdit(row); seteditFlag(true) }}
            />
            <img
                src={Delete}
                className="mx-1 edit_icon_delete"
                alt='Delete Image'
                onClick={() => handleDelete(row)}
            />
        </div>
    };

    const StartDateBody = (row) => {
        let ds = new Date(row.START_DATE);
        var strArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        var d = ds.getDate();
        var m = strArray[ds.getMonth()];
        var y = ds.getFullYear();
        return '' + (d <= 9 ? '0' + d : d) + '-' + m + '-' + y;
    };

    const EndDateBody = (row) => {
        let ds = new Date(row.END_DATE);
        var strArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        var d = ds.getDate();
        var m = strArray[ds.getMonth()];
        var y = ds.getFullYear();
        return '' + (d <= 9 ? '0' + d : d) + '-' + m + '-' + y;
    };

    const CouponCodeBody = (row) => {
        return <React.Fragment>
            <div className={`toop-${row.index}`} style={{ cursor: 'pointer' }}>{row.COUPON_CODE.length > 15 ? row.COUPON_CODE.substring(0, 15 - 3) + "..." : row.COUPON_CODE}</div>
        </React.Fragment>
    };

    const CouponNameBody = (row) => {
        return <React.Fragment>
            <div className={`toop-${row.index}`} style={{ cursor: 'pointer' }}>{row.COUPON_NAME.length > 15 ? row.COUPON_NAME.substring(0, 15 - 3) + "..." : row.COUPON_NAME}</div>
        </React.Fragment>
    };

    const CouponTypeBody = (row) => {
        return <React.Fragment>
            <div className={`toop-${row.index}`} style={{ cursor: 'pointer' }}>{row.COUPON_TYPE.length > 15 ? row.COUPON_TYPE.substring(0, 15 - 3) + "..." : row.COUPON_TYPE}</div>
        </React.Fragment>
    };

    const CouponPlatformBody = (row) => {
        return <React.Fragment>
            <div className={`toop-${row.index}`} style={{ cursor: 'pointer' }}>{row.COUPON_PLATFORM.length > 15 ? row.COUPON_PLATFORM.substring(0, 15 - 3) + "..." : row.COUPON_PLATFORM}</div>
        </React.Fragment>
    };

    const CouponValueBody = (row) => {
        return <React.Fragment>
            {row.COUPON_VALUE_TYPE == 'PERCENTAGE' ?
                <div className={`toop-${row.index}`} style={{ cursor: 'pointer' }}> {row.COUPON_VALUE} %</div> :
                <div className={`toop-${row.index}`} style={{ cursor: 'pointer' }}> ₹ {row.COUPON_VALUE}</div>
            }
        </React.Fragment>
    };

    const CouponMinOrderBody = (row) => {
        return <div>{'₹' + ' ' + row.MIN_ORDER_VALUE}</div>
    };

    const CouponMaxDiscountBody = (row) => {
        return <div>{'₹' + ' ' + row.MAX_DISCOUNT}</div>
    };

    const CouponTypeValueData = (type, row) => {
        let convertarray = JSON.parse("[" + row + "]")
        let obj;
        if (type == "CATEGORIES") {
            obj = Coupon_Catogories_List.filter(it => {
                return convertarray.includes(it.ID)
            })
        } else {
            obj = Coupon_Products_List.filter(it => {
                return convertarray.includes(it.ID)
            })
        }
        return <div className='float-left'>
            {obj.map(items => {
                return (
                    <React.Fragment>
                        {type == "CATEGORIES" ?
                            <p className='m-0'>{items.CATEGORY + ","}</p> :
                            <p className='m-0'>{items.PRODUCT_NAME + ","}</p>
                        }
                    </React.Fragment>
                )
            })}
        </div>
    };

    const couponUserList = (row) => {
        let convertarray = JSON.parse("[" + row + "]");

        let obj = UserList.filter(it => {
            return convertarray.includes(it.ID);
        });
        
        return <div>
            {obj.map(items => {
                return (
                    <p className='m-0 pr-1 float-left'>{items?.FIRST_NAME + ' ' + items?.LAST_NAME}{','}</p>
                )
            })}
        </div>
    };

    const DeleteCoupon = () => {
        axios({
            method: 'DELETE',
            url: EDIT_COUPON + Selected_edit_ID,
        }).then((res) => {
            handleClose();
            getCouponData(currentPage, filter, rows1);
        }).catch((err) => {
            console.log(err);
        })
    };

    const handleDelete = (row) => {
        setDeleteFlag(true);
        setSelected_edit_CouponCode(row.COUPON_CODE);
        setSelected_edit_ID(row.ID);
    };

    const viewnameBody = (row) => {
        return row.LAST_NAME !== '' ? row.FIRST_NAME + " " + row.LAST_NAME : row.FIRST_NAME;
    };

    const dateBody = (row) => {
        let ds = new Date(row.CREATED_AT);

        var strArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        var d = ds.getDate();
        var m = strArray[ds.getMonth()];
        var y = ds.getFullYear();
        var hr = ds.getHours();
        var min = ds.getMinutes();
        var sec = ds.getSeconds();

        return '' + (d <= 9 ? '0' + d : d) + '-' + m + '-' + y + '  ' + (hr < 10 ? '0' + hr : hr) + ':' + (min < 10 ? '0' + min : min) + ':' + (sec < 10 ? '0' + sec : sec);
    };

    // coupon list datta
    const getCouponData = (page, filter, row) => {
        axios({
            method: 'get',
            url: COUPON_LIST + '?type=list&limit='+row+'&skip='+(page+1)+(filter!=='' && '&search='+filter),
        }).then((res) => {
            setTableData(res.data.data.rows); setLoad(false); 
            setTableTotalCount(res.data.data.total_count);
        }).catch((err) => {
            console.log(err);
        });
    };

    // coupon View data
    const getCouponViewData = (ID) => {
        axios({
            method: 'GET',
            url: COUPON_LIST + '/' + ID,
        }).then((res) => {
            // console.log(res);
            setSelectedCoupondata(res.data.data);
            GetCoupontransactiondata(ID)
        }).catch((err) => {
            console.log(err);
        });
    };

    // coupon Transaction History list
    const GetCoupontransactiondata = (ID) => {
        setViewflag(true);
        axios({
            method: 'GET',
            url: USER_COUPON_HISTORY + ID,
        }).then((res) => {
            setTransactionsdata(res.data.data.rows);
        }).catch((err) => {
            console.log(err);
        });
    };

    // coupon privacy list
    const getCouponPrivacyData = () => {
        axios({
            method: 'get',
            url: COUPON_PRIVACY_LIST,
        }).then((res) => {
            // console.log('coupon privacy: ', res.data);
            setCOUPON_PRIVACY_LIST(res.data);
        }).catch((err) => {
            console.log(err);
        });
    };

    // coupon type list
    const getCouponTypeData = () => {
        axios({
            method: 'get',
            url: COUPON_TYPE_LIST,
        }).then((res) => {
            // console.log('coupon type: ', res.data);
            setCOUPON_TYPE_LIST(res.data);
            // console.log(res)
        }).catch((err) => {
            console.log(err);
        });
    };

    // coupon paltform list data
    const getCouponsPlatformData = () => {
        axios({
            method: 'get',
            url: COUPONS_PLATFORM,
        }).then((res) => {
            // console.log('coupon Platform List: ', res.data);
            setCoupon_Platform_Type_List(res.data);
        }).catch((err) => {
            console.log(err);
        });
    };

    // coupon value list Data
    const getCouponValueTypeData = () => {
        axios({
            method: 'get',
            url: COUPON_VALUE_LIST,
        }).then((res) => {
            // console.log('coupon value: ', res.data);
            setCOUPON_VALUE_TYPE_LIST(res.data);
            // setCVTArr(res.data);
        }).catch((err) => {
            console.log(err);
        });
    };

    // End User List Data
    const getEnduserData = () => {
        axios({
            method: 'GET',
            url: END_USERS_DROPDOWN_LIST
        }).then((res) => {
            // console.log('User List: ', res.data);
            setUserList(res.data);
        }).catch((err) => {
            console.log(err);
        });
    };

    // Product List Data
    const getProductData = () => {
        axios({
            method: 'GET',
            url: Products_LIST
        }).then((res) => {
            // console.log('Product List: ', res.data);
            setCoupon_Products_List(res.data);
        }).catch((err) => {
            console.log(err);
        });
    };

    // Category List Data
    const getCategoryData = () => {
        axios({
            method: 'GET',
            url: CATEGORIES_LIST_DROPDOWN
        }).then((res) => {
            // console.log('Category List: ', res.data);
            setCoupon_Catogories_List(res.data);
        }).catch((err) => {
            console.log(err);
        });
    };

    const handleClose = () => {
        initialvalues = null;
        setAddFlag(false); setDeleteFlag(false);
        setCoupon_Type_Categoriesflag(false);
        setCoupon_Type_Productsflag(false);
        setCoupon_Privacy_Typeflag(false); setAmnt(false);
    };

    const handleEdit = (row) => {
        setAddFlag(true);
        setSelected_edit_CouponCode(row.COUPON_CODE);
        setSelected_edit_ID(row.ID);
        let COUPONS_TYPE_VALUES_CATEGORIES = []
        let COUPONS_TYPE_VALUES_PRODUCTS = []
        let COUPON_PRIVACY_TYPE_VALUES = []
        if (row.COUPON_TYPE == "CATEGORIES") {
            COUPONS_TYPE_VALUES_CATEGORIES = JSON.parse("[" + row.COUPON_TYPE_VALUES + "]")
        } else if (row.COUPON_TYPE == "PRODUCTS") {
            COUPONS_TYPE_VALUES_PRODUCTS = JSON.parse("[" + row.COUPON_TYPE_VALUES + "]")
        } else {
            COUPONS_TYPE_VALUES_CATEGORIES = []
            COUPONS_TYPE_VALUES_PRODUCTS = []
        }
        if (row.COUPON_PRIVACY_TYPE = "PRIVATE") {
            COUPON_PRIVACY_TYPE_VALUES = JSON.parse("[" + row.USER_IDS + "]")
        }
        initialvalues = {
            Coupon_Name: row.COUPON_NAME,
            Coupon_Code: row.COUPON_CODE,
            Coupon_Value_Type: row.COUPON_VALUE_TYPE_ID,
            Coupon_Value: row.COUPON_VALUE,
            Coupon_Type: row.COUPON_TYPE_ID,
            Coupon_Privacy_Type: row.COUPON_PRIVACY_TYPE_ID,
            User_List: COUPON_PRIVACY_TYPE_VALUES,
            Coupon_Catogories: COUPONS_TYPE_VALUES_CATEGORIES,
            Coupon_Products: COUPONS_TYPE_VALUES_PRODUCTS,
            Coupon_Platform_Type: row.COUPON_PLATFORM_ID,
            Coupon_Min_Order_Value: row.MIN_ORDER_VALUE,
            Coupon_Max_Discount: row.MAX_DISCOUNT,
            Start_Date: new Date(row.START_DATE),
            End_Date: new Date(row.END_DATE),
            Discription: row.DESCRIPTION,
            Trems_Conditions: row.TERMS_AND_CONDITIONS,
        };
        // console.log(initialvalues);
        // console.log('edit: ', row);
        // console.log('editflag: ', editFlag);
    };

    const validate = (data) => {
        let errors = {};
        // console.log(data);
        if (!data.Coupon_Name) {
            errors.Coupon_Name = 'Coupon Name is required.';
        }
        if (!data.Coupon_Code) {
            errors.Coupon_Code = 'Coupon Code is required.';
        }
        if (!data.Coupon_Value_Type) {
            errors.Coupon_Value_Type = 'Coupon Value Type is required.';
        } else if (data.Coupon_Value_Type) {
            let obj = Coupon_Value_Type_List.filter(it => it.ID == data.Coupon_Value_Type);
            setCoupon_Value_Type_Value(obj[0].LOOKUP_NAME);

            if(obj[0].LOOKUP_NAME === 'AMOUNT'){
                setAmnt(true);
            }else{
                setAmnt(false);
            }
        }
        if (!data.Coupon_Value) {
            errors.Coupon_Value = 'Coupon Value is required.';
        }
        if (!data.Coupon_Type) {
            errors.Coupon_Type = 'Coupon Type is required.';
        } else if (data.Coupon_Type) {
            let obj = Coupon_Type_List.filter(it => it.ID == data.Coupon_Type);
            setCoupon_Type_Value(obj[0].LOOKUP_NAME);

            if (Coupon_Type_Value == "CATEGORIES") {
                setCoupon_Type_Categoriesflag(true);
                setCoupon_Type_Productsflag(false);
                formData.Coupon_Products = '';
                if (!data.Coupon_Catogories) {
                    errors.Coupon_Catogories = 'Coupon Catogories is required.';
                }
            } else if (Coupon_Type_Value == "PRODUCTS") {
                setCoupon_Type_Productsflag(true);
                setCoupon_Type_Categoriesflag(false);
                formData.Coupon_Catogories = '';
                if (!data.Coupon_Products) {
                    errors.Coupon_Products = 'Coupon Products is required.';
                }
            } else {
                setCoupon_Type_Categoriesflag(false);
                setCoupon_Type_Productsflag(false);
                formData.Coupon_Products = '';
                formData.Coupon_Catogories = '';
            }
        }
        if (!data.Coupon_Privacy_Type) {
            errors.Coupon_Privacy_Type = 'Coupon Privacy Type is required.';
        } else if (data.Coupon_Privacy_Type) {
            let obj = Coupon_Privacy_List.filter(it => it.ID == data.Coupon_Privacy_Type);
            setCoupon_Privacy_Type_Value(obj[0].LOOKUP_NAME);
            if (Coupon_Privacy_Type_Value == 'PRIVATE') {
                setCoupon_Privacy_Typeflag(true);
                if (!data.User_List) {
                    errors.User_List = 'Coupon User List is required.';
                }
            } else if (Coupon_Privacy_Type_Value == "PUBLIC") {
                setCoupon_Privacy_Typeflag(false);
                data.User_List = "";
            } else {
                setCoupon_Privacy_Typeflag(false);
                data.User_List = "";
            }
        }
        if (!data.Coupon_Platform_Type) {
            errors.Coupon_Platform_Type = 'Coupon Platform type is required.';
        } else if (data.Coupon_Value_Type) {
            let obj = Coupon_Platform_Type_List.filter(it => it.ID == data.Coupon_Platform_Type);
            setCoupon_Platform_Type_Value(obj[0].LOOKUP_NAME);
        }
        if (!data.Coupon_Min_Order_Value) {
            errors.Coupon_Min_Order_Value = 'Coupon Min Order value is required.';
        }
        if (amnt===false && !data.Coupon_Max_Discount) {
            errors.Coupon_Max_Discount = 'Coupon Max Discount is required.';
        }else if (amnt===false && data.Coupon_Max_Discount) {
            data.Coupon_Max_Discount = data.Coupon_Value;
        }
        if (!data.Start_Date) {
            errors.Start_Date = 'Coupon Start Date is required.';
        }
        if (!data.End_Date) {
            errors.End_Date = 'Coupon End Date is required.';
        }
        if (!data.Discription) {
            errors.Discription = 'Coupon Description is required.';
        }
        if (!data.Trems_Conditions) {
            errors.Trems_Conditions = 'Coupon Terms & Conditions is required.';
        }

        return errors;
    };

    const onSubmit = (data, form) => {
        const Coupon_Type_Values = [];
        const Coupon_User_List = [];
        if (data.Coupon_Catogories && data.Coupon_Catogories.length > 0) {
            data.Coupon_Catogories.map(items => {
                return Coupon_Type_Values.push(items);
            })
        } else if (data.Coupon_Products && data.Coupon_Products.length > 0) {
            data.Coupon_Products.map(items => {
                return Coupon_Type_Values.push(items);
            })
        }
        if (data.User_List && data.User_List.length > 0) {
            data.User_List.map(items => {
                return Coupon_User_List.push(items);
            })
        }
        
        const Coupons_Data = {
            'COUPON_NAME': data.Coupon_Name,
            'COUPON_CODE': data.Coupon_Code,
            'COUPON_VALUE_TYPE_ID': data.Coupon_Value_Type,
            'COUPON_VALUE_TYPE': Coupon_Value_Type_Value,
            'COUPON_VALUE': parseInt(data.Coupon_Value),
            'COUPON_PRIVACY_TYPE_ID': data.Coupon_Privacy_Type,
            'COUPON_PRIVACY_TYPE': Coupon_Privacy_Type_Value,
            'COUPON_TYPE_ID': data.Coupon_Type,
            'COUPON_TYPE': Coupon_Type_Value,
            'COUPON_TYPE_VALUES': `${Coupon_Type_Values.toString()}`,
            'MIN_ORDER_VALUE': parseInt(data.Coupon_Min_Order_Value),
            'MAX_DISCOUNT': amnt===false ? parseInt(data.Coupon_Max_Discount) : parseInt(data.Coupon_Value),
            'IS_USED': false,
            'USER_IDS': `${Coupon_User_List.toString()}`,
            'START_DATE': new Date(data.Start_Date),
            'END_DATE': new Date(data.End_Date),
            'DESCRIPTION': data.Discription,
            'TERMS_AND_CONDITIONS': data.Trems_Conditions,
            'CREATED_BY': UserId,
            'UPDATED_BY': UserId,
            'COUPON_PLATFORM_ID': data.Coupon_Platform_Type,
            'COUPON_PLATFORM': Coupon_Platform_Type_Value
        }

        // console.log(Coupons_Data); 
        setFormData(data);

        if (editFlag) {
            EditCoupons(Coupons_Data)
        } else {
            AddCoupons(Coupons_Data)
        }
        // console.log(data);
        // setShowMessage(true);
        form.restart();
    };

    const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
    
    const getFormErrorMessage = (meta) => {
        return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
    };

    const AddCoupons = (CouponAddData) => {
        axios({
            method: 'POST',
            url: ADD_COUPON,
            data: CouponAddData
        }).then((res) => {
            // console.log(res);
            handleClose();
            getCouponData(currentPage, filter, rows1);
        }).catch((err) => {
            console.log(err);
        });
    };

    const EditCoupons = (CouponsEditData) => {
        CouponsEditData.UPDATED_BY = Selected_edit_ID;
        axios({
            method: 'PUT',
            url: EDIT_COUPON + Selected_edit_ID,
            data: CouponsEditData
        }).then((res) => {
            // console.log(res);
            handleClose();
            getCouponData(currentPage, filter, rows1);
        }).catch((err) => {
            console.log(err);
        });
    };
    
    return (
        <div className="pt-4 m-3">
            <div className='datatable'>
                <DataTable
                    removableSort scrollable scrollHeight='64vh'
                    size='medium' globalFilter={filter} header={tableHeader}
                    loading={load} value={tableData} responsiveLayout="scroll"
                >
                    <Column header='Coupon Code' field='COUPON_CODE' className=' text-truncate' sortable filterMaxlength={2} body={CouponCodeBody} />
                    <Column header='Coupon Name' field='COUPON_NAME' className=' text-truncate' sortable body={CouponNameBody} />
                    <Column header='Coupon Type' field='COUPON_TYPE' className=' text-truncate text_wrap_180' sortable body={CouponTypeBody} />
                    <Column header='Coupon value' field='COUPON_VALUE' className=' text-truncate' sortable body={CouponValueBody} />
                    <Column header='Coupon Platform' field='COUPON_PLATFORM' className=' text-truncate text_wrap_180' sortable body={CouponPlatformBody} />
                    <Column header='Min Order' field='MIN_ORDER_VALUE' className=' text-truncate' body={CouponMinOrderBody} sortable />
                    <Column header='Max Discount' field='MAX_DISCOUNT' className=' text-truncate' body={CouponMaxDiscountBody} sortable />
                    {/* <Column header='Description' field='DESCRIPTION' sortable /> */}
                    <Column header='Start Date' field='START_DATE' className=' text-truncate' body={StartDateBody} sortable />
                    <Column header='End Date' field='END_DATE' className=' text-truncate' body={EndDateBody} sortable />
                    <Column header='Action' body={actionHeader} />
                </DataTable>

                <Paginator rows={rows1} first={first1} totalRecords={TableTotalCount} template={template1} onPageChange={onCustomPage1} />
            </div>

            {/* View Coupon */}
            <Dialog visible={Viewflag} onHide={() => setViewflag(false)}
                header={<div><h5>View Coupon</h5></div>}
                style={{ width: '65vw', height: '80vh' }}
            >
                <Container>
                    <Row>
                        <Col xs={4} sm={3}>
                            Coupon Code
                        </Col>
                        <Col xs={2} sm={1}>
                            :
                        </Col>
                        <Col xs={6} sm={8}>
                            {SelectedCoupondata.COUPON_CODE}
                        </Col>

                        <Col xs={4} sm={3}>
                            Coupon Name
                        </Col>
                        <Col xs={2} sm={1}>
                            :
                        </Col>
                        <Col xs={6} sm={8}>
                            {SelectedCoupondata.COUPON_NAME}
                        </Col>

                        <Col xs={4} sm={3}>
                            Coupon Type
                        </Col>
                        <Col xs={2} sm={1}>
                            :
                        </Col>
                        <Col xs={6} sm={8}>
                            {SelectedCoupondata.COUPON_TYPE}
                        </Col>
                        {SelectedCoupondata.COUPON_TYPE_VALUES &&
                            <React.Fragment>
                                <Col xs={4} sm={3}>
                                    Coupon type values
                                </Col>
                                <Col xs={2} sm={1}>
                                    :
                                </Col>
                                <Col xs={6} sm={8}>
                                    {CouponTypeValueData(SelectedCoupondata.COUPON_TYPE, SelectedCoupondata.COUPON_TYPE_VALUES)}
                                </Col>
                            </React.Fragment>
                        }
                        <Col xs={4} sm={3}>
                            Coupon Privacy Type
                        </Col>
                        <Col xs={2} sm={1}>
                            :
                        </Col>
                        <Col xs={6} sm={8}>
                            {SelectedCoupondata.COUPON_PRIVACY_TYPE}
                        </Col>

                        {SelectedCoupondata.USER_IDS &&
                            <React.Fragment>
                                <Col xs={4} sm={3}>
                                    User List
                                </Col>
                                <Col xs={2} sm={1}>
                                    :
                                </Col>
                                <Col xs={6} sm={8}>
                                    {couponUserList(SelectedCoupondata.USER_IDS)}
                                </Col>
                            </React.Fragment>
                        }
                        <Col xs={4} sm={3}>
                            Coupon value
                        </Col>
                        <Col xs={2} sm={1}>
                            :
                        </Col>
                        <Col xs={6} sm={8}>
                            {SelectedCoupondata.COUPON_VALUE_TYPE == 'PERCENTAGE' ?
                                <div> {SelectedCoupondata.COUPON_VALUE} %</div> :
                                <div> ₹ {SelectedCoupondata.COUPON_VALUE}</div>
                            }
                        </Col>

                        <Col xs={4} sm={3}>
                            Coupon Platform
                        </Col>
                        <Col xs={2} sm={1}>
                            :
                        </Col>
                        <Col xs={6} sm={8}>
                            {SelectedCoupondata.COUPON_PLATFORM}
                        </Col>

                        <Col xs={4} sm={3}>
                            Minimum Order
                        </Col>
                        <Col xs={2} sm={1}>
                            :
                        </Col>
                        <Col xs={6} sm={8}>
                            {SelectedCoupondata.MIN_ORDER_VALUE}
                        </Col>

                        <Col xs={4} sm={3}>
                            Maximum Discount
                        </Col>
                        <Col xs={2} sm={1}>
                            :
                        </Col>
                        <Col xs={6} sm={8}>
                            {SelectedCoupondata.MAX_DISCOUNT}
                        </Col>

                        <Col xs={4} sm={3}>
                            Start Date
                        </Col>
                        <Col xs={2} sm={1}>
                            :
                        </Col>
                        <Col xs={6} sm={8}>
                            {SelectedCoupondata.START_DATE}
                        </Col>

                        <Col xs={4} sm={3}>
                            End Date
                        </Col>
                        <Col xs={2} sm={1}>
                            :
                        </Col>
                        <Col xs={6} sm={8}>
                            {SelectedCoupondata.END_DATE}
                        </Col>
                        <Col xs={4} sm={3}>
                            Description
                        </Col>
                        <Col xs={2} sm={1}>
                            :
                        </Col>
                        <Col xs={6} sm={8}>
                            {SelectedCoupondata.DESCRIPTION}
                        </Col>

                        <Col xs={4} sm={3}>
                            Terms & Conditions
                        </Col>
                        <Col xs={2} sm={1}>
                            :
                        </Col>
                        <Col xs={6} sm={8}>
                            {SelectedCoupondata.TERMS_AND_CONDITIONS}
                        </Col>
                    </Row>
                </Container>
                <h5 className='mt-3 mx-2'>Coupon Transactions</h5>
                <div className='datatable'>
                    <DataTable removableSort scrollable
                        size='medium' loading={load} value={Transactionsdata}>
                        <Column field='FIRST_NAME' header='Customer Name' body={viewnameBody} />
                        <Column field='ORDER_ID' header='Order ID' />
                        <Column field='CREATED_BY' header='Used Date' body={dateBody} />
                    </DataTable>
                </div>
            </Dialog>

            {/* Add / Edit Coupon */}
            <Dialog
                visible={AddFlag} style={{ width: '70vw', height: '100vh' }} onHide={handleClose}
                header={<div><h5>{!editFlag ? 'Add Coupon' : `Edit Coupon - ${Selected_edit_CouponCode}`}</h5></div>}
                maximizable
            >
                <section>
                    <Form onSubmit={onSubmit} initialValues={initialvalues} validate={validate} render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit} className="p-fluid row">
                            <div className='col-sm-6 colmd-6'>
                                <Field name="Coupon_Name" render={({ input, meta }) => (
                                    <div className="field">
                                        {/* <span className="p-label"> */}
                                        <label htmlFor="Coupon_Name" className={'block'}> <small>Coupon Name <sup className='color_danger'>*</sup></small></label>
                                        <InputText id="Coupon_Name" {...input} autoFocus className={classNames({ 'p-invalid': isFormFieldValid(meta) })} />
                                        {/* </span> */}
                                        <small className='fs-12'>{getFormErrorMessage(meta)}</small>
                                    </div>
                                )} />
                            </div>
                            <div className='col-sm-6 colmd-6'>
                                <Field name="Coupon_Code" render={({ input, meta }) => (
                                    <div className="field">
                                        <label htmlFor="Coupon_Code" className={'block'}><small>Coupon Code <sup className='color_danger'>*</sup></small></label>
                                        <InputText id="Coupon_Code" {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} />
                                        <small className='fs-12'>{getFormErrorMessage(meta)}</small>
                                    </div>
                                )} />
                            </div>
                            <div className='col-sm-6 colmd-6'>
                                <Field name="Coupon_Value_Type" render={({ input, meta }) => (
                                    <div className="field">
                                        <label htmlFor="Coupon_Value_Type" className={'block'}><small>Coupon Value Type <sup className='color_danger'>*</sup></small></label>
                                        <Dropdown id="Coupon_Value_Type" {...input}
                                            options={Coupon_Value_Type_List} optionLabel="LOOKUP_DISPLAY_NAME" optionValue='ID'
                                            className={classNames({ 'p-invalid': isFormFieldValid(meta) })} 
                                        />
                                        <small className='fs-12'>{getFormErrorMessage(meta)}</small>
                                    </div>
                                )} />
                            </div>
                            <div className='col-sm-6 colmd-6'>
                                <Field name="Coupon_Value" render={({ input, meta }) => (
                                    <div className="field">
                                        <label htmlFor="Coupon_Value" className={'block'}><small>Coupon Value<sup className='color_danger'>*</sup></small></label>
                                        <InputText id="Coupon_Value" {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} />
                                        <small className='fs-12'>{getFormErrorMessage(meta)}</small>
                                    </div>
                                )} />
                            </div>
                            <div className='row pr-0'>
                                <div className='col-sm-6 col-md-6 pr-6'>
                                    <Field name="Coupon_Type" render={({ input, meta }) => (
                                        <div className="field">
                                            <label htmlFor="Coupon_Type" className={'block'}><small>Coupon Type <sup className='color_danger'>*</sup></small></label>
                                            <Dropdown id="Coupon_Type" {...input}
                                                options={Coupon_Type_List} optionLabel="LOOKUP_DISPLAY_NAME" optionValue='ID'
                                                className={classNames({ 'p-invalid': isFormFieldValid(meta) })}
                                            />
                                            <small className='fs-12'>{getFormErrorMessage(meta)}</small>
                                        </div>
                                    )} />
                                </div>
                                {Coupon_Type_Categoriesflag &&
                                    <div className='col-sm-6 colmd-6 pl-1rem pr-0'>
                                        <Field name="Coupon_Catogories" render={({ input, meta }) => (
                                            <div className="field">
                                                <label htmlFor="Coupon_Catogories" className='block'><small>Coupon Catogories <sup className='color_danger'>*</sup> </small></label>
                                                <MultiSelect id="Coupon_Catogories" {...input} filter
                                                    options={Coupon_Catogories_List} optionLabel="CATEGORY" optionValue='ID'
                                                    className={'multiselect-custom' && classNames({ 'p-invalid': isFormFieldValid(meta) })}
                                                />
                                                <small className='fs-12'>{getFormErrorMessage(meta)}</small>
                                            </div>
                                        )} />
                                    </div>
                                }
                                {Coupon_Type_Productsflag &&
                                    <div className='col-sm-6 colmd-6 pl-1rem pr-0'>
                                        <Field name="Coupon_Products" render={({ input, meta }) => (
                                            <div className="field">
                                                <label htmlFor="Coupon_Products" className={'block'}><small>Coupon Products<sup className='color_danger'>*</sup>  </small></label>
                                                <MultiSelect id="Coupon_Products" {...input} filter
                                                    options={Coupon_Products_List} optionLabel="PRODUCT_NAME" optionValue='ID'
                                                    className={'multiselect-custom' && classNames({ 'p-invalid': isFormFieldValid(meta) })} />
                                                <small className='fs-12'>{getFormErrorMessage(meta)}</small>
                                            </div>
                                        )} />
                                    </div>
                                }
                            </div>
                            <div className='row pr-0'>
                                <div className='col-sm-6 col-md-6 pr-6'>
                                    <Field name="Coupon_Privacy_Type" render={({ input, meta }) => (
                                        <div className="field">
                                            <label htmlFor="Coupon_Privacy_Type" className={'block'}><small>Coupon Privacy Type<sup className='color_danger'>*</sup></small></label>
                                            <Dropdown id="Coupon_Privacy_Type" {...input}
                                                options={Coupon_Privacy_List} optionLabel="LOOKUP_DISPLAY_NAME" optionValue='ID'
                                                className={classNames({ 'p-invalid': isFormFieldValid(meta) })} />
                                            <small className='fs-12'>{getFormErrorMessage(meta)}</small>
                                        </div>
                                    )} />
                                </div>
                                {Coupon_Privacy_Typeflag &&
                                    <div className='col-sm-6 col-md-6 pl-1rem pr-0'>
                                        <Field name="User_List" render={({ input, meta }) => (
                                            <div className="field">
                                                <label htmlFor="User_List" className={'block'}><small>User List<sup className='color_danger'>*</sup> </small></label>
                                                <MultiSelect id="User_List" {...input} filter
                                                    options={UserList} optionLabel="USER_NAME" optionValue='ID'
                                                    className={'multiselect-custom' && classNames({ 'p-invalid': isFormFieldValid(meta) })} />
                                                <small className='fs-12'>{getFormErrorMessage(meta)}</small>
                                            </div>
                                        )} />
                                    </div>
                                }
                            </div>
                            <div className='col-sm-6 colmd-6'>
                                <Field name="Coupon_Min_Order_Value" render={({ input, meta }) => (
                                    <div className="field">
                                        <label htmlFor="Coupon_Min_Order_Value" className={'block'}><small>Coupon Min Order Value <sup className='color_danger'>*</sup></small></label>
                                        <InputText id="Coupon_Min_Order_Value" {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} />
                                        <small className='fs-12'>{getFormErrorMessage(meta)}</small>
                                    </div>
                                )} />
                            </div>
                            <div className='col-sm-6 colmd-6'>
                                <Field name="Coupon_Max_Discount" render={({ input, meta }) => (
                                    <div className="field">
                                        <label htmlFor="Coupon_Max_Discount" className={'block'}><small>Coupon Max Discount {amnt===false && <sup className='color_danger'>*</sup>}</small></label>
                                        <InputText id="Coupon_Max_Discount" {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} disabled={amnt} />
                                        <small className='fs-12'>{getFormErrorMessage(meta)}</small>
                                    </div>
                                )} />
                            </div>
                            <div className='col-sm-6 colmd-6'>
                                <Field name="Start_Date" render={({ input, meta }) => (
                                    <div className="field">
                                        <label htmlFor="Start_Date" className={'block'}><small>Start Date <sup className='color_danger'>*</sup></small></label>
                                        <Calendar id="Start_Date" {...input}
                                            dateFormat="dd/mm/yy" mask="99/99/9999" showIcon minDate={new Date()}
                                            className={classNames({ 'p-invalid': isFormFieldValid(meta) })} />
                                        <small className='fs-12'>{getFormErrorMessage(meta)}</small>
                                    </div>
                                )} />
                            </div>
                            <div className='col-sm-6 colmd-6'>
                                <Field name="End_Date" render={({ input, meta }) => (
                                    <div className="field">
                                        <label htmlFor="End_Date" className={'block'}><small>End Date<sup className='color_danger'>*</sup></small></label>
                                        <Calendar id="End_Date" {...input} minDate={new Date()}
                                            dateFormat="dd/mm/yy" mask="99/99/9999" showIcon
                                            className={classNames({ 'p-invalid': isFormFieldValid(meta) })} />
                                        <small className='fs-12'>{getFormErrorMessage(meta)}</small>
                                    </div>
                                )} />
                            </div>
                            <div className='col-sm-6 colmd-6'>
                                <Field name="Discription" render={({ input, meta }) => (
                                    <div className="field">
                                        <label htmlFor="Discription" className={'block'}><small>Description<sup className='color_danger'>*</sup></small></label>
                                        <InputTextarea id='Discription' {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} rows={5} cols={30} />
                                        {/* <InputText id="name" {...input} autoFocus className={classNames({ 'p-invalid': isFormFieldValid(meta) })} /> */}
                                        <small className='fs-12'>{getFormErrorMessage(meta)}</small>
                                    </div>
                                )} />
                            </div>
                            <div className='col-sm-6 colmd-6'>
                                <Field name="Trems_Conditions" render={({ input, meta }) => (
                                    <div className="field">
                                        <label htmlFor="Trems_Conditions" className={'block'}><small>Terms & Conditions<sup className='color_danger'>*</sup></small></label>
                                        <InputTextarea id='Trems_Conditions' {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} rows={5} cols={30} />
                                        {/* <InputText id="name" {...input} autoFocus className={classNames({ 'p-invalid': isFormFieldValid(meta) })} /> */}
                                        <small className='fs-12'>{getFormErrorMessage(meta)}</small>
                                    </div>
                                )} />
                            </div>
                            <div className='col-sm-6 colmd-6'>
                                <Field name="Coupon_Platform_Type" render={({ input, meta }) => (
                                    <div className="field">
                                        <label htmlFor="Coupon_Platform_Type" className={'block'}><small>Coupon Platform Type <sup className='color_danger'>*</sup> </small></label>
                                        <Dropdown id="Coupon_Platform_Type" {...input}
                                            options={Coupon_Platform_Type_List} optionLabel="LOOKUP_DISPLAY_NAME" optionValue='ID'
                                            className={classNames({ 'p-invalid': isFormFieldValid(meta) })} />
                                        <small className='fs-12'>{getFormErrorMessage(meta)}</small>
                                    </div>
                                )} />
                            </div>
                            <div className='Footer_Submit_Button'>
                                {editFlag ?
                                    <Button type="submit" label="EDIT" className="mt-3" /> :
                                    <Button type="submit" label="SUBMIT" className="mt-3" />
                                }
                            </div>
                        </form>
                    )} />
                </section>
            </Dialog>

            {/* Delete Coupon */}
            <Dialog
                visible={DeleteFlag} onHide={handleClose} className="w-50 h-20"
                header={<div>Delete Coupon</div>}
            >
                <div>
                    <div className='text-center'>
                        <p>Do you want to delete this Coupon <span className='color_danger'>"{Selected_edit_CouponCode}"</span></p>
                    </div>
                    <div className='status_button text-center'>
                        <Button className='primary max-w-80 mx-2' onClick={handleClose} label="NO"></Button>
                        <Button className='danger max-w-80 mx-2' onClick={() => DeleteCoupon()} label="YES"></Button>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default Coupons;