import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { TabView, TabPanel } from 'primereact/tabview';

import { Container, Row, Col, Button } from 'reactstrap';

import Add from '../icons/add.svg';
import Edit from '../icons/edit.svg';
import Delete from '../icons/delete.svg';

import { 
    PAYMENT_SETTINGS, COUPON_SETTINGS, WALLET_SETTINGS, ATUM_SETTINGS, 
    CART_SETTINGS, EDIT_ATUM_SETTINGS, EDIT_CART_SETTINGS, 
    EDIT_COUPON_SETTINGS, EDIT_PAYMENT_SETTINGS, ORDER_DELIVERY_LIST,
    SHIPPING_DELIVERY_CHARGES, DELIVERY_ZONES_LIST,
} from '../../config/config';
import './settings.css';

const Settings = () => {
    const [merchantId, setMId] = useState('');
    const [merchantKey, setMKey] = useState('');
    const [website, setWSite] = useState('');
    const [industryType, setIType] = useState('');
    const [callUrl, setCurl] = useState('');
    const [paymentintURL, setPurl] = useState('');
    const [pid, setPId] = useState('');
    const [cid, setCId] = useState('');
    const [aid, setAId] = useState('');
    const [cartId, setCrtId] = useState('');
    // const [pconfig, setPConfig] = useState('');
    // const [wconfig, setWConfig] = useState('');
    // const [cconfig, setCConfig] = useState('');
    // const [aconfig, setAConfig] = useState('');
    const [couponDays, setCDays] = useState('');
    const [referBonus, setBRefer] = useState('');
    const [aCoins, setCoins] = useState('');
    const [aConvert, setConvert] = useState('');
    const [userBonus, setBUser] = useState('');
    const [brRefer, setBrRefer] = useState('');
    const [brUser, setBrUser] = useState('');
    const [activeIndex, setActiveIndex] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [pLimit, setPLmt] = useState(0);
    const [opnAdd, setAdd] = useState(false);
    const [edits, setEdts] = useState('add');
    const [opnDel, setDel] = useState(false);
    const [cartArr, setCArr] = useState([]);
    const [sObj, setObj] = useState(null);
    const [sIdx, setIdx] = useState(0);
    const [sArr, setSArr] = useState([]);

    const [dSettArr, setDSArr] = useState([]);
    const [dArr, setDArr] = useState([]);
    const [zArr, setZArr] = useState([]);
    const [delivery, setDelivery] = useState('');
    const [dName, setDName] = useState('');
    const [ship, setShip] = useState('');
    const [codValue, setCodVal] = useState(0);
    const [codDiscnt, setCDiscnt] = useState(0);
    const [szone, setSZone] = useState('');
    const [szName, setSZName] = useState('');
    const [szArea, setSZArea] = useState('');
    const [dzone, setDZone] = useState('');
    const [dzName, setDZName] = useState('');
    const [dzArea, setDZArea] = useState('');

    const [newId, setNId] = useState('');
    const [err, setErr] = useState(false);

    let mainId = JSON.parse(sessionStorage.getItem('id')) ? JSON.parse(sessionStorage.getItem('id')) : 1;

    const loadData = () => {
        // Payment Settings
        axios({
            method: 'get',
            url: PAYMENT_SETTINGS,
        }).then((res) => {
            setPId(res.data.ID);
            setWSite(res.data.rows[0].CONFIG_JSON.WEBSITE); setIType(res.data.rows[0].CONFIG_JSON.INDUSTRY_TYPE);
            setMId(res.data.rows[0].CONFIG_JSON.MERCHANT_ID); setMKey(res.data.rows[0].CONFIG_JSON.MERCHANT_KEY);
            setCurl(res.data.rows[0].CONFIG_JSON.CALLBACK_URL); setPurl(res.data.rows[0].CONFIG_JSON.PAYMENT_INITIATE_URL);
        }).catch((err) => {
            console.log(err);
        });

        // Coupon Settings
        axios({
            method: 'get',
            url: COUPON_SETTINGS,
        }).then((res) => {
            setCId(res.data.rows[0].ID); setCDays(res.data.rows[0].CONFIG_JSON.COUPON_DURATION_DAYS);
            setBRefer(res.data.rows[0].CONFIG_JSON.BONUS_TO_REFERRED_BY); setBUser(res.data.rows[0].CONFIG_JSON.BONUS_TO_NEW_USER);
        }).catch((err) => {
            console.log(err);
        });

        // Wallet Settings
        axios({
            method: 'get',
            url: WALLET_SETTINGS,
        }).then((res) => {
            setCId(res.data.rows[0].ID); setCDays(res.data.rows[0].CONFIG_JSON.COUPON_DURATION_DAYS);
            setBRefer(res.data.rows[0].CONFIG_JSON.BONUS_TO_REFERRED_BY); setBUser(res.data.rows[0].CONFIG_JSON.BONUS_TO_NEW_USER);
        }).catch((err) => {
            console.log(err);
        });

        // Atum Referral Settings
        axios({
            method: 'GET',
            url: ATUM_SETTINGS,
        }).then((res) => {
            setAId(res.data.data.rows[0].ID);
            setBrUser(res.data.data.rows[0].ATUM_SETTINGS_JSON.BONUS_TO_NEW_USER); 
            setBrRefer(res.data.data.rows[0].ATUM_SETTINGS_JSON.BONUS_TO_REFERRED_BY);
        }).catch((err) => {
            console.log(err);
        });

        // Cart Settings
        axios({
            method: 'GET',
            url: CART_SETTINGS,
        }).then((res) => {
            const arrs = []; 
            if(res.data.rows.length>0){
                setCrtId(res.data.rows[0].ID);

                // for(let i=0; i<res.data.rows.length; i++){
                //    console.log('Cart Settings: ', res.data.rows[i].CONFIG_JSON.data);
                // };
    
                setCArr(arrs); setSArr(arrs);
            }else{
                setCrtId(1);
            }
        }).catch((err) => {
            console.log(err);
        });

        // Shipping Settings
        axios({
            method: 'GET',
            url: SHIPPING_DELIVERY_CHARGES+'?type=list&limit=100',
        }).then((res) => {
            setDSArr(res.data.rows);
        }).catch((err) => {
            console.log(err);
        });

        // Delivery Partners
        axios({
            method: 'get',
            url: ORDER_DELIVERY_LIST,
        }).then((res) => {
            setDArr(res.data);
        }).catch((err) => {
            console.log(err);
        });

        // Delivery Zones
        axios({
            method: 'GET',
            url: DELIVERY_ZONES_LIST,
        }).then((res) => {
            setZArr(res.data);
        }).catch((err) => {
            console.log(err);
        });
    };

    useEffect(() => {
        loadData();
    },[]);

    const handleSubmit = () => {
        if(activeIndex===0){
            if(merchantId!=='' && merchantKey!=='' && website!=='' && industryType!=='' && callUrl!=='' && paymentintURL!==''){
                let CONFIG_JSON = {
                    'MERCHANT_ID': merchantId, 'MERCHANT_KEY': merchantKey,
                    'INDUSTRY_TYPE': industryType, 'WEBSITE': website,
                    'CALLBACK_URL': callUrl, 'PAYMENT_INITIATE_URL': paymentintURL,
                };
    
                axios({
                    method:'PUT',
                    url: EDIT_PAYMENT_SETTINGS+'/'+pid,
                    data: { CONFIG_JSON: CONFIG_JSON },
                }).then((res) => {
                    loadData();
                }).catch((err) => {
                    console.log(err);
                });
            };
        };
        
        if(activeIndex===1){

        };

        if(activeIndex===2){
            if(couponDays!=='' && referBonus!=='' && userBonus!==''){
                let CONFIG_JSON = {
                    'COUPON_DURATION_DAYS': couponDays,
                    'BONUS_TO_REFERRED_BY': parseInt(referBonus), 'BONUS_TO_NEW_USER': parseInt(userBonus),
                };
    
                axios({
                    method:'PUT',
                    url: EDIT_COUPON_SETTINGS+'/'+cid,
                    data: { CONFIG_JSON: CONFIG_JSON },
                }).then((res) => {
                    loadData();
                }).catch((err) => {
                    console.log(err);
                });
            };
        };

        if(activeIndex===3){
            if(brRefer!=='' && brUser!==''){
                let CONFIG_JSON = {
                    'BONUS_TO_REFERRED_BY': parseInt(brRefer), 
                    'BONUS_TO_NEW_USER': parseInt(brUser),
                };
    
                axios({
                    method:'PUT',
                    url: EDIT_ATUM_SETTINGS+'/'+aid,
                    data: { ATUM_SETTINGS_JSON: CONFIG_JSON },
                }).then((res) => {
                    loadData();
                }).catch((err) => {
                    console.log(err);
                });
            };
        };

        if(activeIndex===5){
            if(edits!=='add'){
                if(delivery!=='' && ship!=='' && codValue!=='' && codDiscnt!=='' && szone!=='' && szArea!=='' && dzone!=='' && dzArea!==''){
                    let config = {
                        SHIPPING_CHARGES: ship,
                        COD_CHARGES_VALUE: codValue,
                        COD_CHARGES_PERCENTAGE: codDiscnt,
                        SOURCE_ZONE_ID: szone,
                        SOURCE_ZONE_NAME: szName,
                        AREAS_UNDER_SOURCE_ZONE: szArea,
                        DESTINATION_ZONE_ID: dzone,
                        DESTINATION_ZONE_NAME: dzName,
                        AREAS_UNDER_DESTINATION_ZONE: dzArea,
                        DELIVERY_PROVIDER_NAME: dName,
                        DELIVERY_PROVIDER_ID: delivery,
                        UPDATED_BY: mainId,
                    };
    
                    axios({
                        method: 'PUT',
                        url: SHIPPING_DELIVERY_CHARGES+'/'+newId,
                        data: config,
                    }).then((res) => {
                        closeDelivery();
                    }).catch((err) => {
                        console.log(err);
                    });
                }else{
                    setErr(true);
                };
            }else{
                if(delivery!=='' && ship!=='' && codValue!=='' && codDiscnt!=='' && szone!=='' && szArea!=='' && dzone!=='' && dzArea!==''){
                    let config = {
                        SHIPPING_CHARGES: ship,
                        COD_CHARGES_VALUE: codValue,
                        COD_CHARGES_PERCENTAGE: codDiscnt,
                        SOURCE_ZONE_ID: szone,
                        SOURCE_ZONE_NAME: szName,
                        AREAS_UNDER_SOURCE_ZONE: szArea,
                        DESTINATION_ZONE_ID: dzone,
                        DESTINATION_ZONE_NAME: dzName,
                        AREAS_UNDER_DESTINATION_ZONE: dzArea,
                        DELIVERY_PROVIDER_NAME: dName,
                        DELIVERY_PROVIDER_ID: delivery,
                        CREATED_BY: mainId,
                        UPDATED_BY: mainId,
                    };
    
                    axios({
                        method: 'POST',
                        url: SHIPPING_DELIVERY_CHARGES,
                        data: config,
                    }).then((res) => {
                        closeDelivery();
                    }).catch((err) => {
                        console.log(err);
                    });
                }else{
                    setErr(true);
                };
            };
        };
    };

    const handleCart = () => {

        if(edits==='add'){  
            let obj = {}; 
            obj.data = sArr;

            axios({
                method: 'put',
                url: EDIT_CART_SETTINGS+'/'+cartId,
                data: { CONFIG_JSON : obj },
            }).then((res) => {
                setAdd(false);
                setDiscount(0); setPLmt(0);
            }).catch((err) => {
                console.log(err);
            });
        }else if(edits==='edit'){
            let arr = cartArr, obj = {};

            for(let i=0; i<arr.length; i++){
                if(sIdx===i){
                    arr[i].DISCOUNT_PERCENTAGE = discount;
                    arr[i].PRODUCTS_LIMIT = pLimit;
                }; 
            };

            obj.data = arr;

            axios({
                method: 'put',
                url: EDIT_CART_SETTINGS+'/'+cartId,
                data: { CONFIG_JSON: obj },
            }).then((res) => {
                setAdd(false);
                setDiscount(0); setPLmt(0);
            }).catch((err) => {
                console.log(err);
            });
        };
    };

    const addArr = () => {
        setSArr((prev) => [...prev, {'DISCOUNT_PERCENTAGE': discount, 'PRODUCTS_LIMIT': pLimit}]);
        setDiscount(0); setPLmt(0);
    };

    const handleEdit = (obj, index) => {
        setDiscount(obj.DISCOUNT_PERCENTAGE); setEdts('edit');
        setPLmt(obj.PRODUCTS_LIMIT); setAdd(true); setObj(obj); setIdx(index);
    };

    const handleDelEdit = (row) => {
        setNId(row.ID); setEdts('edit'); setAdd(true); setErr(false);
        setShip(row.SHIPPING_CHARGES); setCodVal(row.COD_CHARGES_VALUE);
        setCDiscnt(row.COD_CHARGES_PERCENTAGE); setSZone(row.SOURCE_ZONE_ID);
        setSZName(row.SOURCE_ZONE_NAME); setSZArea(row.AREAS_UNDER_SOURCE_ZONE);
        setDZone(row.DESTINATION_ZONE_ID); setDZName(row.DESTINATION_ZONE_NAME); 
        setDZArea(row.AREAS_UNDER_DESTINATION_ZONE); setDName(row.DELIVERY_PROVIDER_NAME); 
        setDelivery(row.DELIVERY_PROVIDER_ID);
    };

    const editBody = (row, index) => {
        return <img src={Edit} onClick={() => handleEdit(row, index.rowIndex)} style={{color: 'dodgerblue', width: '25px', height: '20px'}} />
    };

    const editDelivery = (row) => {
        return <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <img src={Edit} onClick={() => handleDelEdit(row)} style={{color: 'dodgerblue', width: '25px', height: '20px'}} />
            <img src={Delete} onClick={() => {setNId(row.ID); setDel(true)}} style={{color: 'dodgerblue', width: '25px', height: '20px'}} />
        </div>
    };

    const closeDelivery = () => {
        setAdd(false); setEdts('add'); setErr(false); 
        setShip(0); setCodVal(0); setCDiscnt(0); 
        setSZone(0); setSZName(''); setSZArea('');
        setDZone(0); setDZName(''); setDZArea('');
        setDName(''); setDelivery(0);

        axios({
            method: 'GET',
            url: SHIPPING_DELIVERY_CHARGES+'?type=list&limit=100',
        }).then((res) => {
            setDSArr(res.data.rows);
        }).catch((err) => {
            console.log(err);
        });
    };

    const handleDel = () => {
        let obj = { data: [] };

        for(let i=0; i<cartArr.length; i++){
            for(let j=0; j<sObj.length; j++){
                if((cartArr[i].DISCOUNT_PERCENTAGE!==sObj[j].DISCOUNT_PERCENTAGE) && (cartArr[i].PRODUCTS_LIMIT!==sObj[j].PRODUCTS_LIMIT)){
                    obj.data.push(cartArr[i]);
                }
            }
        }
        
        axios({
            method: 'put',
            url: EDIT_CART_SETTINGS+'/'+cartId,
            data: { CONFIG_JSON: obj },
        }).then((res) => {
            setDel(false); setObj(null);
        }).catch((err) => {
            console.log(err);
        });
    };

    const handleDeliveryDel = () => {
        axios({
            method: 'DELETE',
            url: SHIPPING_DELIVERY_CHARGES+`/${newId}`,
        }).then((res) => {
            console.log(res.data);
        }).catch((err) => {
            console.log(err);
        });
    };

    // const handleWalletCoin = (e) => {
    //     if(/^([0-9]+\s)*[0-9]+$/g.test(e.target.value)){
    //         setCoins(e.target.value);
    //     }else if(e.target.value.length === 0){
    //         setCoins('');
    //     };
    // };

    // const handleWalletConversion = (e) => {
    //     if(/^([0-9]+\s)*[0-9]+$/g.test(e.target.value)){
    //         setConvert(e.target.value);
    //     }else if(e.target.value.length === 0){
    //         setConvert('');
    //     };
    // };

    // const handleCouponDays = (e) => {
    //     if(/^([0-9]+\s)*[0-9]+$/g.test(e.target.value)){
    //         setCDays(e.target.value);
    //     }else if(e.target.value.length === 0){
    //         setCDays('');
    //     };
    // };

    const handleBonusRefer = (e) => {
        if(/^([0-9]+\s)*[0-9]+$/g.test(e.target.value)){
            setBRefer(e.target.value);
        }else if(e.target.value.length === 0){
            setBRefer('');
        };
    };

    // const handleBonusUser = (e) => {
    //     if(/^([0-9]+\s)*[0-9]+$/g.test(e.target.value)){
    //         setBUser(e.target.value);
    //     }else if(e.target.value.length === 0){
    //         setBUser('');
    //     };
    // };

    const handleBikeBonus = (e) => {
        if(/^([0-9]+\s)*[0-9]+$/g.test(e.target.value)){
            setBrRefer(e.target.value);
        }else if(e.target.value.length === 0){
            setBrRefer('');
        };
    };

    const handleBikeUser = (e) => {
        if(/^([0-9]+\s)*[0-9]+$/g.test(e.target.value)){
            setBrUser(e.target.value);
        }else if(e.target.value.length === 0){
            setBrUser('');
        };
    };

    const handleCartDiscount = (e) => {
        if(/^([0-9]+\s)*[0-9]+$/g.test(e.target.value)){
            setDiscount(e.target.value);
        }else if(e.target.value.length === 0){
            setDiscount('');
        };
    };

    const handleProductLimit = (e) => {
        if(/^([0-9]+\s)*[0-9]+$/g.test(e.target.value)){
            setPLmt(e.target.value);
        }else if(e.target.value.length === 0){
            setPLmt('');
        };
    };

    const handleDelivery = (val) => {
        if(val){
            let arr = dArr.filter((it) => it.ID === val);
            setDName(arr[0].LOOKUP_NAME); setDelivery(val);
        }
    };

    const handleZone = (val, name) => {
        if(name==='source'){
            if(val){
                let arr = zArr.filter((it) => it.ID === val);
                setSZName(arr[0].LOOKUP_NAME); setSZone(val);
            }
        } else {
            if(val){
                let arr = zArr.filter((it) => it.ID === val);
                setDZName(arr[0].LOOKUP_NAME); setDZone(val);
            };
        };
    };

    const deliveryProviderName = (row) => {
        let arr = row.DELIVERY_PROVIDER_NAME.split('_');
        return <div>{arr[0].charAt(0) + arr[0].slice(1).toLowerCase()+' '+arr[1].charAt(0) + arr[1].slice(1).toLowerCase()}</div>
    };

    const sourceNameBody = (row) => {
        let arr = row.SOURCE_ZONE_NAME.split('_');
        return <div>{arr[0].charAt(0) + arr[0].slice(1).toLowerCase()+' '+arr[1].charAt(0) + arr[1].slice(1).toLowerCase()}</div>
    };

    const destinationNameBody = (row) => {
        let arr = row.DESTINATION_ZONE_NAME.split('_');
        return <div>{arr[0].charAt(0) + arr[0].slice(1).toLowerCase()+' '+arr[1].charAt(0) + arr[1].slice(1).toLowerCase()}</div>
    };

    return(
        <div style={{width: '96%', margin: '1.5vw', marginTop: '10vh', boxShadow: '3px 0px 11px -2px rgba(0,0,0,0.5)'}}>
            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                <TabPanel header="Payment Settings">
                    <Container>
                        <Row>
                            <Col xs={6}>
                                <label>Merchant ID</label>
                                <InputText value={merchantId} onChange={(e) => setMId(e.target.value)} style={{width: '100%'}} />
                            </Col>
                            <Col xs={6}>
                                <label>Merchant Key</label>
                                <InputText value={merchantKey} onChange={(e) => setMKey(e.target.value)} style={{width: '100%'}} />
                            </Col>
                            <Col xs={6}>
                                <label>Industry Type</label>
                                <InputText value={industryType} onChange={(e) => setIType(e.target.value)} style={{width: '100%'}} />
                            </Col>
                            <Col xs={6}>
                                <label>Web Site</label>
                                <InputText value={website} onChange={(e) => setWSite(e.target.value)} style={{width: '100%'}} />
                            </Col>
                            <Col xs={6}>
                                <label>Payment Initiate URL</label>
                                <InputText value={paymentintURL} onChange={(e) => setPurl(e.target.value)} style={{width: '100%'}} />
                            </Col>
                            <Col xs={6}>
                                <label>Callback URL</label>
                                <InputText value={callUrl} onChange={(e) => setCurl(e.target.value)} style={{width: '100%'}} />
                            </Col>
                            <Col xs={12} style={{ marginTop: '2.5%', textAlign: 'center' }}>
                                <Button
                                    style={activeIndex===0 
                                        ? {backgroundColor: '#6366F1'} 
                                        : {backgroundColor: '#ccc'}
                                    } onClick={handleSubmit} 
                                    disabled={activeIndex===0 ? false : true}
                                >SUBMIT</Button>
                            </Col>
                        </Row>
                    </Container>
                </TabPanel>

                <TabPanel header="Wallet Settings">
                    <Container>
                        <Row>
                            <Col xs={6}>
                                <label>ATUM Coins</label>
                                <InputText value={aCoins} onChange={(e) => setCoins(e.target.value)} style={{width: '100%'}} />
                            </Col>
                            <Col xs={6}>
                                <label>ATUM Conversion</label>
                                <InputText value={aConvert} onChange={(e) => setConvert(e.target.value)} style={{width: '100%'}} />
                            </Col>
                            <Col xs={12} style={{ marginTop: '2.5%', textAlign: 'center' }}>
                                <Button 
                                    style={activeIndex===1 
                                        ? {backgroundColor: '#6366F1'} 
                                        : {backgroundColor: '#ccc'}
                                    } onClick={handleSubmit} 
                                    disabled={activeIndex===1 ? false : true}
                                >SUBMIT</Button>
                            </Col>
                        </Row>
                    </Container>
                </TabPanel>

                <TabPanel header="Coupon Settings">
                    <Container>
                        <Row>
                            <Col xs={4}>
                                <label>Coupon Duration Days</label>
                                <InputText value={couponDays} onChange={(e) => setCDays(e.target.value)} style={{width: '100%'}} />
                            </Col>
                            <Col xs={4}>
                                <label>Bonus Referred By</label>
                                <InputText value={referBonus} onChange={handleBonusRefer} style={{width: '100%'}} />
                            </Col>
                            <Col xs={4}>
                                <label>Bonus New User</label>
                                <InputText value={userBonus} onChange={handleBikeUser} style={{width: '100%'}} />
                            </Col>
                            <Col xs={12} style={{ marginTop: '2.5%', textAlign: 'center' }}>
                                <Button 
                                    style={activeIndex===2 
                                        ? {backgroundColor: '#6366F1'} 
                                        : {backgroundColor: '#ccc'}
                                    } onClick={handleSubmit} 
                                    disabled={activeIndex===2 ? false : true}
                                >SUBMIT</Button>
                            </Col>
                        </Row>
                    </Container> 
                </TabPanel>

                <TabPanel header="Bike Referral Settings">
                    <Container>
                        <Row>
                            <Col xs={6}>
                                <label>Bonus Referred</label>
                                <InputText value={brRefer} onChange={handleBikeBonus} style={{width: '100%'}} />
                            </Col>
                            <Col xs={6}>
                                <label>Bonus New User</label>
                                <InputText value={brUser} onChange={handleBikeUser} style={{width: '100%'}} />
                            </Col>
                            <Col xs={12} style={{ marginTop: '2.5%', textAlign: 'center' }}>
                                <Button
                                    style={activeIndex===3 
                                        ? {backgroundColor: '#6366F1'} 
                                        : {backgroundColor: '#ccc'}
                                    } onClick={handleSubmit} 
                                    disabled={activeIndex===3 ? false : true}
                                >SUBMIT</Button>
                            </Col>
                        </Row>
                    </Container>
                </TabPanel>

                <TabPanel header='Cart Settings'>
                    <div style={(sObj!==null && sObj.length>0) ? { display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', fontWeight: 800 } : { textAlign: 'center' }}>
                        <Button
                            style={{ backgroundColor: '#6366F1', width: '7.5vw', height: '6.5vh' }} 
                            onClick={() => setAdd(true)}
                        >
                            <img src={Add} style={{width: '25px', height: '20px'}} />&nbsp;Add
                        </Button>

                        {(sObj!==null && sObj.length>0) && 
                            <Button 
                                style={{ backgroundColor: 'red', width: '7.5vw', height: '6.5vh' }} 
                                onClick={() => setDel(true)}
                            >
                                <img src={Delete} style={{width: '25px', height: '20px'}} />&nbsp;&nbsp;Delete
                            </Button>
                        }
                    </div>

                    <div>
                        <DataTable value={cartArr} selection={sObj} onSelectionChange={(e) => setObj(e.value)} responsiveLayout="scroll">
                            <Column selectionMode="multiple" headerStyle={{width: '2em'}} />
                            <Column field="DISCOUNT_PERCENTAGE" header="Discount Percentage" />
                            <Column field="PRODUCTS_LIMIT" header="Product Limit" />
                            <Column header='More' body={editBody} />
                        </DataTable>
                    </div>
                    
                    <Dialog 
                        visible={opnAdd} onHide={() => {
                            setAdd(false); setEdts('add'); 
                            setDiscount(0); setPLmt(0)
                        }}
                        header={<div style={{textAlign: 'center'}}>
                            {edits==='add' ? 'Add Cart Detail Settings' : 'Edit Cart Detail Settings'}
                        </div>}
                    >
                        <Container style={{marginTop: '1%'}}>
                            <Row>
                                {edits==='add' ? <>
                                    <Col xs={5}>
                                        <label>Discount Percentage</label>
                                        <InputText value={discount} onChange={handleCartDiscount} style={{ width: '100%' }} />
                                    </Col>

                                    <Col xs={5}>
                                        <label>Product Limit</label>
                                        <InputText value={pLimit} onChange={handleProductLimit} style={{ width: '100%' }} />
                                    </Col>
                                    
                                    <Col xs={2}>
                                        <Button style={{height: '100%'}} onClick={addArr}>Add</Button>
                                    </Col>
                                </> : <>
                                    <Col xs={6}>
                                        <label>Discount Percentage</label>
                                        <InputText value={discount} onChange={handleCartDiscount} style={{ width: '100%' }} />
                                    </Col>

                                    <Col xs={6}>
                                        <label>Product Limit</label>
                                        <InputText value={pLimit} onChange={handleProductLimit} style={{ width: '100%' }} />
                                    </Col>
                                </>}

                                {edits==='add' && <Col xs={12}>
                                    <DataTable value={sArr}>
                                        <Column header='Discount Percentage' field='DISCOUNT_PERCENTAGE' />
                                        <Column header='Product Limit' field='PRODUCTS_LIMIT' />
                                    </DataTable>
                                </Col>}

                                <Col xs={12} style={{textAlign: 'center', marginTop: '5px'}}>
                                    <Button style={{height: '7.5vh', width: '9vw'}} onClick={handleCart}>Submit</Button>
                                </Col>
                            </Row>
                        </Container>
                    </Dialog>

                    <Dialog visible={opnDel} onHide={() => setDel(false)} header={<div style={{textAlign: 'center'}}>Do you want to delete the selected items</div>} style={{width: '40vw', height: 'auto'}}>
                        <div style={{textAlign: 'center', marginTop:'1%'}}>
                            <Button onClick={() => setDel(false)}>No</Button>
                            <Button style={{backgroundColor:'red', marginLeft: '2.5vw'}} onClick={handleDel}>Yes</Button>
                        </div>
                    </Dialog>
                </TabPanel>

                <TabPanel header='Shipping Charge Settings'>
                    <div style={{textAlign: 'center'}}>
                        <Button
                            style={{ backgroundColor: '#6366F1', width: '7.5vw', height: '6.5vh' }} 
                            onClick={() => setAdd(true)}
                        >
                            <img src={Add} style={{width: '25px', height: '20px'}} />&nbsp;Add
                        </Button>
                    </div>

                    <div>
                        <DataTable value={dSettArr} selection={sObj} onSelectionChange={(e) => setObj(e.value)} responsiveLayout="scroll" removableSort>
                            <Column field="DELIVERY_PROVIDER_NAME" header="Delivery Partner" sortable body={deliveryProviderName} style={{width: '220px'}} />
                            <Column field="SHIPPING_CHARGES" header="Shipping Charges" sortable style={{width: '75px'}} />
                            <Column field="COD_CHARGES_VALUE" header="COD Charges" sortable style={{width: '75px'}} />
                            <Column field="SOURCE_ZONE_NAME" header="Source Zone" sortable body={sourceNameBody} style={{width: '200px'}} />
                            <Column field="AREAS_UNDER_SOURCE_ZONE" header="Source Areas" sortable style={{width: '200px'}} />
                            <Column field="DESTINATION_ZONE_NAME" header="Destination Zone" sortable body={destinationNameBody} style={{width: '200px'}} />
                            <Column field="AREAS_UNDER_DESTINATION_ZONE" header="Destination Areas" sortable style={{width: '200px'}} />
                            <Column header='More' body={editDelivery} style={{width: '80px', textAlign: 'center'}} />
                        </DataTable>
                    </div>
                    
                    {/* Add / Edit Shipping Charge Settings */}
                    <Dialog 
                        visible={opnAdd} onHide={() => {
                            setAdd(false); setEdts('add'); 
                            setDiscount(0); setPLmt(0);
                        }} header={<div style={{textAlign: 'center'}}>
                            {edits==='add' ? 'Add Delivery Settings' : 'Edit Delivery Settings'}
                        </div>} footer={<div style={{textAlign: 'center', marginTop: '7.5px'}}>
                            <Button style={{height: 'auto', width: '150px'}} onClick={() => handleSubmit()}>Submit</Button>
                        </div>} style={{width: '45vw'}}
                    >
                        <Container style={{marginTop: '1%'}}>
                            <Row>
                                <Col xs={6}>
                                    <label style={(err===true&&delivery==='') ? {color: 'red'} : {}}>Delivery Provider *</label>
                                    <Dropdown 
                                        value={delivery} style={{ width: '100%' }} options={dArr} 
                                        onChange={(e) => handleDelivery(e.target.value)} 
                                        optionLabel='LOOKUP_DISPLAY_NAME' optionValue='ID'

                                    />
                                    {(err===true&&delivery==='') && <small style={{color: 'red'}}>Delivery Partner is required.</small>}
                                </Col>

                                <Col xs={6}>
                                    <label style={(err===true&&szone==='') ? {color: 'red'} : {}}>Source Zone *</label>
                                    <Dropdown 
                                        value={szone} onChange={(e) => handleZone(e.target.value, 'source')} 
                                        style={{ width: '100%' }} options={zArr} optionValue='ID'
                                        optionLabel='LOOKUP_DISPLAY_NAME'
                                    />
                                    {(err===true&&szone==='') && <small style={{color: 'red'}}>Source Zone is required.</small>}
                                </Col>

                                <Col xs={6}>
                                    <label style={(err===true&&szArea==='') ? {color: 'red'} : {}}>Source Zone Areas *</label>
                                    <InputText 
                                        value={szArea} onChange={(e) => setSZArea(e.target.value)} 
                                        style={{ width: '100%' }} 
                                    />
                                    {(err===true&&szArea==='') && <small style={{color: 'red'}}>Source Zone Areas is required.</small>}
                                </Col>

                                <Col xs={6}>
                                    <label style={(err===true&&dzone==='') ? {color: 'red'} : {}}>Destination Zone *</label>
                                    <Dropdown 
                                        value={dzone} onChange={(e) => handleZone(e.target.value, 'destination')} 
                                        style={{ width: '100%' }} options={zArr} optionValue='ID'
                                        optionLabel='LOOKUP_DISPLAY_NAME'
                                    />
                                    {(err===true&&dzone==='') && <small style={{color: 'red'}}>Destination Zone is required.</small>}
                                </Col>

                                <Col xs={6}>
                                    <label style={(err===true&&dzArea==='') ? {color: 'red'} : {}}>Destination Zone Areas *</label>
                                    <InputText 
                                        value={dzArea} onChange={(e) => setDZArea(e.target.value)} 
                                        style={{ width: '100%' }} 
                                    />
                                    {(err===true&&dzArea==='') && <small style={{color: 'red'}}>Destination Zone Areas is required.</small>}
                                </Col>

                                <Col xs={6}>
                                    <label style={(err===true&&ship==='') ? {color: 'red'} : {}}>Shipping Charges *</label>
                                    <InputNumber 
                                        value={ship} mode="decimal" style={{width: '100%'}}
                                        onValueChange={(e) => setShip(e.value)} minFractionDigits={2}
                                    />
                                    {(err===true&&ship==='') && <small style={{color: 'red'}}>Shipping Charges is required.</small>}
                                </Col>

                                <Col xs={6}>
                                    <label style={(err===true&&codValue==='') ? {color: 'red'} : {}}>COD Charges (₹) *</label>
                                    <InputNumber 
                                        value={codValue} mode="decimal" style={{width: '100%'}}
                                        onValueChange={(e) => setCodVal(e.value)} minFractionDigits={2}
                                    />
                                    {(err===true&&codValue==='') && <small style={{color: 'red'}}>COD Charges is required.</small>}
                                </Col>

                                <Col xs={6}>
                                    <label style={(err===true&&codDiscnt==='') ? {color: 'red'} : {}}>COD Charges (%) *</label>
                                    <InputNumber 
                                        value={codDiscnt} mode="decimal" style={{width: '100%'}}
                                        onValueChange={(e) => setCDiscnt(e.value)} minFractionDigits={2} 
                                    />
                                    {(err===true&&codDiscnt==='') && <small style={{color: 'red'}}>COD Charges is required.</small>}
                                </Col>
                            </Row>
                        </Container>
                    </Dialog>

                    {/* Delete Shipping Charge Settings */}
                    <Dialog 
                        visible={opnDel} onHide={() => setDel(false)} 
                        header={<div style={{textAlign: 'center'}}>
                            Do you want to delete the selected items
                        </div>} style={{width: '40vw', height: 'auto'}}
                    >
                        <div style={{textAlign: 'center', marginTop:'1%'}}>
                            <Button onClick={() => setDel(false)}>No</Button>
                            <Button style={{backgroundColor:'red', marginLeft: '2.5vw'}} onClick={handleDeliveryDel}>Yes</Button>
                        </div>
                    </Dialog>
                </TabPanel>
            </TabView>
        </div>
    );
};

export default Settings;