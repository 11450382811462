import React, { useState, useEffect } from 'react';

import { Container, Row, Col } from 'reactstrap';

import ReactPlayer from 'react-player';

import { Image } from 'primereact/image';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { ScrollPanel } from 'primereact/scrollpanel';

import { useHistory, useParams } from 'react-router-dom';

import axios from 'axios';

import Back from '../Sidebar/icons/back.svg';
import { 
  UPLOAD_MEDIA, IMAGE_DOMAIN
} from '../../config/config';
import './Products.css';

export default function ViewProduct () {

  const [state, setState] = useState('');
  
  const history = useHistory();
  const { id } = useParams();

  const loadData = () => {
    axios({
      method: 'GET',
      url: UPLOAD_MEDIA+id
    }).then((res) => {
      setState(res.data);
    }).catch((err) => {
      console.log(err);
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleClr = (clrs) => {
    return <div style={{display: 'flex', flexDirection: 'row'}}>
      <div style={{backgroundColor: clrs, width: '25px', height: '25px', borderRadius: '5px'}}>&nbsp;</div>
    </div>
  };

  return (
    <ScrollPanel style={{ backgroundColor: '#fff', paddingTop: '5vh', height:'100%' }}>
      <Container>
        <Row>
          <Col xs={12}>
            <h4>
              <img src={Back} style={{width: '35px', height: '30px'}} className='backIcon' onClick={() => history.goBack()} /> 
              &nbsp;&nbsp;View Product
            </h4>
          </Col>

          <Col xs={12} sm={3}>
            {state.IMAGE_PATH1!==null ? <Image src={IMAGE_DOMAIN + state.IMAGE_PATH1} width='250px' height='150px' style={{ border: '15px' }} preview /> : 'No Images Found.'}
            {state.VIDEO_PATH!==null && <ReactPlayer url={IMAGE_DOMAIN + state.VIDEO_PATH} width='250px' height='150px' controls={true} playing={true} loop={true} onEnablePIP={false} />}
            {state.IMAGE_PATH2!==null && <Image src={IMAGE_DOMAIN + state.IMAGE_PATH2} width='250px' height='150px' style={{ border: '15px' }} preview />}
            {state.IMAGE_PATH3!==null && <Image src={IMAGE_DOMAIN + state.IMAGE_PATH3}  width='250px' height='150px' style={{ border: '15px' }} preview />}
          </Col>

          <Col xs={12} sm={9}>
            <Row>
              <Col xs={3}>Product Name</Col>
              <Col xs={1}>:</Col>
              <Col xs={8}>{state.PRODUCT_NAME}</Col>

              <Col xs={3}>Product Family</Col>
              <Col xs={1}>:</Col>
              <Col xs={8}>{state.PRODUCT_FAMILY}</Col>

              <Col xs={3}>Category</Col>
              <Col xs={1}>:</Col>
              <Col xs={8}>{state.CATEGORY_DISPLAY_NAME}</Col>

              <Col xs={3}>Price</Col>
              <Col xs={1}>:</Col>
              <Col xs={8}>{new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(state.PRICE)}</Col>

              <Col xs={3}>Discount Type</Col>
              <Col xs={1}>:</Col>
              <Col xs={8}>{state.DISCOUNT_TYPE}</Col>

              <Col xs={3}>Discount Value</Col>
              <Col xs={1}>:</Col>
              <Col xs={8}>{state.DISCOUNT_VALUE}</Col>

              <Col xs={3}>Eligible ATUM Coins</Col>
              <Col xs={1}>:</Col>
              <Col xs={8}>{state.ELIGIBLE_ATUM_COINS}</Col>

              <Col xs={3}>Quantity</Col>
              <Col xs={1}>:</Col>
              <Col xs={8}>{state.QUANTITY}</Col>

              <Col xs={3}>Barcode</Col>
              <Col xs={1}>:</Col>
              <Col xs={8}>{state.PRODUCT_BARCODE}</Col>

              <Col xs={3}>Color</Col>
              <Col xs={1}>:</Col>
              <Col xs={8}>{state.PRODUCT_COLOR ? handleClr(state.PRODUCT_COLOR) : '-'}</Col>

              <Col xs={3}>Measurements</Col>
              <Col xs={1}>:</Col>
              <Col xs={8}>{state.MEASUREMENT_NAME} - {state.MEASUREMENT_NAME && state.MEASUREMENT_VALUE}</Col>

              <Col xs={3}>Default</Col>
              <Col xs={1}>:</Col>
              <Col xs={8}>{state.IS_DEFAULT_PRODUCT==0 ? 'False' : 'True'}</Col>

              <Col xs={3}>Description</Col>
              <Col xs={1}>:</Col>
              <Col xs={8}>{state.DESCRIPTION}</Col>

              <Col xs={3}>Product Sustainability</Col>
              <Col xs={1}>:</Col>
              <Col xs={8}>{state.PRODUCT_SUSTAINABILITY}</Col>
              
              <Col xs={12}>
                <h5 style={{ marginTop: '0.5vh' }}><span style={{borderBottom: '1px solid'}}>Product Inventory</span></h5>

                <DataTable value={state.STORES_INFO}>
                  <Column header='Store' field='STORE_NAME' />
                  <Column header='Quantity' field='QUANTITY' />
                </DataTable>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </ScrollPanel>
  );
};