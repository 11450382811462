import React, { useState, useEffect } from 'react';

import { InputNumber } from 'primereact/inputnumber';
import { InputMask } from 'primereact/inputmask';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { PickList } from 'primereact/picklist';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';

import { Table, Container, Row, Col } from 'reactstrap';

import axios from 'axios';

import Check from '../icons/check.svg';
import { PRODUCTS_LIST, GLOBAL_DOMAIN, STORES_LIST, SUBMITORDER, VERIFYUSER } from '../../config/config';
import './offOrder.css';

export default function OfflineOrders(){
    const [products, setProducts] = useState([]);
    const [source, setSource] = useState([]);
    const [target, setTarget] = useState([]);
    const [selTarget, setSTarget] = useState([]);
    const [load, setLoad] = useState(false);
    const [userPop, setUPop] = useState(false);
    const [userMobile, setUMobile] = useState(null);
    const [userEmail, setUEmail] = useState(null);
    const [orderPop, setOPop] = useState(false);
    const [store, setStore] = useState(null);
    const [storeArr, setSArr] = useState([]);
    const [user, setUser] = useState(null);
    const [max, setMax] = useState(true);
    const [pay, setPay] = useState(null);
    const [price, setPrice] = useState(0);
    const [dPrice, setDPrice] = useState(0);
    const [orderID, setOId] = useState(null);
    const [fName, setFName] = useState(null);
    const [lName, setLName] = useState(null);
    const [show, setShow] = useState(false);
    const [disable, setDisable] = useState(true);

    let payType = [
        {name: 'Cash', val: 'CASH'},
        {name: 'Online', val: 'ONLINE'}
    ];

    const axiosCall = () => {
        if(products.length===0){
            axios({
                method: 'get',
                url: PRODUCTS_LIST + '&limit=1000'
            }).then((res) => {
                setProducts(res.data.rows); setSource(res.data.rows);
            }).catch((error) => {
                console.log(error);
            });
        };

        if(storeArr.length===0){
            axios({
                method: 'get',
                url: STORES_LIST,
            }).then((res) => {
                setSArr(res.data.rows);
            }).catch((err) => {
                console.log(err);
            });
        };
    };

    const onChange = (e) => {
        setSource(e.source); console.log('target: ', e.target);
        let arr=[], arr1=[];

        for(let i=0; i<e.target.length; i++){
            e.target[i].CART_COUNT = 1; arr.push(e.target[i]); 
            arr1.push({'ID': e.target[i].ID, 'CART_COUNT': e.target[i].CART_COUNT})
        };
        setTarget(arr); setSTarget(arr1);
    };

    const onChangeQuantity = (value, item) => {
        if(value!==null){
            let idx = target.findIndex((it => it.ID === item.ID));
            let idx2 = selTarget.findIndex((it => it.ID === item.ID));
            target[idx].CART_COUNT = value; selTarget[idx2].CART_COUNT = value; 
        }
    };

    const itemTemplate = (item) => {

        return (
            <div className="product-item" style={{display: 'flex', flexDirection: 'row'}}>
                <div style={{width: '30%'}}>
                    <div>
                        <img src={GLOBAL_DOMAIN + item.IMAGE_PATH1} width='80px' />
                    </div>
                </div>

                <div style={{width: '70%'}}>
                    <div className="product-list-detail" style={{width: '100%'}}>
                        <div>{item.PRODUCT_NAME}</div>
                        <div style={{marginTop: '-1%', width: '100%'}}>
                            <div className="product-category" style={{marginLeft: '1%'}}><i className="pi pi-tag product-category-icon"></i> {item.CATEGORY_DISPLAY_NAME}</div>
                        </div>
                    </div>
                    <div className="product-list-action">
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                            <div>₹ {priceFormat(item.PRICE)}</div>  
                            <div> {item.QUANTITY > 10 ? <div style={{color: 'forestgreen'}}>In Stock</div> : item.QUANTITY>= 5 ? <div style={{color: 'darkgoldenrod'}}>Low Stock</div> : <div style={{color: 'red'}}>Out of Stock</div> }</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const targetTemplate = (item) => {

        return(
            <div className="product-item" style={{display: 'flex', flexDirection: 'row'}}>
                <div style={{width: '30%'}}>
                    <div>
                        <img src={GLOBAL_DOMAIN + item.IMAGE_PATH1} width='80px' />
                    </div>
                </div>

                <div style={{width: '70%'}}>
                    <div className="product-list-detail" style={{width: '100%'}}>
                        <div>{item.PRODUCT_NAME}</div>
                        <div style={{marginTop: '-1%', width: '100%'}}>
                            <div className="product-category" style={{marginLeft: '1%'}}><i className="pi pi-tag product-category-icon"></i> {item.CATEGORY_DISPLAY_NAME}</div>
                        </div>
                    </div>

                    <div className="product-list-action">
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                            <div style={{width: '55%'}}>₹ {priceFormat(item.PRICE)}</div>  
                            <div style={{width: '45%'}}>
                                <InputNumber value={item.CART_COUNT} mode="decimal" min={1} onChange={(e) => onChangeQuantity(e.value, item)} className='minmax' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const priceFormat = (item) => {
        var x = item.toString();

        var lastThree = x.substring(x.length - 3);

        var otherNumbers = x.substring(0, x.length - 3);

        if (otherNumbers !== ''){
            lastThree = ',' + lastThree;
        }
            
        return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    };

    // const storeMap = (sMap) => {
    //     window.open("https://maps.google.com?q="+sMap.LATITUDE+","+sMap.LONGITUDE);
    // };

    const handlePrice = () => {
        if(target.length >= 1){
            setOPop(true); setMax(true); let cp = 0, dp = 0, ds = 0;

            for(let i=0; i<target.length; i++){
                cp += (target[i].CART_COUNT * target[i].PRICE); 
                dp += parseInt(target[i].DISCOUNT_PRICE); ds += target[i].DISCOUNT_PENCENTAGE;
            };

            setPrice(cp); setDPrice(dp); console.log('Price: ', cp, dp, ds);
        };
    };

    const handleVerify = () => {
        setLoad(true);

        axios({
            method: 'PATCH',
            url: VERIFYUSER,
            data: { MOBILE_NUMBER: userMobile, EMAIL: userEmail }
        }).then((res) => {
            console.log(res.data.data); setUser(res.data.data);
            setLoad(false); setShow(false);
        }).catch((err) => {
            console.log(err);
        });
    };

    const handleSubmit = () => {
        setLoad(true);

        let PickupData = {
            orderItems: target,
            shippingAddress: {},
            CUSTOMER_CONTACT_NUMBER: parseInt(userMobile),
            DELIVERY_TYPE: "STORE_PICKUP",
            PAYMENT_STATUS: 'SUCCESS',
            PAYMENT_MODE: pay,
            ORDER_TRACKER_ID: orderID,
            ORDER_AMOUNT: price - dPrice,
            TRANSACTION_TOKEN: "",
            TRANSACTION_RESPONSE_TOKEN: "",
            STORE_ID: store,
            USER_ID: 1,
        };

        axios({
            method: 'POST',
            url: SUBMITORDER,
            data: PickupData,
        }).then((res) => {
            console.log('Order Submit: ', res.data); 

            setLoad(false); setOId(null); setOPop(false);
            setPay(null); setStore(null); setDisable(true);
        }).catch((err) => {
            console.log(err);
        });
    };

    const handleStore = (e) => {
        console.log('value: ', e.value); setStore(e.value);
    };

    const handlePay = (e) => {
        setPay(e.value);
    };

    useEffect(() => {
        axiosCall();
    }, []);

    useEffect(() => {
        if(userMobile){
            if(target.length){
                setDisable(false);
            }else{
                setDisable(true);
            }
        };

    }, [target.length, userMobile]);

    return(
        <div style={{margin: '1%', marginTop: '3%'}}>
            {/* Store Order */}
            <div className="card" style={{height: '81vh'}}>
                <h4 style={{textAlign: 'center', margin: '1%'}}>
                    Store Order
                </h4>

                <Button label="Verify User" style={{
                    paddingTop: '5px', paddingBottom: '5px', 
                    position: 'absolute', right:'13vw', top: '2%'
                }} onClick={() => setUPop(true)} />
                
                <Button label="Submit Order" style={{
                    paddingTop: '5px', paddingBottom: '5px', 
                    position: 'absolute', right:'1vw', top: '2%'
                }} onClick={handlePrice} disabled={disable} />

                <div style={{margin: '0.7%'}}>
                    <PickList 
                        source={source} target={target} 
                        itemTemplate={itemTemplate} targetItemTemplate={targetTemplate}
                        sourceHeader={<div style={{textAlign: 'center'}}>{source.length>0 ? `Available Products - (${source.length})` : 'Available Products'}</div>} 
                        targetHeader={<div style={{textAlign: 'center'}}>{target.length===0 ? 'Cart Details' : `Cart Details - (${target.length})`}</div>}
                        sourceStyle={{height: '47vh'}} targetStyle={{height: '47vh'}} 
                        onChange={onChange} filterBy="PRODUCT_NAME" 
                        sourceFilterPlaceholder="Search by name" 
                        targetFilterPlaceholder="Search by name" 
                    />
                </div>
            </div>
            
            {/* Verify User */}
            <Dialog 
                header={<div style={{textAlign: 'center'}}>Verify User</div>} 
                style={{width: '45vw', height: '55vh'}}
                visible={userPop} onHide={() => setUPop(false)}
            >
                <div style={{marginTop: '1%', textAlign: 'center'}}>
                    <Container>
                        <Row>

                        </Row>
                        {show===true && <>
                            <Col xs={12} md={6}>
                                <InputText 
                                    value={fName} onChange={(e)=>setFName(e.target.value)}
                                    placeholder='Enter User First Name' style={{textAlign: 'center', width: '100%'}}
                                />
                            </Col>

                            <Col xs={12} md={6}>
                                <InputText 
                                    value={lName} onChange={(e)=>setLName(e.target.value)}
                                    placeholder='Enter User Last Name' style={{textAlign: 'center', width: '100%'}}
                                />
                            </Col>
                        </>}

                        <Col xs={12} md={show===false ? 10 : 6} style={show===false ? {marginLeft: '9%'} : {}}> 
                            <InputText 
                                type='email' value={userEmail} placeholder='Enter User Email' 
                                onChange={(e)=>setUEmail(e.target.value)} style={{textAlign: 'center', width: '100%'}}    
                            />
                        </Col>

                        <Col xs={12} md={show===false ? 10 : 6} style={show===false ? {marginLeft: '9%'} : {}}>
                            <InputMask 
                                mask="9999999999" value={userMobile} placeholder='Enter User Mobile No.' maxlength={10}
                                onChange={(e)=>setUMobile(e.target.value)} style={{textAlign: 'center', width: '100%'}}
                            />
                        </Col>
                    </Container>
                    
                    <Button label= {user!==null ? <img 
                        src={Check} style={{ width: '25px', height: '20px' }}
                    /> : 'Verify'} onClick={handleVerify} style={{
                        paddingTop: '4px', paddingBottom: '4px', 
                        width: '35%', marginTop: '3%'
                    }} loading={load} iconPos="right" />
                </div>
            </Dialog>

            {/* Submit Order */}
            <Dialog
                header={<div style={{textAlign: 'center'}}>Submit Order</div>} 
                style={{width: '95vw', height: '85vh'}} maximizable={max}
                visible={orderPop} onHide={() => setOPop(false)}
            >
                <div className='d-flex'>
                    <div style={{width: '65%'}}>
                        <Table striped bordered>                        
                            <thead>
                                <tr>
                                    <th style={{textAlign: 'center'}}>
                                        #
                                    </th>
                                    <th style={{textAlign: 'center'}}>
                                        Image
                                    </th>
                                    <th>
                                        Name
                                    </th>
                                    <th style={{textAlign: 'center'}}>
                                        Price
                                    </th>
                                    <th style={{textAlign: 'center'}}>
                                        Item Count
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                {target.map((it, idx) => <tr key={idx}>
                                    <th scope="row" style={{textAlign: 'center'}}>
                                        {idx + 1}
                                    </th>
                                    <td style={{textAlign: 'center'}}>
                                        <Image src={GLOBAL_DOMAIN + it.IMAGE_PATH1} width="25" height="25" preview />
                                    </td>
                                    <td>
                                        {it.PRODUCT_NAME}
                                    </td>
                                    <td style={{textAlign: 'center'}}>
                                        ₹ {priceFormat(it.PRICE)}
                                    </td>
                                    <td style={{textAlign: 'center'}}>
                                        {it.CART_COUNT}
                                    </td>
                                </tr>)}
                            </tbody>
                        </Table>
            
                        <Container>
                            <Row>
                                <Col xs={12} md={4}>
                                    <h6 style={{marginTop: '2%'}}>Store Address </h6>
                                    <Dropdown 
                                        value={store} options={storeArr} optionLabel='STORE_NAME' optionValue='ID' showClear
                                        onChange={handleStore} placeholder="Select Store" editable style={{width: '100%'}} required
                                    />      
                                </Col>

                                <Col xs={12} md={4}>
                                    <h6 style={{marginTop: '2%'}}>Payment Type</h6>
                                    <Dropdown 
                                        value={pay} options={payType} optionLabel='name' optionValue='val' showClear required
                                        onChange={handlePay} placeholder='Select Payment Type' editable style={{width: '100%'}}
                                    />      
                                </Col>

                                <Col xs={12} md={4}>
                                    <h6 style={{marginTop: '2%'}}>Order ID</h6>
                                    <InputMask 
                                        mask="aaaaa9999999999999999999" placeholder='Enter Order ID' tooltip='Maximum 24 character'
                                        value={orderID} onChange={(e) => setOId(e.value)} style={{width: '101%'}}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </div>

                    <div style={{width: '35%', paddingLeft: '3%'}}>
                        <div style={{border: '1px solid #ccc', borderRadius: '10px'}}>
                            <h5 style={{textAlign: 'center', marginTop: '2%'}}>Price Details</h5>

                            <div style={{marginLeft: '4%', marginRight: '4%', marginTop: '0px'}}>
                                <Container>
                                    <Row>
                                        <Col xs={6} style={{textAlign: 'left'}}>Price</Col>
                                        <Col xs={6} style={{textAlign: 'right'}}>₹ {priceFormat(price)}</Col>
                                        <Col xs={6} style={{textAlign: 'left'}}>Discount</Col>
                                        <Col xs={6} style={{textAlign: 'right', color: 'red'}}>- ₹ {priceFormat(dPrice)}</Col>
                                        <Col xs={12} style={{marginTop: '-5%', marginBottom: '-5%'}}><hr /></Col>
                                        <Col xs={6} style={{textAlign: 'left', marginBottom: '4%'}}>Total Price</Col>
                                        <Col xs={6} style={{textAlign: 'right', marginBottom: '4%'}}>₹ {priceFormat(price - dPrice)}</Col>
                                    </Row>
                                </Container>

                                <hr style={{marginTop: '-3%'}} />

                                <div style={{textAlign: 'center', marginTop: '2%', marginBottom: '4%'}}>
                                    <Button label='Submit' onClick={handleSubmit} style={{
                                        paddingTop: '5px', paddingBottom: '5px'
                                    }} loading={load} iconPos="right" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};