import React, { useState, useEffect } from 'react';

import axios from 'axios';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputSwitch } from 'primereact/inputswitch';

import { DELIVERY_ADDRESS, UPDATE_DELIVERY_ADDRESS } from '../../config/config.js';

const DeliveryAddress = () => {
    const [tableData, setTableData] = useState([]);
    
    useEffect(() => {
        GetDeliverydata();
    }, []);

    // Get Delivery Address Api Call
    const GetDeliverydata = () => {
        axios({
            method: 'get',
            url: DELIVERY_ADDRESS
        }).then((res) => {
            setTableData(res.data.rows);
            // console.log('Delivery Address: ', res.data.rows);
        }).catch((err) => {
            console.log(err)
        });
    }

    // Update Delivery Address Api Call
    const UpdateAddress = (row, values) => {
        axios({
            method: 'put',
            url: UPDATE_DELIVERY_ADDRESS + '/' + row.ID,
            data: {
                "IS_ENABLED": values,
                "IS_DELETED": 0
            }
        }).then((res) => {
            setTableData(res.data.rows);
            console.log('lookup codes: ', res.data.rows);
            GetDeliverydata();
        }).catch((err) => {
            console.log(err)
        });
    }

    //   Table Header 
    const tableHeader = () => {
        return <div className='DataTable'>
            <div className='m-2'>
                <h3>Delivery Address</h3></div>
            <div className='table-header-action'>
                {/* <InputText className='m-2' value={filter} onChange={(e)=>setFilter(e.target.value)} placeholder='Search...' /> */}
            </div>

        </div>
    };

    //   Table Actions 
    const actionController = (rowData) => {
        return <div style={{ display: 'flex', flexDirection: 'row' }}>
            {/* <SelectButton value={rowData.IS_ENABLED} optionLabel="name" optionValue="value" options={address_statuss} onChange={(e) => UpdateAddress(rowData, e.value)}/> */}
            <InputSwitch 
                checked={rowData.IS_ENABLED}
                onChange={(e) => UpdateAddress(rowData, e.checked)}
            />
        </div>
    };

    return (
        <section className='pt-4 m-3'>
            <div style={{ width: "100%" }} className='datatable'>
                <DataTable
                    value={tableData} header={tableHeader}
                    removableSort scrollable scrollHeight="70vh">
                    <Column header='Contry/States/City' field='LOOKUP_TYPE_NAME' className=' text-truncate' sortable />
                    {/* <Column header='Lookup Name' field='LOOKUP_NAME' className=' text-truncate' sortable /> */}
                    <Column header='Address Names' field='DESCRIPTION' className=' text-truncate' sortable />
                    <Column header='Actions' body={actionController} />
                </DataTable>
            </div>
        </section>
    );
};

export default DeliveryAddress;