import React, { useState, useEffect } from 'react';

import { Container, Row, Col } from 'reactstrap';

import View from '../icons/view.svg';

import axios from 'axios';

import { DataTable } from 'primereact/datatable';
import { Paginator } from 'primereact/paginator';
import { InputText } from 'primereact/inputtext';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';

import { STORE_MANAGERS_LIST } from '../../config/config.js';

const UsersList = () => {
  const [tableData, setTableData] = useState([]);
  const [filter, setFilter] = useState('');
  const [view, setView] = useState(false);
  const [load, setLoad] = useState(false);
  const [name, setName] = useState('');
  const [viewObj, setVObj] = useState({});

  const [totalRecord, setTRecords] = useState(0);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [page, setPage] = useState(0);
  
  const handleView = (rowData) => {
    console.log('store manager :', rowData)
    setView(true); setVObj(rowData);
    setName(rowData.FIRST_NAME + ' ' + rowData.LAST_NAME);
  };

  const loadData = (page, filter, row) => {
    setLoad(true);

    axios({
      method: 'get',
      url: STORE_MANAGERS_LIST+'&limit='+row+'&skip='+(page+1)+(filter!=='' && '&search='+filter),
    })
    .then((res) => {
      if (res.data) {
        setTableData(res.data.rows); setLoad(false);
        setTRecords(res.data.total_count);
      } else {
        setLoad(false); setTRecords(0);
      }
    })
    .catch( (err) => {
      console.log(err);
    });
  };
  
  useEffect(() => {
    loadData(page, filter, rows);
  }, []);

  const tableHeader = () => {
    return <div className='DataTable'>
      <div className='m-2'>
        <h3>Store Managers List</h3></div>
      
      <div className='table-header-action'>
        <InputText className='m-2' value={filter} onChange={(e) => setFilter(e.target.value)} placeholder='Search...' />
      </div>
    </div>
  };

  const actionHeader = (rowData) => {
    return <img
      alt='View Image' src={View}
      onClick={() => handleView(rowData)}
      style={{width: '25px', height: '20px'}}
    />
  };

  const nameBody = (row) => {
    return row.LAST_NAME !== null ? <span>{row.FIRST_NAME +' '+ row.LAST_NAME}</span> : row.FIRST_NAME;
  };

  const pageTemplate = {
    layout: 'RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        { label: 10, value: 10 },
        { label: 25, value: 25 },
        { label: 50, value: 50 },
        { label: 100, value: 100 },
      ];

      return (
        <React.Fragment>
          <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
        </React.Fragment>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '150px', textAlign: 'center' }}>
          {options.first} - {options.last} of {options.totalRecords}
        </span>
      )
    }
  };

  const onPage = (e) => {
    setFirst(e.first); setPage(e.page); 
    setRows(e.rows); loadData(e.page, filter, e.rows);
  };

  return (
    <div className='pt-4 m-3'>
      <div style={{ width: "100%" }} className='datatable'>
        <DataTable 
          value={tableData} removableSort loading={load}
          globalFilter={filter} header={tableHeader}
        >
          <Column header='Store Manager Name' field='FIRST_NAME' body={nameBody} sortable />
          <Column header='Email' field='EMAIL' sortable />
          <Column header='Mobile' field='MOBILE_NUMBER' sortable />
          <Column header='Gender' field='GENDER' sortable />
          <Column header='Actions' body={actionHeader} />
        </DataTable>

        <Paginator rows={rows} first={first} totalRecords={totalRecord} onPageChange={onPage} template={pageTemplate} />
      </div>

      {/* View Store Manager */}
      <Dialog 
        visible={view} onHide={(() => setView(false))} style={{width: '55vw', height: 'auto'}}
        header={<div style={{textAlign: 'center'}}> Store Manager - {name} </div>}
      >
        <Container>
          <Row>
            <Col xs={4}>Name</Col>
            <Col xs={1}>:</Col>
            <Col xs={5}>{viewObj.FIRST_NAME +" "+ viewObj.LAST_NAME}</Col>

            <Col xs={4}>Email</Col>
            <Col xs={1}>:</Col>
            <Col xs={5}>{viewObj.EMAIL}</Col>

            <Col xs={4}>Mobile Number</Col>
            <Col xs={1}>:</Col>
            <Col xs={5}>{viewObj.MOBILE_NUMBER}</Col>

            <Col xs={4}>Gender</Col>
            <Col xs={1}>:</Col>
            <Col xs={5}>{viewObj.GENDER}</Col>
          </Row>
        </Container>
      </Dialog>
    </div>
  )
};

export default UsersList;