import React, { useState, useEffect } from 'react';

import { Container, Row, Col, Button } from 'reactstrap';

import { ADD_BIKE_OWN, ADD_BIKE_REFER, BIKE_OWN_LIST, BIKE_REFER_LIST } from '../../config/config';

import axios from 'axios';

import Role from '../Sidebar/icons/userRoles.svg';
import View from '../icons/view.svg';

import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';

const BikeReferal = () => {
  let mainId = JSON.parse(sessionStorage.getItem('id'));

  const [filter, setFilter] = useState('');
  const [load, setLoad] = useState(false);
  const [view, setView] = useState(false);
  const [userAdd, setUAdd] = useState(false);
  const [add, setAdd] = useState(false);
  const [errs, setErr] = useState(null);
  const [bArr, setBArr] = useState([]);
  const [rArr, setRArr] = useState([]);
  const [selObj, setSObj] = useState({});
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [name, setName] = useState('');
  const [refer, setRefer] = useState('');
  const [oid, setOId] = useState('');
  const [oPlat, setOPlat] = useState('');
  const [isBuy, setBuy] = useState(true);
  const [discount, setDiscount] = useState(0);
  const [amount, setAmount] = useState(0);
  const [pStatus, setPStatus] = useState('');
  const [wDays, setDays] = useState(0);
  const [oDate, setODate] = useState(null);

  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [totalRecord, setTRecord] = useState(0);
  const [page, setPage] = useState(0);

  const platforms = [
    {value: 'ATUM_LIFE', name: 'ATUM Life'},
    {value: 'ATUM_CHARGE', name: 'ATUM Charge'},
    {value: 'ATUMOBILE', name: 'ATUM Mobile'},
    {value: 'OFFLINE', name: 'Offline'},
  ];

  const payStatus = [
    {value: 'PENDING', name: 'pending'},
    {value: 'SUCCESS', name: 'success',}
  ];

  // const payType = [
  //   {value: 'ONLINE', name: 'online'},
  //   {value: 'OFFLINE', name: 'offline'},
  // ];

  const loading = (page) => {
    setLoad(true);

    axios({
      method: 'get',
      url: BIKE_OWN_LIST+"&limit=10&skip="+(page+1)+'&atumBikeUsers=true',
    }).then((res) => {
      if (res.data.data) {
        setBArr(res.data.data.rows); setLoad(false);
        setTRecord(res.data.data.total_count);
      } else {
        setLoad(false); setTRecord(0);
      }
    }).catch((err) => {
      console.log(err);
    });
  };

  useEffect(() => {
    loading(page);
  }, [mainId]);

  useEffect(() => {
    if (load === true) {
      const timer = setTimeout(() => { setLoad(false) }, 1500);
      return () => clearTimeout(timer);
    };
  }, [load]);
  
  const handleView = (row) => {
    setView(true); setSObj(row);

    axios({
      method: 'get',
      url: BIKE_REFER_LIST + row.BIKE_REFERRAL_CODE,
    }).then((res) => {
      setRArr(res.data.data.rows);
    }).catch((err) => {
      console.log(err);
    })
  };

  const handleAdd = (row) => {
    setSObj(row); setUAdd(true);
  };

  const handleClose = () => {
    setView(false); setAdd(false); setUAdd(false);
    setSObj({}); setName(''); setRefer(''); setMobile('');
    setEmail(''); setErr(null); setBuy(false); setRArr([]);
  };

  const tableHeader=()=>{
    return <div className='DataTable'>
      <div className='m-2'>
        <h3>Bike Referral List</h3></div>

      <div className='table-header-action'>
        <InputText className='m-2' value={filter} onChange={(e)=>setFilter(e.target.value)} placeholder='Search...' />
        
        <Button variant="contained" className='table-button m-2' style={{fontSize:'12px'}}
          onClick={() => {setAdd(true); setName(''); setEmail(''); setMobile('')}}
        >
          Add Bike Owner
        </Button>
      </div>
    </div>
  };

  const handleAddUser = () => {
    if(email!=='' && mobile!=='' && name!==''){

      let obj = {
        'FULL_NAME': name,
        'EMAIL': email,
        'MOBILE_NUMBER': mobile,
        'REFERRED_BY': refer,
        'IS_BIKE_PURCHASED': isBuy,
        'CREATED_BY': mainId,
        'UPDATED_BY': mainId,
      };

      axios({
        method: 'post',
        url: ADD_BIKE_OWN,
        data: obj,
      }).then((res) => {
        setAdd(false);
        loading();
      }).catch((err) => {
        console.log(err);
      });
    } else {
      let erros = 'Please Fill Details - ';

      if(email===''){
        erros += 'Email Id ';
      };

      if(name===''){
        erros += 'Owner Name ';
      };

      if(mobile===''){
        erros += 'Mobile No.';
      };

      setErr(erros);
    };
  };

  const handleAddRefer = () => {
    if(email!=='' && mobile!=='' && name!=='' && oPlat!==''){

      let obj = {
        'FULL_NAME': name,
        'EMAIL': email,
        'MOBILE_NUMBER': mobile,
        'REFERRED_BY': selObj.BIKE_REFERRAL_CODE,
        'IS_BIKE_PURCHASED': isBuy,
        'ORDER_ID': oid,
        'ORDER_PLATFORM': oPlat,
        'BONUS_AMOUNT_TO_REFERRER': parseInt(amount),
        'DISCOUNT_AMOUNT': parseInt(discount),
        'WAIT_PERIOD_IN_DAYS': parseInt(wDays),
        'ORDER_DATE': new Date(oDate),
        'CREATED_BY': mainId,
        'UPDATED_BY': mainId,
      };

      axios({
        method: 'post',
        url: ADD_BIKE_REFER,
        data: obj,
      }).then((res) => {
        setUAdd(false);
        loading();
      }).catch((err) => {
        console.log(err);
      });
    } else {
      let erros = 'Please Fill Details - ';

      if(email===''){
        erros += 'Email Id ';
      };

      if(name===''){
        erros += 'Owner Name ';
      };

      if(mobile===''){
        erros += 'Mobile No.';
      };

      setErr(erros);
    };
  };

  const template2 = {
    layout: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport',
    'CurrentPageReport': (options) => {
      return (
        <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '150px', textAlign: 'center' }}>
          {options.first} - {options.last} of {options.totalRecords}
        </span>
      )
    },
  };

  const onCustomPage = (event) => {
    setFirst(event.first);
    setRows(event.rows); setPage(event.page);
  };

  const actionBody = (row) => {
    return <div style={{display: 'flex', flexDirection: 'row'}}>
      <img src={Role} style={{marginRight: '25%', width: '25px', height:'20px'}} onClick={() => handleAdd(row)} />
      <img src={View} onClick={() => handleView(row)} style={{marginRight: '25%', width: '25px', height:'20px'}} />
      {/* <Add onClick={() => {setTrans(true); setSObj(row)}} /> */}
    </div>
  };

  const discountBody = (row) => {
    return `₹ ${row.DISCOUNT_AMOUNT.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  };

  const referBody = (row) => {
    return `₹ ${row.BONUS_AMOUNT_TO_REFERRER.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  };

  const handleName = (e) => {
    if(/^([a-zA-Z]+\s)*[a-zA-Z]+$/g.test(e.target.value)){
      setName(e.target.value);
    }else if(e.target.value.length===0){
      setName('');
    }
  };

  const handleMobile = (e) => {
    if(e.target.value.length <= 10){
      if(/^([0-9]+\s)*[0-9]+$/g.test(e.target.value)){
        setMobile(e.target.value);
      }else if(e.target.value.length === 0){
        setMobile('');
      };
    };
  };

  return(
    <div className="pt-4 m-3">
      <div style={{ width: "100%" }} className='datatable'>
        <DataTable 
          header={tableHeader} value={bArr} removableSort globalFilter={filter} 
          loading={load} scrollable scrollHeight="61vh"
        >
          <Column header='Bike Owner' field='FULL_NAME' sortable />
          <Column header='Bike Refer' field='BIKE_REFERRAL_CODE' sortable />
          <Column header='Mobile No.' field='MOBILE_NUMBER' sortable />
          <Column header='Action' body={actionBody} />
        </DataTable>

        <Paginator rows={rows} first={first} totalRecords={totalRecord} onPageChange={onCustomPage} template={template2} />
      </div>

      {/* View Bike Owner */}
      <Dialog 
        visible={view} maximizable onHide={handleClose} 
        style={{width: '75vw', height: '67vh'}}
        header={<div>{`View Bike Referral - ${selObj.FULL_NAME}`}</div>}
      >
        <Container>
          <Row>
            <Col xs={4} style={{marginLeft: '25%'}}>
              Bike User
            </Col>
            <Col xs={1}>
              :
            </Col>
            <Col xs={7}>
              {selObj.FULL_NAME}
            </Col>

            <Col xs={4} style={{marginLeft: '25%'}}>
              Referral Code
            </Col>
            <Col xs={1}>
              :
            </Col>
            <Col xs={7}>
              {selObj.BIKE_REFERRAL_CODE}
            </Col>

            <Col xs={4} style={{marginLeft: '25%'}}>
              Email
            </Col>
            <Col xs={1}>
              :
            </Col>
            <Col xs={7}>
              {selObj.EMAIL}
            </Col>

            <Col xs={4} style={{marginLeft: '25%'}}>
              Mobile Number
            </Col>
            <Col xs={1}>
              :
            </Col>
            <Col xs={7}>
              {selObj.MOBILE_NUMBER}
            </Col>

            <Col xs={12}>
              <DataTable value={rArr} scrollable scrollHeight="250px">
                <Column field='FULL_NAME' header='Referral User Name' />
                <Column field='EMAIL' header='Referral User Email' />
                <Column field='MOBILE_NUMBER' header='Referral User Mobile' />
                <Column field='BONUS_AMOUNT_TO_REFERRER' header='Referral Bonus' body={referBody} />
                <Column field='DISCOUNT_AMOUNT' header='Discount Amount' body={discountBody} />
                <Column field='ORDER_PLATFORM' header='Order Platform' />
                <Column field='WAIT_PERIOD_IN_DAYS' header='Waiting Period' />
              </DataTable>
            </Col>
          </Row>
        </Container>
      </Dialog>

      {/* Add Bike Owner  */}
      <Dialog 
        visible={add} onHide={handleClose} 
        style={{width: '55vw', height: 'auto'}}
        header={<div style={{textAlign: 'center'}}>Add New Bike Owner</div>}
      >
        <Container style={{marginTop: '2%'}}>
          <Row>
            <Col xs={6}>
              <label>Owner Name *</label>
              <InputText value={name} onChange={handleName} style={{width: '100%'}} />
            </Col>

            <Col xs={6}>
              <label>Mobile No. *</label>
              <InputText value={mobile} onChange={handleMobile} style={{width: '100%'}} />
            </Col>

            <Col xs={6}>
              <label>Email Id *</label>
              <InputText value={email} onChange={(e) => setEmail(e.target.value)} style={{width: '100%'}} />
            </Col>

            <Col xs={6}>
              <label>ATUM Refer Code</label>
              <InputText value={refer} onChange={(e) => setRefer(e.target.value)} style={{width: '100%'}} />
            </Col>

            <Col xs={12} style={{textAlign: 'center', margin: '5px'}}>
              <Checkbox color="primary" checked={isBuy} onChange={(e) => setBuy(e.checked)} />
              <span style={{marginLeft: '10px'}}>isBikePurchased</span>
            </Col>

            <Col xs={12} style={{textAlign: 'center'}}>
              <Button variant="contained" color="primary" onClick={handleAddUser}>Submit</Button>
            </Col>
          </Row>
        </Container>
      </Dialog>

      {/* Add Bike Referral */}
      <Dialog 
        visible={userAdd} onHide={handleClose}
        style={{width: '55vw', height: '90vh'}} 
        header={<div style={{textAlign: 'center'}}>Add Bike Referral - {selObj.FULL_NAME}</div>}
        footer={<div style={{textAlign: 'center'}}><Button variant="contained" color="primary" onClick={handleAddRefer}>Submit</Button></div>}
      >
        <Container style={{marginTop: '1%'}}>
          <Row>
            <Col xs={6}>
              <label>Owner Name *</label>
              <InputText value={name} onChange={handleName} style={{width: '100%'}} />
            </Col>

            <Col xs={6}>
              <label>ATUM Refer Code *</label>
              <InputText value={selObj.BIKE_REFERRAL_CODE} disabled={true} style={{width: '100%'}} />
            </Col>

            <Col xs={6}>
              <label>Mobile No. *</label>
              <InputText value={mobile} onChange={handleMobile} style={{width: '100%'}} />
            </Col>

            <Col xs={6}>
              <label>Email Id *</label>
              <InputText value={email} onChange={(e) => setEmail(e.target.value)} style={{width: '100%'}} />
            </Col>

            <Col xs={6}>
              <label>Order ID</label>
              <InputText value={oid} onChange={(e) => setOId(e.target.value)} style={{width: '100%'}} />
            </Col>

            <Col xs={6}>
              <label>Order Platform *</label>
              <Dropdown 
                value={oPlat} style={{width: '100%'}}
                onChange={(e) => setOPlat(e.target.value)}  
                options={platforms} optionLabel='name' optionValue='value'
              />
            </Col>

            <Col xs={4}>
              <label>Payment Status *</label>
              <Dropdown 
                value={pStatus} style={{width: '100%'}}
                onChange={(e) => setPStatus(e.target.value)} 
                options={payStatus} optionLabel='name' optionValue='value'
              />
            </Col>

            <Col xs={4}>
              <label>Discount Amount *</label>
              <InputText value={discount} onChange={(e) => setDiscount(e.target.value)} style={{width: '100%'}} />
            </Col>

            <Col xs={4}>
              <label>Referral Amount *</label>
              <InputText value={amount} onChange={(e) => setAmount(e.target.value)} style={{width: '100%'}} />
            </Col>

            <Col xs={6}>
              <label>Order Date</label>
              <Calendar 
                value={oDate} onChange={(e) => setODate(e.value)} 
                showIcon dateFormat="DD/MM/YYYY"
              />
            </Col>

            <Col xs={6}>
              <label>Waiting Day Count *</label>
              <InputText value={wDays} onChange={(e) => setDays(e.target.value)} style={{width: '100%'}} />
            </Col>

            <Col xs={12}>
              <Checkbox color="primary" checked={isBuy} onChange={(e) => setBuy(e.checked)} />
              <span>isBikePurchased</span>
            </Col>
          </Row>
        </Container>
      </Dialog>
    </div>
  );
};

export default BikeReferal;