import React, { useState, useEffect } from 'react';

import { Chart } from 'primereact/chart';

import axios from 'axios';

import { useHistory } from 'react-router-dom';

import { Row, Col } from 'reactstrap';

import { DASHBOARD_LIST } from '../../config/config';
import '../home/home.css'; 

export default function Charts({cat, ords}){
  const [clabel, setCLabel] = useState([]);
  const [cData, setCData] = useState([]);
  const [olabel, setOLabel] = useState([]);
  const [oData, setOData] = useState([]);
  const [orders, setOrdrs] = useState(0);
  const [bgColor, setBColor] = useState([]);

  const history = useHistory();

  let multiAxisData = {
    labels: clabel,
    datasets: [{
      label: 'Product Count',
      backgroundColor: [
        "#FF6384",
        "#36A2EB",
        "#FFCE56",
        '#00BFFF',
        '#808080',
        '#800080',
        '#ADFF2F',
        '#00FA9A',
        '#D2691E',
        '#FFD700',
        '#008000',
        '#2F4F4F',
        '#FF0000',
        '#00FFFF',
        '#006400',
      ],
      yAxisID: 'y',
      data: cData,
    }]
  };

  let chartData = {
    labels: olabel,
    datasets: [
      {
        data: oData,
        backgroundColor: bgColor,
      }
    ]
  };

  let basicOptions = {
    maintainAspectRatio: false,
    aspectRatio: .8,
    plugins: {
      legend: {
        labels: {
          color: '#495057'
        }
      }
    },
    scales: {
      x: {
        ticks: {
          color: '#495057'
        },
        grid: {
          color: '#ebedef'
        }
      }, y: {
        ticks: {
          color: '#495057'
        },
        grid: {
          color: '#ebedef'
        }
      }
    }
  };

  useEffect(() => {
    axios({
      method: 'get',
      url: DASHBOARD_LIST,
    }).then((res) => {
      let cnt=[], lbl=[], cnt2=[], lbl2=[], ords=null, color=[
        'red', 'forestgreen', 'dodgerblue', 'orange', 'grey', '#DAA520', '#FF8C00', '#6B8E23'  
      ];
      
      for(let i=0; i<res.data.CATEGORIES_COUNT.length; i++){
        lbl.push(res.data.CATEGORIES_COUNT[i].CATEGORY_DISPLAY_NAME);
        cnt.push(res.data.CATEGORIES_COUNT[i].PRODUCTS_COUNT);
      };

      for(let j=0; j<res.data.ORDERS_COUNT.length; j++){
        lbl2.push(res.data.ORDERS_COUNT[j].STATUS);

        if(res.data.ORDERS_COUNT[j].STATUS==='DELIVERED'){
          color[j] = 'forestgreen';
        };

        if(res.data.ORDERS_COUNT[j].STATUS==='CANCELLED'){
          color[j] = 'red';
        }

        if(res.data.ORDERS_COUNT[j].STATUS==='ORDERED'){
          color[j] = 'dodgerblue';
        };

        if(res.data.ORDERS_COUNT[j].STATUS==='DISPATCHED'){
          color[j] = 'orange';
        }

        if(res.data.ORDERS_COUNT[j].STATUS==='PREBOOK'){
          color[j] = 'grey';
        }

        if(res.data.ORDERS_COUNT[j].STATUS==='READY_TO_DISPATCH'){
          color[j] = '#DAA520'
        }

        if(res.data.ORDERS_COUNT[j].STATUS==='READY_TO_PICKUP'){
          color[j] = '#FF8C00'
        }

        if(res.data.ORDERS_COUNT[j].STATUS==='PICKED_UP'){
          color[j] = '#6B8E23'
        }

        cnt2.push(res.data.ORDERS_COUNT[j].STATUS_COUNT); ords+=res.data.ORDERS_COUNT[j].STATUS_COUNT;
      }; 

      setCLabel(lbl); setCData(cnt); setOLabel(lbl2); 
      setOData(cnt2); setOrdrs(ords); setBColor(color);
    }).catch((err) => {
      console.log(err);
    })
  }, []);

  return(
    <Row>
      {cat===true && <Col xs={12} md={8}> 
        <div className="card text-center chart_card">
          <h5 style={{cursor: 'pointer'}} onClick={() => history.push('/categories')}>Categories</h5>
          <Chart type="bar" data={multiAxisData} options={basicOptions} style={{width: '98%', textAlign: 'center', height: '90%'}} />
        </div>
      </Col>}

      {ords===true && <Col xs={12} md={4}>
        <div className="card text-center chart_card2">
          <h5 style={{cursor: 'pointer'}} onClick={() => history.push('/orders')}>Orders - ({orders})</h5>
          <Chart type="doughnut" data={chartData} options={chartData} style={{width: '95%', alignItems: 'center', height: '95%'}} />
        </div>
      </Col>}
    </Row>
  );
};