import Home from '../components/home/home';
import PrivacyPolicy from '../components/PrivacyPolicy/PrivacyPolicy';
import UsersList from '../components/Users/UsersList';
import StoresList from '../components/Stores/StoresList';
import LookupTypes from '../components/LookupTypes/LookupTypes';
import LookupCodes from '../components/LookupCodes/LookupCodes';
import RolesList from '../components/Roles/RolesList';
import ProductsList from '../components/Products/ProductsList';
import AddProduct from '../components/Products/Add';
import ViewProduct from '../components/Products/View';
import BannersList from '../components/Banners/BannersList';
import StoreManagers from '../components/StoreManagers/StoreManagers';
import Faqs from '../components/Faqs/Faqs';
import CategoriesList from '../components/Categories/CategoriesList';
import ChangePassword from '../components/ChangePassword/ChangePassword';
import Profile from '../components/Profile/Profile';
import NotificationsList from '../components/Notifications/NotificationsList';
import EndUsersList from '../components/EndUsers/EndUsers';
import OrderList from '../components/Order/Orders';
import Settings from '../components/settings/settings';
import Coupons from '../components/Coupons/Coupons';
import Reports from '../components/Reports/Reports';
import DeliveryAddress from '../components/DeliveryAddress/DeliveryAddress';
import Blog from '../components/Blog/Blog';
import OfflineOrders from '../components/offlineorder';
import BikeReferal from '../components/BikeRefer';
import ProductAttributes from '../components/productAttributes/productAttributes';
import Inventory from '../components/Inventory';
import Loggers from '../components/loggers/loggers';

const routes = [
    {
        path: "/home",
        component: Home
    },
    {
        path: "/privacypolicy",
        component: PrivacyPolicy
    },
    {
        path: "/products",
        component: ProductsList
    },
    {
        path: "/product/add",
        component: AddProduct
    },
    {
        path: "/product/edit/:id",
        component: AddProduct
    },
    {
        path: "/product/view/:id",
        component: ViewProduct
    },
    {
        path: "/users",
        component: UsersList
    },
    {
        path: "/stores",
        component: StoresList
    },
    {
        path: "/lookuptypes",
        component: LookupTypes
    },
    {
        path: "/lookupcodes",
        component: LookupCodes
    },
    {
        path: "/roleslist",
        component: RolesList
    },
    {
        path: "/bannerslist",
        component: BannersList
    },
    {
        path: "/storemanagers",
        component: StoreManagers
    },
    {
        path: "/faqs",
        component: Faqs
    },
    {
        path: "/categories",
        component: CategoriesList
    },
    {
        path: "/changepassword",
        component: ChangePassword
    },
    {
        path: "/profile/:id",
        component: Profile
    },
    {
        path: "/notifications",
        component: NotificationsList
    },
    {
        path: "/endusers",
        component: EndUsersList
    },
    {
        path:'/bikereferral',
        component: BikeReferal
    },
    {
        path: '/orders',
        component: OrderList
    },
    {
        path: '/settings',
        component: Settings
    },
    {
        path: '/coupons',
        component: Coupons
    },
    {
        path: '/reports',
        component: Reports
    },
    {
        path: '/deliveryaddress',
        component: DeliveryAddress
    },
    {
        path: '/blog',
        component: Blog
    },
    {
        path: '/storeorder',
        component: OfflineOrders
    },
    {
        path: '/productattributes',
        component: ProductAttributes
    },
    {
        path: '/productinventory',
        component: Inventory
    },
    {
        path: '/logs',
        component: Loggers,
    }
];

export default routes;