import React, { useState, useEffect } from 'react';

import { Container, Row, Col, Button } from 'reactstrap';

import axios from 'axios';

import Add from '../icons/add.svg';
import Edit from '../icons/edit.svg';
import View from '../icons/view.svg';
import Delete from '../icons/delete.svg';
import Banner from '../Sidebar/icons/banners.svg';

import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Paginator } from 'primereact/paginator';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { Image } from 'primereact/image';

import {
  NOTIFICATIONS_LIST, EDIT_NOTIFICATIONS, GLOBAL_DOMAIN,
  ADD_NOTIFICATION_TYPES, ADD_NOTIFICATION_DELIVERY_TYPES,
  UPLOAD_IMAGE_NOTIFICATIONS, ADD_NOTIFICATIONS, ADMIN_LIST,
  CUSTOMER_LIST, IMAGE_DOMAIN
} from '../../config/config.js';

const NotificationsList = () => {
  const [tableData, setTableData] = useState([]);
  const [open, setOpen] = useState(false);
  const [popup, setPopup] = useState(false);
  const [newId, setNewId] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [filter, setFilter] = useState('');
  const [load, setLoad] = useState(false);
  const [imgs, setImgs] = useState(null);
  const [notificationText, setNotificationText] = useState('');
  const [TextNotification, setTextNotification] = useState('');
  const [WhatsAppNotification, setWhatsAppNotification] = useState('');
  const [notificationHeading, setNotificationHeading] = useState('');
  const [notificationLink, setNotificationLink] = useState('');

  const [admin, setAdmin] = useState([]);
  const [custmr, setCust] = useState([]);
  const [selObj, setSObj] = useState('');
  const [user, setUser] = useState(null);
  const [userId, setUId] = useState(null);
  const [notificationType, setNotificationType] = useState('');
  const [notificationDropdown, setNotificationDropdown] = useState([]);
  const [notification, setNotification] = useState([]);
  const [deliveryDropdown, setDeliveryDropdown] = useState([]);
  const [delivery, setDelivery] = useState('');
  const [deliveryType, setDeliveryType] = useState('');
  const [actionType, setActionType] = useState('');
  const [recordId, setRecordId] = useState('');
  const [isPublish, setisPublish] = useState(false);
  const [flagText, setflagText] = useState(false);
  const [flagWhatsApp, setflagWhatsApp] = useState(false);
  const [flagPush, setflagPush] = useState(true);

  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [page, setPage] = useState(0);
  const [totalRecord, setTRecord] = useState(0);

  const [dView, setDView] = useState(false);

  const handleOpen = () => {
    setOpen(true); setActionType('add'); setImgs(null);
  };

  const handleClose = () => {
    setOpen(false); setPopup(false); setisPublish(false);
    setActionType(''); setNotificationText('');
    setNotification(''); setNotificationType('');
    setDeliveryType(''); setDelivery('');
    setNotificationLink(''); setNotificationHeading('');
  };

  let loginUserId = JSON.parse(sessionStorage.getItem('id')) ? JSON.parse(sessionStorage.getItem('id')) : 1;

  const handleView = (e) => {
    setPopup(true); setSObj(e);
  };

  const handleEdit = (event) => {
    setOpen(true); setRecordId(event.ID); setSObj(event);
    setisPublish(event.IS_PUBLISH); setNotificationText(event.NOTIFICATION_TEXT);
    setNotificationType(event.NOTIFICATION_TYPE_ID); setDeliveryType(event.DELIVERY_TYPE_ID);
    setNotificationLink(event.NOTIFICATION_LINK); setNotificationHeading(event.NOTIFICATION_HEADING);
  };

  const handleClickOpen = (e) => {
    setNewId(e.ID); setOpenPopup(true);
  };

  const handleCloseDelete = () => {
    setOpenPopup(false);
  };

  const getNotificationTypes = () => {
    axios({
      method: 'get',
      url: ADD_NOTIFICATION_TYPES
    }).then((res) => {
      if (res.data) {
        setNotificationDropdown(res.data); setNotification(res.data[0].LOOKUP_NAME);
      }
    }).catch((err) => {
      console.log(err);
    });
  };

  const getNotificationDeliveryTypes = () => {
    axios({
      method: 'get',
      url: ADD_NOTIFICATION_DELIVERY_TYPES
    }).then((res) => {
      if (res.data) {
        setDeliveryDropdown(res.data); setDelivery(res.data[0].LOOKUP_NAME);
      }
    }).catch((err) => {
      console.log(err);
    });
  };

  const getNotificationsList = (page, row) => {
    axios({
      method: 'get',
      url: NOTIFICATIONS_LIST+'&limit='+row+'&skip='+(page+1)+'&userId='+ loginUserId,
    }).then((res) => {
      if (res.data) {
        setTableData(res.data.rows); setLoad(false);
        setTRecord(res.data.total_count);
      } else {
        setLoad(false); setTRecord(0);
      }
    }).catch((err) => {
      console.log(err);
    });
  };

  const getUsers = () => {
    axios({
      method: 'get',
      url: ADMIN_LIST
    }).then((res) => {
      setAdmin(res.data);
    }).catch((err) => {
      console.log(err);
    });

    axios({
      method: 'get',
      url: CUSTOMER_LIST
    }).then((res) => {
      setCust(res.data.rows);
    }).catch((err) => {
      console.log(err);
    });
  };

  const handleChangeNotification = (e) => {
    const data = notificationDropdown.filter((option) => (option.ID == e.target.value));
    setNotification(data[0].LOOKUP_NAME); setNotificationType(data[0].ID);
  };

  const handleChangeDelivery = (e) => {
    const data = deliveryDropdown.filter((option) => (option.ID == e.target.value));
    setDelivery(data[0].LOOKUP_NAME); setDeliveryType(data[0].ID);
  };

  const handleChangeUser = (e) => {
    if (delivery === 'For Admins') {
      const data = admin.filter((option) => (option.ID == e.target.value));
      setUser(data[0].NAME); setUId(data[0].ID);
    } else {
      const data = custmr.filter((option) => (option.ID == e.target.value));
      setUser(data[0].FIRST_NAME + ' ' + data[0].LAST_NAME); setUId(data[0].ID);
    };
  };

  const handleDelete = () => {
    axios({
      method: 'delete',
      url: EDIT_NOTIFICATIONS + newId,
      'Content-Type': 'multipart/form-data',
      headers: { token: sessionStorage.getItem('token') }
    }).then((res) => {
      console.log(res);
    });
    setOpenPopup(false);
  };

  useEffect(() => {
    getNotificationsList(page, rows); getUsers();
    getNotificationTypes(); getNotificationDeliveryTypes();
  }, [popup, openPopup]);

  useEffect(() => {
    let list = JSON.parse(sessionStorage.getItem('roleJson'));
    setDView(!list.menu['notifications'].enabled); 
  }, []);

  const tableHeader = () => {
    return <div className='DataTable'>
      <div><h3>Notification List</h3></div>

      <div className='table-header-action status_bu'>
        <InputText className='m-2' value={filter} onChange={(e) => setFilter(e.target.value)} placeholder='Search...' />
        <Button variant="contained" className='contained_primary table-button m-2' onClick={handleOpen}><img src={Add} style={{ width: '25px', height: '20px', marginRight: 0.5 }} /> Add </Button>
      </div>
    </div>
  };

  const actionHeader = (row) => {
    return <div>
      <img
        src={View} alt='View'
        onClick={() => handleView(row)}
        style={{width: '25px', height: '20px', marginRight: '7.5px'}}
      />

      {row.IS_PUBLISH !== 1 && 
        <img
          src={Edit} alt='Edit'
          onClick={() => handleEdit(row)}
          style={{width: '25px', height: '20px', marginRight: '7.5px'}}
        />
      }

      <img
        src={Delete} alt='Delete'
        onClick={() => handleClickOpen(row)}
        style={{width: '25px', height: '20px'}}
      />
    </div>
  };

  const pageTemplate = {
    layout: 'RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        { label: 10, value: 10 },
        { label: 25, value: 25 },
        { label: 50, value: 50 },
        { label: 100, value: 100 },
      ];

      return (
        <React.Fragment>
          <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
        </React.Fragment>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '150px', textAlign: 'center' }}>
          {options.first} - {options.last} of {options.totalRecords}
        </span>
      )
    }
  };

  const onPage = (e) => {
    setFirst(e.first); setRows(e.rows);
    setPage(e.page); getNotificationsList(e.page, e.rows);
  };

  const descHeader = (rowData) => {
    const limit = 35

    if (rowData.NOTIFICATION_TEXT.length <= limit) {
      return <div>{rowData.NOTIFICATION_TEXT}</div>
    } else {
      return <div>{rowData.NOTIFICATION_TEXT.slice(0, limit) + '...'}</div>
    }
  };

  const statusHeader = (rowData) => {
    if (rowData.IS_PUBLISH === 0) {
      return <span style={{ color: 'goldenrod', fontWeight: '700' }}>DRAFT</span>
    } else {
      return <span style={{ color: 'darkgreen', fontWeight: '700' }}>PUBLISH</span>
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault(); setLoad(true);
    let NOTIFICATION_TYPE_ID = notificationType;
    let NOTIFICATION_TYPE = notification;
    let DELIVERY_TYPE_ID = deliveryType;
    let DELIVERY_TYPE = delivery;
    let NOTIFICATION_TEXT = notificationText;
    let NOTIFICATION_HEADING = notificationHeading;
    let NOTIFICATION_LINK = notificationLink;
    let USER_ID = userId;
    let CREATED_BY = loginUserId;
    let UPDATED_BY = loginUserId;
    let IS_PUSH_NOTIFICATION = flagPush;
    let IS_TEXT_NOTIFICATIONS = flagText;
    let IS_WHATSAPP_NOTIFICATIONS = flagWhatsApp;
    let WHATSAPP_TEXT = WhatsAppNotification;
    let TEXT_MESSAGE_TEXT = TextNotification;
    let IS_PUBLISH = isPublish;
    axios({
      method: 'post',
      url: ADD_NOTIFICATIONS,
      'Content-Type': 'multipart/form-data',
      data: USER_ID !== null ? {
        NOTIFICATION_TYPE_ID,
        NOTIFICATION_TYPE,
        DELIVERY_TYPE_ID,
        DELIVERY_TYPE,
        NOTIFICATION_TEXT,
        NOTIFICATION_HEADING,
        NOTIFICATION_LINK,
        IS_PUSH_NOTIFICATION,
        IS_TEXT_NOTIFICATIONS,
        IS_WHATSAPP_NOTIFICATIONS,
        WHATSAPP_TEXT,
        TEXT_MESSAGE_TEXT,
        IS_PUBLISH,
        USER_ID,
        CREATED_BY,
        UPDATED_BY
      } : {
        NOTIFICATION_TYPE_ID,
        NOTIFICATION_TYPE,
        DELIVERY_TYPE_ID,
        DELIVERY_TYPE,
        NOTIFICATION_TEXT,
        NOTIFICATION_HEADING,
        NOTIFICATION_LINK,
        IS_PUBLISH,
        CREATED_BY,
        UPDATED_BY
      },
      headers: { token: sessionStorage.getItem('token') }
    }).then(res => {
      if (res) {
        getNotificationsList(); handleClose();
        setNotificationText(''); setNotification('');
        setDelivery(''); setNotificationLink('');
        setNotificationHeading(''); setLoad(false);

        if (imgs.length > 0) {
          handleUploadFile(res.data.ID)
        };
      };
    });
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();
    let NOTIFICATION_TYPE_ID = notificationType;
    let NOTIFICATION_TYPE = notification;
    let DELIVERY_TYPE_ID = deliveryType;
    let DELIVERY_TYPE = delivery;
    let NOTIFICATION_TEXT = notificationText;
    let NOTIFICATION_HEADING = notificationHeading;
    let NOTIFICATION_LINK = notificationLink;
    let USER_ID = userId;
    let UPDATED_BY = loginUserId;
    let IS_PUBLISH = isPublish;
    axios({
      method: 'put',
      url: EDIT_NOTIFICATIONS + recordId,
      'Content-Type': 'multipart/form-data',
      data: USER_ID !== null ? {
        NOTIFICATION_TYPE_ID,
        NOTIFICATION_TYPE,
        DELIVERY_TYPE_ID,
        DELIVERY_TYPE,
        NOTIFICATION_TEXT,
        NOTIFICATION_HEADING,
        NOTIFICATION_LINK,
        IS_PUBLISH,
        UPDATED_BY,
        USER_ID,
      } : {
        NOTIFICATION_TYPE_ID,
        NOTIFICATION_TYPE,
        DELIVERY_TYPE_ID,
        DELIVERY_TYPE,
        NOTIFICATION_TEXT,
        NOTIFICATION_HEADING,
        NOTIFICATION_LINK,
        IS_PUBLISH,
        UPDATED_BY,
      },
      headers: { token: sessionStorage.getItem('token') }
    }).then(response => {
      if (response) {
        getNotificationsList(); handleClose();
        setNotificationText(''); setNotification('');
        setDelivery(''); setNotificationLink('');
        setNotificationHeading('');
      };
    });
  };

  const handleUploadFile = (uploadId) => {
    var formData = new FormData();
    formData.append('issueId', uploadId);
    formData.append('notificationImage', imgs[0]);

    axios({
      method: 'put',
      url: UPLOAD_IMAGE_NOTIFICATIONS + uploadId,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(res => {
      if (res) {
        getNotificationsList(); setOpen(false);
      }
    }).catch(err => {
      console.log(err); setOpen(false);
    })
  };

  const onChagePublish = () => {
    if (isPublish) {
      setisPublish(false);
    } else {
      setisPublish(true);
    }
  };

  // const onChageText = (e) => {
  //   console.log(e)
  //   if (flagText) {
  //     setflagText(false);
  //   } else {
  //     setflagText(true);
  //   }
  // }; 
  
  // const onChageWhatsApp = () => {
  //   if (isPublish) {
  //     setflagWhatsApp(false);
  //   } else {
  //     setflagWhatsApp(true);
  //   }
  // }; 
  
  // const onChagePush = () => {
  //   if (isPublish) {
  //     setflagPush(false);
  //   } else {
  //     setflagPush(true);
  //   }
  // };
  
  return (
    <div className='pt-4 m-3'>
      <div style={{ width: "100%" }} className='datatable'>
        <DataTable
          value={tableData} removableSort size='medium' globalFilter={filter}
          scrollable scrollHeight="60vh" header={tableHeader} loading={load}
        >
          <Column header='Notification Type' field='NOTIFICATION_TYPE' className=' text-truncate' sortable />
          <Column header='Delivery Type' field='DELIVERY_TYPE' className=' text-truncate' sortable />
          <Column header='Notification Text' field='NOTIFICATION_TEXT' className=' text-truncate' sortable body={descHeader} />
          <Column header='Status' field='STATUS' className=' text-truncate' sortable body={statusHeader} />
          <Column header='Actions' body={actionHeader} />
        </DataTable>

        <Paginator rows={rows} first={first} totalRecords={totalRecord} template={pageTemplate} onPageChange={onPage} />
      </div>

      {/* View popup*/}
      <Dialog
        visible={popup} onHide={handleClose}
        header={<div style={{ textAlign: 'center' }}>
          Notification view 
        </div>}
      >
        <Container>
          <Row>
            <Col xs={3}>
              {selObj.IMAGE_PATH === null ? "No Notification Image" : <Image src={IMAGE_DOMAIN + selObj.IMAGE_PATH + selObj.IMAGE_NAME} width='220px' height='200px' style={{ border: '15px' }} preview />}
            </Col>

            <Col xs={8}>
              <Row>
                <Col xs={4}>
                  <div className='sub_heading_1'>Notification Header</div>
                </Col>
                <Col xs={1}>:</Col>
                <Col xs={7}>
                  <div>{selObj.NOTIFICATION_HEADING}</div>
                </Col>
              </Row>

              <Row style={{marginTop: '2%'}}>
                <Col xs={4}>
                  <div className='sub_heading_1'>Notification Type</div>
                </Col>
                <Col xs={1}>:</Col>
                <Col xs={7}>
                  <div>{selObj.NOTIFICATION_TYPE}</div>
                </Col>
              </Row>

              <Row style={{marginTop: '2%'}}>
                <Col xs={4}>
                  <div className='sub_heading_1'>Notification Text</div>
                </Col>
                <Col xs={1}>:</Col>
                <Col xs={7}>
                  <div>{selObj.NOTIFICATION_TEXT}</div>
                </Col>
              </Row>

              <Row style={{marginTop: '2%'}}>
                <Col xs={4}>
                  <div className='sub_heading_1'>Notification Link</div>
                </Col>
                <Col xs={1}>:</Col>
                <Col xs={7}>
                  <a href={GLOBAL_DOMAIN + selObj.NOTIFICATION_LINK} target='_blank' style={{ textDecorationLine: 'none', color: 'dodgerblue', fontWeight: '600' }}>View Link</a>
                </Col>
              </Row>

              <Row style={{marginTop: '2%'}}>
                <Col xs={4}>
                  <div className='sub_heading_1'>Status</div>
                </Col>
                <Col xs={1}>:</Col>
                <Col xs={7}>
                  <div>{selObj.IS_PUBLISH === 1 ? 'Publish' : 'Draft'}</div>
                </Col>
              </Row>

              <Row style={{marginTop: '2%'}}>
                <Col xs={4}>
                  <div className='sub_heading_1'>Delivery Type</div>
                </Col>
                <Col xs={1}>:</Col>
                <Col xs={7}>
                  <div>{selObj.DELIVERY_TYPE}</div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Dialog>

      {/* Add / Edit Pop-up */}
      <Dialog
        visible={open} onHide={handleClose}
        header={<div>
          <h3>
            {actionType === 'add' ? 'Add Notification' : 'Edit Notification'} </h3>
        </div>}
        footer={
          actionType === 'add' ? <div className='status_button'>
            <Button type="submit" variant="contained" className='contained_danger' onClick={handleClose}>Cancel</Button>
            <Button type="submit" variant="contained" className='contained_primary' disabled={load} onClick={handleSubmit}>submit</Button>
          </div> : <div className='status_button'>
            <Button type="submit" variant="contained" onClick={handleClose} className='contained_danger'>Cancel</Button>
            <Button type="submit" variant="contained" onClick={handleEditSubmit} disabled={load} className='contained_primary'>Submit</Button>
          </div>
        }
      >
        <Container className='p-2'>
          <Row>
            {(notification !== '' || notification !== 'Image' || notification !== 'Video') && <Col xs={3}>
              <div>

                {imgs === null ? <label htmlFor='uploadi' style={{ margin: '0.5%' }}>
                  <img src={Banner} style={{ width: '55px', height: '18vh', padding: '0.5%' }} />
                  <div>Upload Image / Video *</div>
                </label> : <>
                  <div onClick={() => setImgs(null)} style={{
                    position: 'absolute', left: '17%', width: '25px', height: '25px', marginTop: '0.5%',
                    border: '1px solid red', background: 'red', borderRadius: '25px',
                    color: 'whitesmoke', fontSize: '15px', fontWeight: '600', cursor: 'pointer',
                  }}>x</div>

                  {actionType === 'add' ? <img
                    src={URL.createObjectURL(imgs)}
                    style={{ width: '12vw', height: '20vh', borderRadius: '15px' }}
                    accept=".png, .jfif, .pjpeg, .jpeg, .pjp, .jpg, audio/*,video/*"
                  /> : imgs !== null ? <img
                    src={imgs} style={{ width: '12vw', height: '20vh', borderRadius: '15px' }}
                    accept=".png, .jfif, .pjpeg, .jpeg, .pjp, .jpg, audio/*,video/*"
                  /> : <img
                    src={URL.createObjectURL(imgs)}
                    style={{ width: '12vw', height: '20vh', borderRadius: '15px' }}
                    accept=".png, .jfif, .pjpeg, .jpeg, .pjp, .jpg, audio/*,video/*"
                  />}
                </>}

                <input id='uploadi' type='file'
                  style={{ display: 'none' }}
                  onChange={(e) => { e.target.files ? setImgs(e.target.files[0]) : setImgs(null) }}
                />
              </div>
            </Col>}

            <Col 
              xs={(notification === '' || notification !== 'Image' || notification !== 'Video') ? 9 : 12} 
              style={(notification === '' || notification !== 'Image' || notification !== 'Video') ? { marginLeft: '-2%' } : {}}
            >
              <Row>
                <Col xs={deliveryType !== '' ? 4 : 6}>
                  <label>Notification Type *</label>
                  <Dropdown value={notificationType} onChange={handleChangeNotification} options={notificationDropdown} optionLabel='LOOKUP_DISPLAY_NAME' optionValue='ID' />
                </Col>

                <Col xs={deliveryType !== '' ? 4 : 6}>
                  <label>Delivery Type *</label>
                  <Dropdown value={deliveryType} onChange={handleChangeDelivery} options={deliveryDropdown} optionLabel='LOOKUP_DISPLAY_NAME' optionValue='ID' />
                </Col>

                {deliveryType !== '' && <Col xs={4}>
                  {deliveryType === 10 ? <div style={{width: '100%'}}>
                    <label>Admin Users</label>
                    <Dropdown value={userId} onChange={handleChangeUser} options={admin} optionLabel='Name' optionValue='ID' />
                  </div> : <div style={{ width: '100%' }}>
                    <label>End Users</label>
                    <Dropdown value={userId} onChange={handleChangeUser} options={custmr} optionLabel={'FIRST_NAME LAST_NAME'} optionValue='ID' />
                  </div>}
                </Col>}

                <Col xs={6}>
                  <label>Notification Heading *</label>
                  <InputTextarea style={{ width: '100%' }} value={notificationHeading} onChange={(e) => setNotificationHeading(e.target.value)} />
                </Col>
                
                <Col xs={6}>
                  <label>Notification Link *</label>
                  <InputTextarea style={{ width: '100%' }} value={notificationLink} onChange={(e) => setNotificationLink(e.target.value)} />
                </Col>

                <Col xs={3}>
                  <Checkbox checked={flagText} onChange={(e) => setflagText(e.checked)} />
                  <label>Text Notification</label>
                </Col>

                <Col xs={3}>
                  <Checkbox checked={flagWhatsApp} onChange={(e) => setflagWhatsApp(e.checked)} />
                  <label>WhatsApp Notification</label>
                </Col>

                <Col xs={3}>
                  <Checkbox checked={flagPush} onChange={(e) => setflagPush(e.checked)} />
                  <label>Push Notification</label>
                </Col>

                {flagPush && <Col xs={12}>
                  <label>Push Notification Text *</label>
                  <InputTextarea style={{width: '100%'}} value={notificationText} onChange={(e) => setNotificationText(e.target.value)} />
                </Col>}

                {flagText && <Col xs={12}>
                  <label>Text Notification *</label>
                  <InputTextarea style={{width: '100%'}} value={TextNotification} onChange={(e) => setTextNotification(e.target.value)} />
                </Col>}

                {flagWhatsApp && <Col xs={12}>
                  <label>WhatsApp Notification *</label>
                  <InputTextarea style={{width: '100%'}} value={WhatsAppNotification} onChange={(e) => setWhatsAppNotification(e.target.value)} />
                </Col>}

                <Col xs={3}>
                  <Checkbox checked={isPublish} onChange={onChagePublish} />
                  <label>Publish</label>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Dialog>

      {/* delete Pop-up */}
      <Dialog
        visible={openPopup}
        onHide={handleCloseDelete}
        header={<div style={{ textAlign: 'center' }}>You want to delete the record ?</div>}
      >
        <Button onClick={handleCloseDelete}>No</Button>
        <Button onClick={handleDelete} autoFocus>Yes</Button>
      </Dialog>
    </div>
  );
};

export default NotificationsList;