import React, { useState, useEffect } from 'react';

import { Form, Field } from 'react-final-form';

import { Container, Row, Col, Button } from 'reactstrap';

import axios from 'axios';

import Add from '../icons/add.svg';
import Edit from '../icons/edit.svg';
import View from '../icons/view.svg';
import Delete from '../icons/delete.svg';

import { DataTable } from 'primereact/datatable';
import { Paginator } from 'primereact/paginator';
import { Column } from 'primereact/column';
import { classNames } from 'primereact/utils';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';

import { LOOKUP_CODES_LIST, EDIT_LOOKUP_CODES, LOOKUP_TYPES_DROPDOWN, ADD_LOOKUP_CODES } from '../../config/config.js';

let initialValue = {
  name: '', description: '',
  display: '', lookupType: '',
};

const LookupCodes = () => {
  const [tableData, setTableData] = useState([]);
  const [open, setOpen] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [popup, setPopup] = useState(false);
  const [filter, setFilter] = useState('');
  const [ename, setEName] = useState('');
  const [eid, setEId] = useState('');
  const [name, setName] = useState('');
  const [typeDrop, setTDrop] = useState([]);
  const [load, setLoad] = useState(false);

  const [totalRecord, setTRecords] = useState(0);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [page, setPage] = useState(0);

  let mainId = JSON.parse(sessionStorage.getItem('id')) ? JSON.parse(sessionStorage.getItem('id')) : 1;

  const handleView = (row) => {
    setOpen(true); setEName(row.LOOKUP_NAME);
    console.log(row); setName(row.LOOKUP_TYPE_NAME);

    initialValue = {
      name: row.LOOKUP_NAME, display: row.LOOKUP_DISPLAY_NAME,
      description: row.DESCRIPTION,lookupType: row.LOOKUP_TYPE_NAME,
    };
  };

  const handleClose = () => {
    setEName(''); setEId('');
    setOpen(false); setPopup(false);

    initialValue = {
      name: '', display: '',
      description: '', lookupType: ''
    };
  };
  
  const handleEdit = (row) => {
    setPopup(true); setEId(row.ID); setEName(row.LOOKUP_DISPLAY_NAME);
    setName(row.LOOKUP_TYPE_NAME);

    initialValue = {
      name: row.LOOKUP_NAME, display: row.LOOKUP_DISPLAY_NAME,
      description: row.DESCRIPTION, lookupType: row.LOOKUP_TYPE_ID,
    };
  };

  const loadData = (page, filter, row) => {
    setLoad(true);

    axios({
      method: 'get',
      url: LOOKUP_CODES_LIST+'&limit='+row+'&skip='+(page+1)+(filter!=='' && '&search='+filter),
    }).then((res) => {
      setTableData(res.data.rows); setLoad(false);
      setTRecords(res.data.total_count);
    }).catch( (err) => {
      console.log(err)
    });

    axios({
      method: 'get',
      url: LOOKUP_TYPES_DROPDOWN
    }).then((res) => {
      setTDrop(res.data.rows);
    }).catch((err) => {
      console.log(err);
    })
  };

  const handleDelete = () => {
    axios({
      method: 'delete',
      url: EDIT_LOOKUP_CODES + eid,
      'Content-Type': 'multipart/form-data',
      headers: {token: sessionStorage.getItem('token')}
    }).then((res) => {
      loadData(page,filter); setEId('');
    });
    setOpenPopup(false);
  };

  useEffect(() => {
    loadData(page, filter, rows);
  }, [filter]);
      
  const tableHeader=()=>{
    return <div className='DataTable'>
      <div className='m-2'>
        <h3>Lookup Codes List</h3>
      </div>

      <div className='table-header-action'>
        <InputText className='m-2' value={filter} onChange={(e)=>setFilter(e.target.value)} placeholder='Search...' />
        <Button variant="contained" className='table-button m-2' onClick={() => setPopup(true)}>
          <img src={Add} style={{ width: '25px', height: '20px', marginRight:0.5 }}/> Add
        </Button>
      </div>
    </div>
  };
      
  const actionHeader=(rowData)=>{
    return <div style={{display: 'flex', flexDirection:'row'}}>
      <img
        src={View}
        alt='View Image'
        onClick={() => handleView(rowData)}
        style={{marginRight: '15%', width: '25px', height: '20px'}}
      />
    
      <img
        src={Edit}
        alt='Edit Image'
        onClick={() => handleEdit(rowData)}
        style={{marginRight: '15%', width: '25px', height: '20px'}}
      />
    
      <img
        src={Delete}
        alt='Delete Image'
        style={{width: '25px', height: '20px'}}
        onClick={() => {setEId(rowData.ID); setOpenPopup(true)}}
        
      />
    </div> 
  };

  const handleChange = (value) => {
    const data = typeDrop.filter((option) => (option.ID == value));
    setName(data[0].LOOKUP_TYPE);
  };

  const addLookupCode = (obj, form) => {
    axios({
      method: 'post',
      url: ADD_LOOKUP_CODES,
      data: obj, 
    }).then((res) => {
      setPopup(false); setEName(''); 
      loadData(page, filter, rows); form.restart();
    }).catch((err) => {
      console.log(err);
    });
  };

  const editLookCode = (obj, form) => {
    axios({
      method: 'put',
      url: EDIT_LOOKUP_CODES + eid,
      data: obj, 
    }).then((res) => {
      setPopup(false); setEName(''); 
      loadData(page, filter, rows); setEId(''); form.restart();
    }).catch((err) => {
      console.log(err);
    });
  };

  const handleOnSubmit = (data, form) => {
    let obj = {
      'LOOKUP_NAME': data.name,
      'LOOKUP_DISPLAY_NAME': data.display,
      'DESCRIPTION': data.description,
      'LOOKUP_TYPE_ID': data.lookupType,
      'LOOKUP_TYPE_NAME': name,
      'UPDATED_BY': mainId,
    };

    if(ename===''){
      obj.CREATED_BY = mainId;

      addLookupCode(obj, form);
    }else{
      editLookCode(obj, form);
    };
  };

  const validate = (data) => {
    let errors = {};

    if (!data.name) {
      errors.lookupName = 'Lookup Code is required.';
    }

    if (!data.lookupType) {
      errors.lookupType = 'Lookup Type is required.';
    }

    if (!data.description) {
      errors.description = 'Description is required.';
    }

    if (!data.display) {
      errors.display = 'Display Name is required.';
    }

    return errors;
  };

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);

  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  const pageTemplate = {
    layout: 'RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        { label: 10, value: 10 },
        { label: 25, value: 25 },
        { label: 50, value: 50 },
        { label: 100, value: 100 },
      ];

      return (
        <React.Fragment>
          <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
        </React.Fragment>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '150px', textAlign: 'center' }}>
          {options.first} - {options.last} of {options.totalRecords}
        </span>
      )
    }
  };

  const onPage = (e) => {
    setFirst(e.first); setPage(e.page); 
    setRows(e.rows); loadData(e.page, filter, e.rows);
  };

  return(
    <div className='pt-4 m-3'>  

      {/* LookUp Code Table */}
      <div style={{ width: "100%" }} className='datatable'>
        <DataTable 
          value={tableData} header={tableHeader} loading={load}
          removableSort scrollable scrollHeight="64vh"
        >
          <Column header='Lookup Type' field='LOOKUP_TYPE_NAME' className=' text-truncate' sortable  />
          <Column header='Lookup Name' field='LOOKUP_NAME' className=' text-truncate' sortable  />
          <Column header='Description' field='DESCRIPTION' className=' text-truncate' sortable  />
          <Column header='Actions' body={actionHeader} />
        </DataTable>

        <Paginator rows={rows} first={first} totalRecords={totalRecord} onPageChange={onPage} template={pageTemplate} />
      </div>   

      {/* delete lookup code pop-up */}
      <Dialog
        visible={openPopup}
        onHide={() => { setEId(''); setOpenPopup(false) }}
        header={<div> <h5>You want to delete the record ?</h5></div>}
      >
        <div style={{textAlign: 'center'}}>
          <Button onClick={() => { setEId(''); setOpenPopup(false) }} style={{marginRight: '1%'}}>No</Button>
          <Button onClick={handleDelete} style={{marginLeft: '1%'}}>Yes</Button>
        </div>
      </Dialog> 

      {/* add / edit lookup code pop-up */}
      <Dialog
        visible={popup} onHide={handleClose}
        header={<div><h5>{eid === '' ? 'Add Lookup Code' : `Edit Lookup Code - ${ename}`}</h5></div>}
      >
        <Container style={{marginTop: '2%'}}>
          <Form
            onSubmit={handleOnSubmit} 
            initialValues={initialValue}
            validate={validate}
            render={({ handleSubmit }) => 
            <form onSubmit={handleSubmit}>
              <Row>
                <Col xs={4}>
                  <Field name='lookupType' render={({ input, meta }) => (
                    <>
                      <label className={classNames({ 'p-error': isFormFieldValid(meta) })}>Lookup Type *</label>
                      <Dropdown 
                        style={{width: '100%'}} {...input} 
                        onChange={(e) => {
                          input.onChange(e.value); handleChange(e.value);
                        }}
                        options={typeDrop} optionValue='ID'
                        optionLabel="LOOKUP_TYPE_DISPLAY_NAME" 
                      />
                      {getFormErrorMessage(meta)}
                    </>
                  )} />
                </Col>

                <Col xs={4}>
                  <Field name='name' render={({ input, meta }) => (
                    <>
                      <label className={classNames({ 'p-error': isFormFieldValid(meta) })}>lookup Code *</label>
                      <InputText {...input} style={{width: '100%'}} />
                      {getFormErrorMessage(meta)}
                    </>
                  )} />
                </Col>

                <Col xs={4}>
                  <Field name='display' render={({ input, meta }) => (
                    <>
                      <label className={classNames({ 'p-error': isFormFieldValid(meta) })}>Display Name *</label>
                      <InputText {...input} style={{ width: '100%' }} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} />
                      {getFormErrorMessage(meta)}
                    </>
                  )} />
                </Col>

                <Col xs={12}>
                  <Field name='description' render={({ input, meta }) => (
                    <>   
                      <label className={classNames({ 'p-error': isFormFieldValid(meta) })} style={{width: '100%'}}>Description *</label>
                      <InputTextarea {...input} autoResize  style={{width: '100%'}}className={classNames({ 'p-invalid': isFormFieldValid(meta) })} />
                      {getFormErrorMessage(meta)}
                    </>
                  )} />
                </Col>

                <Col xs={12} style={{ textAlign: 'center' }}>
                  <Button className='contained_primary' onClick={handleSubmit}>Submit</Button>
                </Col>   
              </Row>
            </form>
          } />
        </Container>
      </Dialog>

      {/* view lookup code pop-up */}
      <Dialog 
        visible={open} onHide={handleClose}
        header={<div><h5>View Lookup - {ename}</h5></div>}
      >
        <Container>
          <Row>
            <Col xs={4} className='sub_heading_1'>Lookup Type</Col>
            <Col xs={1}><b>:</b></Col>
            <Col xs={6}>{initialValue.lookupType}</Col>

            <Col xs={4} className='sub_heading_1'>Lookup Code</Col>
            <Col xs={1}><b>:</b></Col>
            <Col xs={6}>{initialValue.name}</Col>

            <Col xs={4} className='sub_heading_1'>Display Name</Col>
            <Col xs={1}><b>:</b></Col>
            <Col xs={6}>{initialValue.display}</Col>

            <Col xs={4} className='sub_heading_1'>Description</Col>
            <Col xs={1}><b>:</b></Col>
            <Col xs={6}>{initialValue.description}</Col>
          </Row>
        </Container>
      </Dialog>
    </div>
  );
};

export default LookupCodes;