import React, { useState, useEffect } from 'react';

import axios from 'axios';

import { Form, Field } from 'react-final-form';

import { Container, Row, Col, Button } from 'reactstrap';

import Add from '../icons/add.svg';
import Edit from '../icons/edit.svg';
import View from '../icons/view.svg';
import Delete from '../icons/delete.svg';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { classNames } from 'primereact/utils';

import { QUESTIONS_LIST, EDIT_FAQS, FAQS } from '../../config/config.js';

let initialValue = {
  question: '', answer: ''
};

const Faqs = () => {
  const [tableData, setTableData] = useState([]);
  const [open, setOpen] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [popup, setPopup] = useState(false);
  const [newId, setNewId] = useState('');
  const [filter, setFilter] = useState('');
  const [load, setLoad] = useState(false);

  const [totalRecord, setTRecords] = useState(0);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [page, setPage] = useState(0);

  let mainId = sessionStorage.getItem('id');

  const handleEdit = (row) => {
    setPopup(true); setNewId(row.ID);

    initialValue = {
      question: row.QUESTION,
      answer: row.ANSWER
    };
  };

  const handleView = (row) => {
    setOpen(true); initialValue = {
      question: row.QUESTION,
      answer: row.ANSWER
    };
  };

  const handleOpen = () => {
    setPopup(true); initialValue = {
      question: '', answer: ''
    };
  };

  const handleClickOpen = (row) => {
    setNewId(row.ID); setOpenPopup(true);
  };

  const handleCloseDelete = () => {
    setOpenPopup(false); setNewId('');
  };

  const handleDelete = () => {
    axios({
      method: 'delete',
      url: EDIT_FAQS + newId,
      'Content-Type': 'multipart/form-data',

      headers: { token: sessionStorage.getItem('token') }
    }).then((res) => {
      setOpenPopup(false);
      getListing(page, filter, rows);
    });
  };

  useEffect(() => {
    getListing(page, filter, rows);
  }, []);

  const getListing = (page, filter, row) => {
    setLoad(true);

    axios({
      method: 'get',
      url: QUESTIONS_LIST+'&limit='+row+'&skip='+(page+1)+(filter!=='' && '&search='+filter),
    }).then((res) => {
      if (res.data) {
        setTableData(res.data.rows); setLoad(false);
        setTRecords(res.data.total_count);
      } else {
        setLoad(false); setTRecords(0);
      }
    }).catch((err) => {
      console.log(err);
    });
  };

  const tableHeader = () => {
    return <div className='DataTable'>
      <div className='m-2'>
        <h3>Question & Answers List</h3>
      </div>
      
      <div className='table-header-action'>
        <InputText className='m-2' value={filter} onChange={(e)=>setFilter(e.target.value)} placeholder='Search...' />

        <Button className='m-2 table-button' onClick={handleOpen}>
          <img src={Add} style={{ width: '25px', height: '20px' }}/> Add
        </Button>
      </div>
    </div>
  };

  const actionHeader = (row) => {
    return <div>
      <img
        src={View}
        alt='Edit Image'
        onClick={() => handleView(row)}
        style={{width: '25px', height: '20px'}}
      />

      <img
        src={Edit}
        alt='Edit Image'
        onClick={() => handleEdit(row)}
        style={{marginLeft: '15px', width: '25px', height: '20px'}}
      />

      <img
        src={Delete}
        alt='Delete'
        onClick={() => handleClickOpen(row)}
        style={{marginLeft: '15px', width: '25px', height: '20px'}}
      />
    </div>
  };

  const addFaq = (obj, form) => {
    axios({
      method: 'post',
      url: FAQS,
      data: obj
    }).then((res) => {
      getListing(page, filter, rows); setPopup(false); 
      form.restart();
    });
  };

  const editFaq = (obj, form) => {
    axios({
      method: 'put',
      url: EDIT_FAQS + newId,
      data: obj
    }).then((res) => {
      getListing(page, filter, rows); setPopup(false);
      setNewId(''); form.restart();
    });
  };

  const handleOnSubmit = (data, form) => {
    let obj = {
      QUESTION: data.question,
      ANSWER: data.answer,
      UPDATED_BY: mainId,
    };

    if(newId===''){
      obj.CREATED_BY = mainId;

      addFaq(obj, form);
    }else{
      editFaq(obj, form);
    };
  };

  const validate = (data) => {
    let errors = {};

    if (!data.question) {
      errors.question = 'Question is required.';
    }

    if (!data.answer) {
      errors.answer = 'Answer is required.';
    }

    return errors;
  };

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);

  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  const pageTemplate = {
    layout: 'RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        { label: 10, value: 10 },
        { label: 25, value: 25 },
        { label: 50, value: 50 },
        { label: 100, value: 100 },
      ];

      return (
        <React.Fragment>
          <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
        </React.Fragment>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '150px', textAlign: 'center' }}>
          {options.first} - {options.last} of {options.totalRecords}
        </span>
      )
    }
  };

  const onPage = (e) => {
    setFirst(e.first); setPage(e.page); 
    setRows(e.rows); getListing(e.page, filter, e.rows);
  };

  return (
    <div className='pt-4 m-3'>
      <div style={{ width: "100%" }} className='datatable'>
        <DataTable 
          value={tableData} removableSort loading={load}
          header={tableHeader} globalFilter={filter}
        >
          <Column header='Questions' field='QUESTION' className=' text-truncate' sortable />
          <Column header='Answers' field='ANSWER' className=' text-truncate' sortable />
          <Column header='Actions' body={actionHeader} />
        </DataTable>

        <Paginator rows={rows} first={first} totalRecords={totalRecord} onPageChange={onPage} template={pageTemplate} />
      </div>

      {/* Add / Edit FAQ's Pop-up */}
      <Dialog 
        visible={popup} onHide={() => {
          setPopup(false); setNewId(''); initialValue = {
            question: '', answer: ''
          }
        }}
        style={{width: '50vw', height: '63vh'}}
        header={<div>{newId==='' ? <h3>Add FAQ</h3> : <h3>Edit FAQ</h3>}</div>}
      >
        <Container>
          <Form 
            onSubmit={handleOnSubmit} 
            initialValues={initialValue}
            validate={validate}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Row>
                  <Col xs={12}>
                    <Field name='question' render={({ input, meta }) => (
                      <>
                        <label className={classNames({ 'p-error': isFormFieldValid(meta) })}>Question *</label>
                        <InputTextarea className={classNames({ 'p-error': isFormFieldValid(meta) })} {...input} style={{width:'100%'}} autoResize rows={3} cols={10} />
                        {getFormErrorMessage(meta)}
                      </>
                    )} />
                  </Col>

                  <Col xs={12}>
                    <Field name='answer' render={({ input, meta }) => (
                      <>
                        <label className={classNames({ 'p-error': isFormFieldValid(meta) })}>Answer *</label>
                        <InputTextarea className={classNames({ 'p-error': isFormFieldValid(meta) })} {...input} style={{width:'100%'}} autoResize rows={3} cols={10} />
                        {getFormErrorMessage(meta)}
                      </>
                    )} />
                  </Col>

                  <Col xs={12}>
                    <div className='table-header-action'>
                      <Button className='table-button m-2' onClick={handleSubmit}>Submit</Button>
                    </div>
                  </Col>
                </Row>
              </form>
            )}
          />
          
        </Container>
      </Dialog>

      {/* View FAQ's Pop-up */}
      <Dialog
        visible={open} onHide={() => setOpen(false)}
        header={<div style={{textAlign: 'center'}}>View FAQ</div>}
      >
        <Container>
          <Row>
            <Col xs={2} className='sub_heading_1'>Question</Col>
            <Col xs={1}>:</Col>
            <Col xs={8}>{initialValue.question}</Col>

            <Col xs={2} style={{ marginTop: '7.5px' }} className='sub_heading_1'>Answer</Col>
            <Col xs={1} style={{ marginTop: '7.5px' }}>:</Col>
            <Col xs={8} style={{ marginTop: '7.5px' }}>{initialValue.answer}</Col>
          </Row>
        </Container>
      </Dialog>

      {/* Delete FAQ's Pop-up */}
      <Dialog
        visible={openPopup} onHide={handleCloseDelete}
        header={<div className='text-center'>You want to delete the record ?</div>}
      >
        <div className='text-center'>
          <Button onClick={handleCloseDelete} style={{marginRight: '5%'}}>No</Button>
          <Button onClick={handleDelete} autoFocus>
            Yes
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default Faqs;