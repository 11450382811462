import React, { useState, useEffect } from 'react';

import axios from 'axios';

import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Paginator } from 'primereact/paginator';
import { MultiSelect } from 'primereact/multiselect';

import  { Button } from "reactstrap";

import Filter from '../Sidebar/icons/filter.svg';
import Download from '../Sidebar/icons/excelDownload.svg';

import { REPORTS_LIST, Products_LIST } from '../../config/config.js';
import './Reports.css';

const Reports = () => {
    const [tableData, setTableData] = useState([]);
    const [load, setLoad] = useState(false);
    const [selectedproduct, setSelectedproduct] = useState(null);
    const [startDate, setstartdate] = useState('');
    const [endDate, setenddate] = useState('');
    const [products, setproducts]  = useState(null);
    const [productID, setproductID]  = useState([]);
    const [cancel, setCancel] = useState(false);

    const [loadExl, setLExl] = useState(false);

    const [totalRecord, setTRecords] = useState(0);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [page, setPage] = useState(0);

    const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

    const onProductChange = (e) => {
        setSelectedproduct(e.value); let pid = [];

        for(let i=0; i < e.value.length; i++){
            pid.push(e.value[i].ID);
        };

        setproductID(pid); setCancel(false);
    };

    const handleFilter = (page, row) => {
        setLoad(true); setCancel(true); 
        // setPage(0);
        
        // patch method Order list
        axios({
            method: 'patch',
            url: REPORTS_LIST,
            data: { 
                productIds: productID, 
                startDate: startDate, 
                endDate: endDate,
                type: "list",
                limit: row,
                skip: page + 1,
            }
        }).then((res) => {
            if (res.data.data) {
                setTRecords(res.data.data.total_count);
                setLoad(false); setTableData(res.data.data.rows); 
            } else {
                setLoad(false); setTRecords(0);
            }
        }).catch((err) => {
            console.log(err);
        });
    };

    const getOrderData = (page, row) => {
        setLoad(true);
        
        // patch method Order list
        axios({
            method: 'patch',
            url: REPORTS_LIST,
            data: {
                type: "list",
                limit: row,
                skip: page + 1,
            }
        }).then((res) => {
            if (res.data.data) {
                setTRecords(res.data.data.total_count);
                setLoad(false); setTableData(res.data.data.rows); 
            } else {
                setLoad(false); setTRecords(0);
            }
        }).catch((err) => {
            console.log(err);
        });
    };

    const getProductData = () => {
        axios({
            method: 'get',
            url: Products_LIST
        }).then((res) => {
            setproducts(res.data);
        }).catch((err) => {
            console.log(err);
        }); 
    };

    useEffect(() => {
        if (cancel) {
            handleFilter(page, rows);
        } else {
            getOrderData(page, rows);
        };
        
        getProductData();
    }, []);

    const exportExcel = () => {
        setLExl(true); setLoad(true);

        axios({
            method: 'patch',
            url: REPORTS_LIST,
            data: {
                type: "list",
                limit: totalRecord,
                skip: null,
            }
        }).then((res) => { 
            import('xlsx').then(xlsx => {
                const worksheet = xlsx.utils.json_to_sheet(res.data.data.rows);
                const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
                const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
                saveAsExcelFile(excelBuffer, 'Reports_'); setLoad(false); setLExl(false);
            });
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            };
        });
    };

    const orderStatus = (rowData) => {
        return <div className='status_button'>
          {rowData.STATUS === "ORDERED" && <Button value="ORDER PLACED" className="order_placed primary" variant="outlined"> ORDER PLACED</Button>}
          {rowData.STATUS === "DELIVERED" && <Button variant="outlined" value="DELIVERED" className="delivered success" >DELIVERED </Button>}
          {rowData.STATUS === "DISPATCHED" && <Button variant="outlined" value="DELIVERED" className="dispatched warning"> DISPATCHED </Button>}
          {rowData.STATUS === "CANCELLED" && <Button variant="outlined" value="DELIVERED" className="cancelled danger" > CANCELLED</Button>}
          {rowData.STATUS === "PREBOOK" && <Button variant="outlined" value="DELIVERED" className="prebook secondary" > PREBOOK</Button>}
        </div>
    };
    
    const paymentStatus = (rowData) => {
        return <div className='status_button'>
            {/* {rowData.STATUS === "SUCCESS" && <Button value="ORDER PLACED" className="order_placed primary" variant="outlined"> ORDER PLACED</Button>} */}
            {rowData.PAYMENT_STATUS === "SUCCESS" && <Button variant="contained" className="contained_success w130"> SUCCESS</Button>}
            {rowData.PAYMENT_STATUS === "PENDING" && <Button variant="contained" className="contained_warning w130"> PENDING </Button>}
            {/* {rowData.STATUS === "CANCELLED" && <Button variant="outlined" value="DELIVERED" className="cancelled danger" > DELIVERED</Button>} */}
        </div>
    };

    const paymentMode = (rowData) => {
        return <div className='status_button'>
          {rowData.PAYMENT_MODE === 'COD' && <Button variant="outlined" label="Cash On Delivery" className="cod primary" > Cash On Delivery</Button>}
          {rowData.PAYMENT_MODE === 'ATUM_WALLET' && <Button variant="outlined" label='ATUM Wallet' className="wallet success"> ATUM Wallet</Button>}
          {rowData.PAYMENT_MODE === 'ONLINE' && <Button variant="outlined" label='Payment Online' className="pay-online success"> Payment Online</Button>}
        </div>
    };

    const productTemplate = (option) => {
        return (
            <div className="country-item">
                <div>{option.PRODUCT_NAME}</div>
            </div>
        );
    };

    const selectProductTemplate = (option) => {
        if (option) {
            return (
                <div className="country-item country-item-value">
                    <div>{option.PRODUCT_NAME}</div>
                </div>
            )
        };

        return "Select Products";
    };

    const footerTemplate = () => {
        const selectedItems = selectedproduct;
        const length = selectedItems ? selectedItems.length : 0;

        return (
            <div className="py-2 px-3">
                <b>{length}</b> item{length > 1 ? 's' : ''} selected.
            </div>
        );
    };

    const handleCancel = () => {
        setstartdate(''); setenddate(''); 
        setproductID([]); setCancel(false);
        setSelectedproduct(null); getOrderData();
    };

    const tableHeader = () => {
        return <div className='DataTable report_order_header'>
            <div className='m-2'>
                <h3>Reports </h3>
            </div>

            <div>
                <div className='DataTable table-header-action custom_dropdown'>
                    <div>
                        <MultiSelect 
                            value={selectedproduct} options={products} onChange={onProductChange} className='setle'
                            style={{marginTop: '4.5%'}} optionLabel="PRODUCT_NAME" placeholder="Select Products" filter 
                            itemTemplate={productTemplate} maxSelectedLabels={1} selectedItemTemplate={selectProductTemplate} 
                            panelFooterTemplate={footerTemplate} display="chip"
                        />
                    </div>

                    <div>
                        <Calendar
                            className='m-2'
                            placeholder='Start Date'
                            dateFormat="dd-mm-yy" showIcon
                            value={startDate} maxDate={new Date()}
                            onChange={(e) => {setstartdate(e.target.value); setCancel(false)}}
                            style={{ height: '35px', width: '150px' }}
                        />
                    
                        <Calendar 
                            className='m-2'
                            placeholder='End Date'
                            dateFormat="dd-mm-yy" showIcon
                            value={endDate} maxDate={new Date()}
                            onChange={(e) => {setenddate(e.target.value); setCancel(false)}}
                            style={{ height: '35px', width: '150px' }}
                        />
                    </div>

                    <div>
                        {cancel===false ? <Button className='m-2 table-button' onClick={handleFilter}>
                            <img src={Filter} style={{ width: '25px', height: '20px', marginRight: 0.5 }} /> Filter
                        </Button> : <Button className='m-2 table-button' onClick={handleCancel}>Cancel
                        </Button>}

                        <Button className='m-2 table-button' onClick={exportExcel} disabled={loadExl}>
                            <img src={Download} style={{ width: '25px', height: '20px', marginRight: 0.5 }} /> Excel
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    };
      
    const dateBody = (row) => {
        let ds = new Date(row.ORDERED_DATE);
        return ds.getDate() + '/' + month[ds.getMonth()] + '/' + ds.getFullYear();
    };

    const pageTemplate = {
        layout: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport',
        'CurrentPageReport': (options) => {
          return (
            <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '150px', textAlign: 'center' }}>
              {options.first} - {options.last} of {options.totalRecords}
            </span>
          )
        }
    };
    
    const onPage = (e) => {
        setFirst(e.first); setRows(e.rows); setPage(e.page); 

        if (cancel) {
            handleFilter(e.page);
        } else {
            getOrderData(e.page, e.rows);
        };
    };

    return (
        <div className='pt-4 m-3'>
            <div sx={{ width: "100%" }} className='datatable'>
                <DataTable 
                    value={tableData} removableSort header={tableHeader}
                    scrollable scrollHeight='64vh' size='medium' loading={load}
                >
                    <Column header='Order ID' field='ORDER_TRACKER_ID' className='text-truncate w-80' sortable />
                    <Column header='Status' field='STATUS' className='text-truncate w-30' body={orderStatus} sortable />
                    <Column header='Email' field='EMAIL' className='text-truncate w-80' sortable />
                    <Column header='Ordered Date' dataType="date" field='ORDERED_DATE w-30' className='text-truncate' body={dateBody} sortable />
                    {/* <Column header='ORDER_TYPE' field='ORDER_TYPE' className='text-truncate' sortable /> */}
                    <Column header='Payment Mode' field='PAYMENT_MODE' className='text-truncate w-30' body={paymentMode} sortable />
                    <Column header='Payment Status' field='PAYMENT_STATUS' className='text-truncate w-30' body={paymentStatus} sortable />
                    <Column header='Product Name' field='PRODUCT_NAME' className='text-truncate w-80' sortable />
                    <Column header='Delivery Address' field='DELIVERY_ADDRESS' className='text-truncate w-80' sortable />
                </DataTable>

                <Paginator rows={rows} first={first} totalRecords={totalRecord} onPageChange={onPage} template={pageTemplate} />
            </div>
        </div>
    );
};

export default Reports;