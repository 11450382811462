import React, { useState, useEffect } from 'react';

import { ListBox } from 'primereact/listbox';

import Home from './icons/home.svg';
import Coupons from './icons/coupon.svg';
import Blog from './icons/blog.svg';
import Shop from './icons/shop.svg';
import Bike from './icons/bike.svg';
import Order from './icons/order.svg';
import Admin from './icons/admins.svg';
import User from './icons/account.svg';
import Store from './icons/store.svg';
import Category from './icons/categories.svg';
import Product from './icons/products.svg';
import Report from './icons/reports.svg';
import Delivery from './icons/delivery.svg';
import Manager from './icons/storeManager.svg';
import Banner from './icons/banners.svg';
import Role from './icons/userRoles.svg';
import Lookup from './icons/lookup.svg';
import Faq from './icons/faqs.svg';
import Logger from '../icons/loggers.svg';
import './sidebar.css';

let drawerWidth = 230;

export default function Menubar(props) {
  const [menuItems, setMenuItems] = useState([]);

  const [selectMenu, setSMenu] = useState(null);

  props.drawerOpen===true ? drawerWidth = 17 : drawerWidth = 0;
 
  const getuserRoles = () => {
    let menuItemsData = [];

    if(sessionStorage.getItem('roleJson')){
      const roleJson = JSON.parse(sessionStorage.getItem('roleJson'));
      const menudData = roleJson.menu ? roleJson.menu : {};
      
      menuItemsData.push(
        { title: 'Home', icon: 'home', url: '/home', show: true },
        { title: 'Logs', icon: 'logger', url: '/logs', show: true },
        { title: 'Coupon', icon: 'coupons', url: '/coupons', show: true },
        { title: 'Blog', icon: 'blog', url: '/blog', show: true },
        // { title: 'Store Order', icon: 'shop', url: '/storeorder', show: true },
        { title: 'Bike Referral', icon: 'bikereferral', url: '/bikereferral', show: true },
      );
  
      const listItems = {
        "orders":{ title: 'Orders', icon: 'order', url: '/orders', show: true },
        "adminusers":{ title: 'Admin Users', icon: 'admins', url: '/users', show: true },
        "endusers":{ title: 'End Users', icon: 'contact', url: '/endusers', show: true },
        "categories":{ title: 'Categories', icon: 'category', url: '/categories', show: true },
        "products":{ title: 'Products', icon: 'product', url: '/products', show: true },
        "orderreports":{ title: 'Reports', icon: 'reports', url: '/reports', show: true },
        "stores":{ title: 'Stores', icon: 'store', url: '/stores', show: true },
        "deliveryaddress":{ title: 'Delivery Address', icon: 'deliveryaddress', url: '/deliveryaddress', show: true },
        "storemanagers":{ title: 'Store Managers', icon: 'manager', url: '/storemanagers', show: true },
        "banners":{ title: 'Banners', icon: 'banner', url: '/bannerslist', show: true },
        "userroles":{ title: 'User Roles', icon: 'roles', url: '/roleslist', show: true },
        "lookuptypes":{ title: 'Lookup Types', icon: 'types', url: '/lookuptypes', show: true },
        "lookupcodes":{ title: 'Lookup Codes', icon: 'codes', url: '/lookupcodes', show: true },
        "faqs":{ title: 'Faqs', icon: 'faq', url: '/faqs', show: true },
      };

      const menusNames = Object.keys(listItems);

      for(let i=0; i<=menusNames.length; i++){
        if(menudData[menusNames[i]] && menudData[menusNames[i]].enabled){
          
          if(listItems[[menusNames[i]]]){
            menuItemsData.push(listItems[menusNames[i]]);
          }
        }
      };

      for(let j=0; j<menuItemsData.length; j++){
        if(menuItemsData[j].url === props.location.pathname){
          setSMenu(menuItemsData[j]);
        }
      }

      setMenuItems(menuItemsData);
    };
  };

  const getIcon = (name) => {
    switch (name) {
      case 'home':
        return <img src={Home} className='icon' />;
      case 'coupons':
        return <img src={Coupons} className='icon' />;
      case 'blog':
        return <img src={Blog} className='icon' />;
      case 'shop':
        return <img src={Shop} className='icon' />;
      case 'bikereferral':
        return <img src={Bike} className='icon' />;
      case 'order':
        return <img src={Order} className='icon' />;
      case 'admins':
        return <img src={Admin} className='icon' />;
      case 'contact':
        return <img src={User} className='icon' />;
      case 'category':
        return <img src={Category} className='icon' />;
      case 'product':
        return <img src={Product} className='icon' />;
      case 'reports':
        return <img src={Report} className='icon' />;
      case 'store': 
        return <img src={Store} className='icon' />;
      case 'deliveryaddress':
        return <img src={Delivery} className='icon' />;
      case 'manager':
        return <img src={Manager} className='icon' />;
      case 'banner':
        return <img src={Banner} className='icon' />;
      case 'roles':
        return <img src={Role} className='icon' />;
      case 'types':
        return <img src={Lookup} className='icon' />;
      case 'codes':
        return <img src={Lookup} className='icon' />;
      case 'faq':
        return <img src={Faq} className='icon' />;
      case 'logger':
        return <img src={Logger} className='icon' />;
    };
  };

  const setActions = (e) => {
    e.preventDefault();

    if(e.value){
      setSMenu(e.value);
      props.history.push(e.value.url);
    }
  };

  const countryTemplate = (option) => {
    return (
      <div>{getIcon(option.icon)}&nbsp;&nbsp;{option.title}</div>
    );
  };

  useEffect(() => {
    getuserRoles();

    if(props.drawerOpen){
      drawerWidth = 230;
    } else {
      drawerWidth = 0;
    }
  }, [props.location.pathname]);

  return (
    <div 
      id="style-scroll" className='drawerPaper'
      style={{width: `${drawerWidth}vw`, height: '100%'}}
    >
      <ListBox 
        value={selectMenu} options={menuItems}
        onChange={setActions} itemTemplate={countryTemplate}
        optionLabel="title" style={{ width: '100%' }} listStyle={{ height: '91.5vh' }}
      />
    </div>
  );
};