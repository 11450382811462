import React, { useState, useEffect } from 'react';

import axios from 'axios';

import { ROLES_LIST, ADD_USER_ROLES, USER_ROLES_DROPDOWN, EDIT_USER_ROLES } from '../../config/config.js';

import Add from '../icons/add.svg';
import Edit from '../icons/edit.svg';
import View from '../icons/view.svg';
import Delete from '../icons/delete.svg';

import { Button, Container, Row, Col } from 'reactstrap';

import { ScrollPanel } from 'primereact/scrollpanel';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Tree } from 'primereact/tree';
// import { Chip } from 'primereact/chip';

const RolesList = () => {
  const [tableData, setTableData] = useState([]);
  const [rolesDropDown, setRolesDropDown] = useState([]);
  const [addOpen, setAOpn] = useState(false);
  const [editOpen, setEOpn] = useState(false);
  const [view, setView] = useState(false);
  const [selectedKeys3, setSelectedKeys3] = useState([]);
  const [del, setDel] = useState(false);
  const [des, setDes] = useState('');
  const [menuObj, setMObj] = useState(null);
  const [roleId, setRoleId] = useState('');
  const [rName, setRName] = useState('');
  const [activeError, setActiveError] = useState(false);
  const [editRole, setERole] = useState('');
  const [editID, setEId] = useState('');
  const [filter, setFilter] = useState('');

  const [list, setList] = useState([]);
  const [opts, setOpts] = useState([]);
  const [selObj, setSObj] = useState([]);

  const objs = [
    {
      "key": "0",
      "label": "Dashboard",
      "data": "Dashboard Folder",
      "children": [{
        "key": "0-0",
        "label": "Create",
        "data": "Create Folder"},
        {"key": "0-1",
        "label": "Edit",
        "data": "Edit Folder"},
        {"key": "0-2",
        "label": "Delete",
        "data": "Delete Folder"},
        {"key": "0-3",
        "label": "Status",
        "data": "Status Folder",
      }]
    },{
      "key": "1",
      "label": "Orders",
      "data": "Orders Folder",
      "children": [{
        "key": "1-0",
        "label": "Create",
        "data": "Create Folder"},
        {"key": "1-1",
        "label": "Edit",
        "data": "Edit Folder"},
        {"key": "1-2",
        "label": "Delete",
        "data": "Delete Folder"},
       {"key": "1-3",
        "label": "Status",
        "data": "Status Folder",
      }]
    },{
      "key": "2",
      "label": "Admin Users",
      "data": "Admin User Folder",
      "children": [{
        "key": "2-0",
        "label": "Create",
        "data": "Create Folder"},
        {"key": "2-1",
        "label": "Edit",
        "data": "Edit Folder"},
        {"key": "2-2",
        "label": "Delete",
        "data": "Delete Folder"},
        {"key": "2-3",
        "label": "Status",
        "data": "Status Folder",
      }]
    },{
      "key": "3",
      "label": "End Users",
      "data": "End User Folder",
      "children": [{
        "key": "3-0",
        "label": "Create",
        "data": "Create Folder"},
        {"key": "3-1",
        "label": "Edit",
        "data": "Edit Folder"},
        {"key": "3-2",
        "label": "Delete",
        "data": "Delete Folder"},
        {"key": "3-3",
        "label": "Status",
        "data": "Status Folder",
      }]
    },{
      "key": "4",
      "label": "Category",
      "data": "Category Folder",
      "children": [{
        "key": "4-0",
        "label": "Create",
        "data": "Create Folder"},
        {"key": "4-1",
        "label": "Edit",
        "data": "Edit Folder"},
        {"key": "4-2",
        "label": "Delete",
        "data": "Delete Folder"},
        {"key": "4-3",
        "label": "Status",
        "data": "Status Folder",
      }]
    },{
      "key": "5",
      "label": "Product",
      "data": "Product Folder",
      "children": [{
        "key": "5-0",
        "label": "Create",
        "data": "Create Folder"},
        {"key": "5-1",
        "label": "Edit",
        "data": "Edit Folder"},
        {"key": "5-2",
        "label": "Delete",
        "data": "Delete Folder"},
        {"key": "5-3",
        "label": "Status",
        "data": "Status Folder",
      }]
    },{
      "key": "6",
      "label": "Store",
      "data": "Store Folder",
      "children": [{
        "key": "6-0",
        "label": "Create",
        "data": "Create Folder"},
        {"key": "6-1",
        "label": "Edit",
        "data": "Edit Folder"},
        {"key": "6-2",
        "label": "Delete",
        "data": "Delete Folder"},
        {"key": "6-3",
        "label": "Status",
        "data": "Status Folder",
      }]
    },{
      "key": "7",
      "label": "Store Manager",
      "data": "Store Manager Folder",
      "children": [{
        "key": "7-0",
        "label": "Create",
        "data": "Create Folder"},
        {"key": "7-1",
        "label": "Edit",
        "data": "Edit Folder"},
        {"key": "7-2",
        "label": "Delete",
        "data": "Delete Folder"},
        {"key": "7-3",
        "label": "Status",
        "data": "Status Folder",
      }]
    },{
      "key": "8",
      "label": "User Role",
      "data": "User Role Folder",
      "children": [{
        "key": "8-0",
        "label": "Create",
        "data": "Create Folder"},
        {"key": "8-1",
        "label": "Edit",
        "data": "Edit Folder"},
        {"key": "8-2",
        "label": "Delete",
        "data": "Delete Folder"},
        {"key": "8-3",
        "label": "Status",
        "data": "Status Folder",
      }]
    },{
      "key": "9",
      "label": "LookUp Type",
      "data": "Lookup Type Folder",
      "children": [{
        "key": "9-0",
        "label": "Create",
        "data": "Create Folder"},
        {"key": "9-1",
        "label": "Edit",
        "data": "Edit Folder"},
        {"key": "9-2",
        "label": "Delete",
        "data": "Delete Folder"},
        {"key": "9-3",
        "label": "Status",
        "data": "Status Folder",
      }]
    },{
      "key": "10",
      "label": "LookUp Code",
      "data": "Lookup Code Folder",
      "children": [{
        "key": "10-0",
        "label": "Create",
        "data": "Create Folder"},
        {"key": "10-1",
        "label": "Edit",
        "data": "Edit Folder"},
        {"key": "10-2",
        "label": "Delete",
        "data": "Delete Folder"},
        {"key": "10-3",
        "label": "Status",
        "data": "Status Folder",
      }]
    },{
      "key": "11",
      "label": "Notification",
      "data": "Notification Folder",
      "children": [{
        "key": "11-0",
        "label": "Create",
        "data": "Create Folder"},
        {"key": "11-1",
        "label": "Edit",
        "data": "Edit Folder"},
        {"key": "11-2",
        "label": "Delete",
        "data": "Delete Folder"},
        {"key": "11-3",
        "label": "Status",
        "data": "Status Folder",
      }]
    },{
      "key": "12",
      "label": "FAQ's",
      "data": "Faq's Folder",
      "children": [{
        "key": "12-0",
        "label": "Create",
        "data": "Create Folder"},
        {"key": "12-1",
        "label": "Edit",
        "data": "Edit Folder"},
        {"key": "12-2",
        "label": "Delete",
        "data": "Delete Folder"},
        {"key": "12-3",
        "label": "Status",
        "data": "Status Folder",
      }]
    },
    {
      "key": "13",
      "label": "Reports",
      "data": "Reports Folder",
      "children": [{
        "key": "13-0",
        "label": "Create",
        "data": "Create Folder"},
        {"key": "13-1",
        "label": "Edit",
        "data": "Edit Folder"},
        {"key": "13-2",
        "label": "Delete",
        "data": "Delete Folder"},
        {"key": "13-3",
        "label": "Status",
        "data": "Status Folder",
      }]
    }
  ];

  const loadData = () => {
    // roles list
    axios({
      method: 'get',
      url: ROLES_LIST
    }).then((res) => {
      console.log('roles :', res.data); setTableData(res.data.rows);
    }).catch((err) => {
      console.log(err);
    });
  };

  const getAddRoles = () => {
    // add roles list
    axios({
      method: 'get',
      url: USER_ROLES_DROPDOWN
    }).then((res) => {
      if (res.data) {
        console.log('user roles :', res.data); setRolesDropDown(res.data);
      }
    }).catch((err) => {
      console.log(err)
    });
  };
    
  useEffect(() => {
    loadData(); getAddRoles();
  }, []);

  useEffect(() => {
    let olist = JSON.parse(sessionStorage.getItem('roleJson'));
    let obj = Object.keys(olist.menu); setList(obj);
  }, []);

  const addRoles = () => {
    setAOpn(true); setERole('');
  };

  const handleChange = (event) => {
    const data = rolesDropDown.filter((option) => (option.ID === event.target.value));
    setRoleId(event.target.value); setRName(data[0].LOOKUP_DISPLAY_NAME);
  };

  const tableHeader = () => {
    return <div className='DataTable'>
      <div className='m-2'>
        <h3>Roles List</h3></div>
      
      <div className='table-header-action'>
        <InputText className='m-2' value={filter} onChange={(e)=>setFilter(e.target.value)} placeholder='Search...' />
        <Button variant="contained" className='table-button m-2' onClick={addRoles}>
          <img src={Add} style={{ width: '25px', height: '20px', marginRight: 5 }}/>Add
        </Button>
      </div>
    </div>
  };

  let menus = {};

  const addPage = (vals) => {

    for(let i=0; i<vals.length; i++){

      switch(vals[i]){
        case '0':
          menus['dashboard'] = { 'enabled': true };
          break;
        case '1':
          menus['userorders'] = { 'enabled': true };
          break;
        case '2':
          menus['adminusers'] = { 'enabled': true };
          break;
        case '3':
          menus['endusers'] = { 'enabled': true };
          break;
        case '4':
          menus['categories'] = { 'enabled': true };
          break;
        case '5':
          menus['products'] = { 'enabled': true };
          break;
        case '6':
          menus['stores'] = { 'enabled': true };
          break;
        case '7':
          menus['storemanagers'] = { 'enabled': true };
          break;
        case '8':
          menus['userroles'] = { 'enabled': true };
          break;
        case '9':
          menus['lookuptypes'] = { 'enabled': true };
          break;
        case '10':
          menus['lookupcodes'] = { 'enabled': true };
          break;
        case '11':
          menus['notfications'] = { 'enabled': true };
          break;
        case '12':
          menus['faqs'] = { 'enabled': true };
          break;  
          case '13':
          menus['orderreports'] = { 'enabled': true };
          break; 
        case '0-0':
          menus['dashboard']['actions'] = { ...menus['dashboard']['actions'], 'create' : true };
          break;
        case '0-1':
          menus['dashboard']['actions'] = { ...menus['dashboard']['actions'], 'edit' : true };
          break;
        case '0-2':
          menus['dashboard']['actions'] = { ...menus['dashboard']['actions'], 'delete' : true };
          break;
        case '0-3':
          menus['dashboard']['actions'] = { ...menus['dashboard']['actions'], 'status' : true };
          break;
        case '1-0':
          menus['userorders']['actions'] = { ...menus['orders']['actions'], 'create' : true };
          break;
        case '1-1':
          menus['userorders']['actions'] = { ...menus['orders']['actions'], 'edit' : true };
          break;
        case '1-2':
          menus['userorders']['actions'] = { ...menus['orders']['actions'], 'delete' : true };
          break;
        case '1-3':
          menus['userorders']['actions'] = { ...menus['orders']['actions'], 'status' : true };
          break;
        case '2-0':
          menus['adminusers']['actions'] = { ...menus['adminusers']['actions'], 'create' : true };
          break;
        case '2-1':
          menus['adminusers']['actions'] = { ...menus['adminusers']['actions'], 'edit' : true };
          break;
        case '2-2':
          menus['adminusers']['actions'] = { ...menus['adminusers']['actions'], 'delete' : true };
          break;
        case '2-3':
          menus['adminusers']['actions'] = { ...menus['adminusers']['actions'], 'status' : true };
          break;
        case '3-0':
          menus['endusers']['actions'] = { ...menus['endusers']['actions'], 'create' : true };
          break;
        case '3-1':
          menus['endusers']['actions'] = { ...menus['endusers']['actions'], 'edit' : true };
          break;
        case '3-2':
          menus['endusers']['actions'] = { ...menus['endusers']['actions'], 'delete' : true };
          break;
        case '3-3':
          menus['endusers']['actions'] = { ...menus['endusers']['actions'], 'status' : true };
          break;
        case '4-0':
          menus['categories']['actions'] = { ...menus['categories']['actions'], 'create' : true };
          break;
        case '4-1':
          menus['categories']['actions'] = { ...menus['categories']['actions'], 'edit' : true };
          break;
        case '4-2':
          menus['categories']['actions'] = { ...menus['categories']['actions'], 'delete' : true };
          break;
        case '4-3':
          menus['categories']['actions'] = { ...menus['categories']['actions'], 'status' : true };
          break;
        case '5-0':
          menus['products']['actions'] = { ...menus['products']['actions'], 'create' : true };
          break;
        case '5-1':
          menus['products']['actions'] = { ...menus['products']['actions'], 'edit' : true };
          break;
        case '5-2':
          menus['products']['actions'] = { ...menus['products']['actions'], 'delete' : true };
          break;
        case '5-3':
          menus['products']['actions'] = { ...menus['products']['actions'], 'status' : true };
          break;
        case '6-0':
          menus['stores']['actions'] = { ...menus['stores']['actions'], 'create' : true };
          break;
        case '6-1':
          menus['stores']['actions'] = { ...menus['stores']['actions'], 'edit' : true };
          break;
        case '6-2':
          menus['stores']['actions'] = { ...menus['stores']['actions'], 'delete' : true };
          break;
        case '6-3':
          menus['stores']['actions'] = { ...menus['stores']['actions'], 'status' : true };
          break;
        case '7-0':
          menus['storemanagers']['actions'] = { ...menus['storemanagers']['actions'], 'create' : true };
          break;
        case '7-1':
          menus['storemanagers']['actions'] = { ...menus['storemanagers']['actions'], 'edit' : true };
          break;
        case '7-2':
          menus['storemanagers']['actions'] = { ...menus['storemanagers']['actions'], 'delete' : true };
          break;
        case '7-3':
          menus['storemanagers']['actions'] = { ...menus['storemanagers']['actions'], 'status' : true };
          break;
        case '8-0':
          menus['storemanagers']['actions'] = { ...menus['storemanagers']['actions'], 'create' : true };
          break;
        case '8-1':
          menus['userroles']['actions'] = { ...menus['userroles']['actions'], 'edit' : true };
          break;
        case '8-2':
          menus['userroles']['actions'] = { ...menus['userroles']['actions'], 'delete' : true };
          break;
        case '8-3':
          menus['userroles']['actions'] = { ...menus['userroles']['actions'], 'status' : true };
          break;
        case '9-0':
          menus['lookuptypes']['actions'] = { ...menus['lookuptypes']['actions'], 'create' : true };
          break;
        case '9-1':
          menus['lookuptypes']['actions'] = { ...menus['lookuptypes']['actions'], 'edit' : true };
          break;
        case '9-2':
          menus['lookuptypes']['actions'] = { ...menus['lookuptypes']['actions'], 'delete' : true };
          break;
        case '9-3':
          menus['lookuptypes']['actions'] = { ...menus['lookuptypes']['actions'], 'status' : true };
          break;
        case '10-0':
          menus['lookupcodes']['actions'] = { ...menus['lookupcodes']['actions'], 'create' : true };
          break;
        case '10-1':
          menus['lookupcodes']['actions'] = { ...menus['lookupcodes']['actions'], 'edit' : true };
          break;
        case '10-2':
          menus['lookupcodes']['actions'] = { ...menus['lookupcodes']['actions'], 'delete' : true };
          break;
        case '10-3':
          menus['lookupcodes']['actions'] = { ...menus['lookupcodes']['actions'], 'status' : true };
          break;
        case '11-0':
          menus['notifications']['actions'] = { ...menus['notifications']['actions'], 'create' : true };
          break;
        case '11-1':
          menus['notifications']['actions'] = { ...menus['notifications']['actions'], 'edit' : true };
          break;
        case '11-2':
          menus['notifications']['actions'] = { ...menus['notifications']['actions'], 'delete' : true };
          break;
        case '11-3':
          menus['notifications']['actions'] = { ...menus['notifications']['actions'], 'status' : true };
          break;
        case '12-0':
          menus['faqs']['actions'] = { ...menus['faqs']['actions'], 'create' : true };
          break;
        case '12-1':
          menus['faqs']['actions'] = { ...menus['faqs']['actions'], 'edit' : true };
          break;
        case '12-2':
          menus['faqs']['actions'] = { ...menus['faqs']['actions'], 'delete' : true };
          break;
        case '12-3':
          menus['faqs']['actions'] = { ...menus['faqs']['actions'], 'status' : true };
          break;
          case '13-0':
          menus['orderreports']['actions'] = { ...menus['orderreports']['actions'], 'create' : true };
          break;
        case '13-1':
          menus['orderreports']['actions'] = { ...menus['orderreports']['actions'], 'edit' : true };
          break;
        case '13-2':
          menus['orderreports']['actions'] = { ...menus['orderreports']['actions'], 'delete' : true };
          break;
        case '13-3':
          menus['orderreports']['actions'] = { ...menus['orderreports']['actions'], 'status' : true };
          break;
        default:
          break;   
      };
    };

    console.log('add roles :', menus);
    setMObj(menus);
  };

  const editPage = (row) => {
    let arr = [], objss = {};

    let arr1 = Object.keys(row);

    for(let i=0; i<arr1.length; i++){
      arr.push(arr1[i]);
    };

    let arr2 = [];

    Object.values(row).forEach(val => {
      arr2.push(Object.keys(val['actions']));
    });

    console.log(arr1, arr2);

    for(let j=0; j<arr1.length; j++){
      for(let k=0; k<arr2.length; k++){
        arr.push(`${arr1[j]}-${arr2[k][k]}`);
      }
    };

    for(let a=arr1.length; a<arr.length; a++){
      switch(arr[a]){ 
        case 'dashboard-create':
          objss['0-0'] = {checked: true, partialChecked: false};
          break;
        case 'dashboard-edit':
          objss['0-1'] = {checked: true, partialChecked: false};
          break;
        case 'dashboard-delete':
          objss['0-2'] = {checked: true, partialChecked: false};
          break;
        case 'dashboard-status':
          objss['0-3'] = {checked: true, partialChecked: false};
          break;
        case 'userorders-create':
          objss['1-0'] = {checked: true, partialChecked: false};
          break;
        case 'userorders-edit':
          objss['1-1'] = {checked: true, partialChecked: false};
          break;
        case 'userorders-delete':
          objss['1-2'] = {checked: true, partialChecked: false};
          break;
        case 'userorders-status':
          objss['1-3'] = {checked: true, partialChecked: false};
          break;
        case 'adminusers-create':
          objss['2-0'] = {checked: true, partialChecked: false};
          break;
        case 'adminusers-edit':
          objss['2-1'] = {checked: true, partialChecked: false};
          break;
        case 'adminusers-delete':
          objss['2-2'] = {checked: true, partialChecked: false};
          break;
        case 'adminusers-status':
          objss['2-3'] = {checked: true, partialChecked: false};
          break;
        case 'endusers-create':
          objss['3-0'] = {checked: true, partialChecked: false};
          break;
        case 'endusers-edit':
          objss['3-1'] = {checked: true, partialChecked: false};
          break;
        case 'endusers-delete':
          objss['3-2'] = {checked: true, partialChecked: false};
          break;
        case 'endusers-status':
          objss['3-3'] = {checked: true, partialChecked: false};
          break;
        case 'categories-create':
          objss['4-0'] = {checked: true, partialChecked: false};
          break;
        case 'categories-edit':
          objss['4-1'] = {checked: true, partialChecked: false};
          break;
        case 'categories-delete':
          objss['4-2'] = {checked: true, partialChecked: false};
          break;
        case 'categories-status':
          objss['4-3'] = {checked: true, partialChecked: false};
          break;
        case 'products-create':
          objss['5-0'] = {checked: true, partialChecked: false};
          break;
        case 'products-edit':
          objss['5-1'] = {checked: true, partialChecked: false};
          break;
        case 'products-delete':
          objss['5-2'] = {checked: true, partialChecked: false};
          break;
        case 'products-status':
          objss['5-3'] = {checked: true, partialChecked: false};
          break;
        case 'stores-create':
          objss['6-0'] = {checked: true, partialChecked: false};
          break;
        case 'stores-edit':
          objss['6-1'] = {checked: true, partialChecked: false};
          break;
        case 'stores-delete':
          objss['6-2'] = {checked: true, partialChecked: false};
          break;
        case 'stores-status':
          objss['6-3'] = {checked: true, partialChecked: false};
          break;
        case 'storemanagers-create':
          objss['7-0'] = {checked: true, partialChecked: false};
          break;
        case 'storemanagers-edit':
          objss['7-1'] = {checked: true, partialChecked: false};
          break;
        case 'storemanagers-delete':
          objss['7-2'] = {checked: true, partialChecked: false};
          break;
        case 'storemanagers-status':
          objss['7-3'] = {checked: true, partialChecked: false};
          break;
        case 'userroles-create':
          objss['8-0'] = {checked: true, partialChecked: false};
          break;
        case 'userroles-edit':
          objss['8-1'] = {checked: true, partialChecked: false};
          break;
        case 'userroles-delete':
          objss['8-2'] = {checked: true, partialChecked: false};
          break;
        case 'userroles-status':
          objss['8-3'] = {checked: true, partialChecked: false};
          break;
        case 'lookuptypes-create':
          objss['9-0'] = {checked: true, partialChecked: false};
          break;
        case 'lookuptypes-edit':
          objss['9-1'] = {checked: true, partialChecked: false};
          break;
        case 'lookuptypes-delete':
          objss['9-2'] = {checked: true, partialChecked: false};
          break;
        case 'lookuptypes-status':
          objss['9-3'] = {checked: true, partialChecked: false};
          break;
        case 'lookupcodes-create':
          objss['10-0'] = {checked: true, partialChecked: false};
          break;
        case 'lookupcodes-edit':
          objss['10-1'] = {checked: true, partialChecked: false};
          break;
        case 'lookupcodes-delete':
          objss['10-2'] = {checked: true, partialChecked: false};
          break;
        case 'lookupcodes-status':
          objss['10-3'] = {checked: true, partialChecked: false};
          break;
        case 'notifications-create':
          objss['11-0'] = {checked: true, partialChecked: false};
          break;
        case 'notifications-edit':
          objss['11-1'] = {checked: true, partialChecked: false};
          break;
        case 'notifications-delete':
          objss['11-2'] = {checked: true, partialChecked: false};
          break;
        case 'notifications-status':
          objss['11-3'] = {checked: true, partialChecked: false};
          break;
        case 'faqs-create':
          objss['12-0'] = {checked: true, partialChecked: false};
          break;
        case 'faqs-edit':
          objss['12-1'] = {checked: true, partialChecked: false};
          break;
        case 'faqs-delete':
          objss['12-2'] = {checked: true, partialChecked: false};
          break;
        case 'faqs-status':
          objss['12-3'] = {checked: true, partialChecked: false};
          break;
          case 'orderreports-create':
            objss['13-0'] = {checked: true, partialChecked: false};
            break;
          case 'orderreports-edit':
            objss['13-1'] = {checked: true, partialChecked: false};
            break;
          case 'orderreports-delete':
            objss['13-2'] = {checked: true, partialChecked: false};
            break;
          case 'orderreports-status':
            objss['13-3'] = {checked: true, partialChecked: false};
            break;
        default:
          break;
      }
    };

    for(let b=0; b<arr1.length; b++){
      switch(arr[b]){
        case 'dashboard':
          objss['0'] = {checked: true, partialChecked: false};
          break;
        case 'userorders':
          objss['1'] = {checked: true, partialChecked: false};
          break;
        case 'adminusers':
          objss['2'] = {checked: true, partialChecked: false};
          break;
        case 'endusers':
          objss['3'] = {checked: true, partialChecked: false};
          break;
        case 'categories':
          objss['4'] = {checked: true, partialChecked: false};
          break;
        case 'products':
          objss['5'] = {checked: true, partialChecked: false};
          break;
        case 'stores':
          objss['6'] = {checked: true, partialChecked: false};
          break;
        case 'storemanagers':
          objss['7'] = {checked: true, partialChecked: false};
          break;
        case 'userroles':
          objss['8'] = {checked: true, partialChecked: false};
          break;
        case 'lookuptypes':
          objss['9'] = {checked: true, partialChecked: false};
          break;
        case 'lookupcodes':
          objss['10'] = {checked: true, partialChecked: false};
          break;
        case 'notifications':
          objss['11'] = {checked: true, partialChecked: false};
          break;
        case 'faqs':
          objss['12'] = {checked: true, partialChecked: false};
          break;
          case 'orderreports':
          objss['13'] = {checked: true, partialChecked: false};
          break;
        default:
          break;
      }
    };

    console.log('edit out: ',objss);
    setSelectedKeys3(objss);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let data = JSON.stringify({DESCRIPTION: des, USER_ROLE_ID: roleId, USER_ROLE: rName, ROLE_JSON: { menu : menuObj }});
    console.log(data);

    axios({
      method: 'post',
      url: ADD_USER_ROLES,
      data: data,
      headers: { token: sessionStorage.getItem('token') }
    }).then((res) => {
      console.log(res);

      setDes(''); setRoleId(''); setRName('');
      setAOpn(false); menus = {};
    }).catch((err) => {
      console.log(err);
    })
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();
    
    let data = JSON.stringify({DESCRIPTION: des, USER_ROLE_ID: roleId, USER_ROLE: rName, ROLE_JSON: { menu : menuObj }});
    console.log(data);

    axios({
      method: 'put',
      url: EDIT_USER_ROLES + editID,
      data: data,
      headers: { token: sessionStorage.getItem('token') }
    }).then((res) => {
      console.log('res: ', res);
      
      setEOpn(false); setERole(''); setRName('');
      setDes(''); setRoleId(''); menus = ''
    }).catch((err) => {
      console.log(err);
    })
  };

  const handleView = (row) => {
    setView(true); setERole(row); console.log('view data -> ', row);
    editPage(row.ROLE_JSON.menu);
  };

  const handleEdit = (row) => {
    setEOpn(true); setERole(row.USER_ROLE); 
    setDes(row.DESCRIPTION); setRoleId(row.USER_ROLE_ID); setEId(row.ID);
    menus = row.ROLE_JSON.menu; editPage(row.ROLE_JSON.menu); setMObj(row.ROLE_JSON.menu);

    const data = rolesDropDown.filter((option) => (option.ID === row.USER_ROLE_ID));
    setRName(data[0].LOOKUP_DISPLAY_NAME);
    console.log('Selected row: ', row.ROLE_JSON.menu);
  };

  const actionHeader = (row) => {
    return <div style={{display: 'flex', flexDirection:'row'}}>
      <img
        src={View} alt='View Image' 
        style={{ marginRight: '1vw', width: '25px', height: '20px' }}
        onClick={() => handleView(row)}
      />
      
      <img
        src={Edit} alt='Edit Image'
        onClick={() => handleEdit(row)}
        style={{ marginRight: '1vw', width: '25px', height: '20px' }}
      />
      
      <img
        src={Delete} alt='Delete Image'
        style={{width: '25px', height: '20px'}}
        onClick={() => {setDel(true); setERole(row.USER_ROLE_ID)}}
      />
    </div> 
  };

  const setRol = (item, e) => {
    let arr = [...opts], arr2 = [...selObj];

    if(arr.length===0){
      arr.push(item);
    }else if(arr.length>=1){
      for(let i=0; i<arr.length; i++){
        if(item!==arr[i]){
          arr.push(item);
        }
      };
    };

    if (e.checked) {
      arr2.push(e.value);
    } else {
      for (let i = 0; i < arr2.length; i++) {
        if(e.value === arr2[i]){
          arr2.splice(i, 1);
          break;
        }              
      };
    };

    setOpts(arr); setSObj(arr2);
  };

  const setSelRow = (e) => {
    console.log(e.checked, e.value);

    let arr = [...opts], arr2 = [...selObj], 
    sopt = ['-create', '-edit', '-delete', '-view'];

    if(e.checked){
      if(arr.length>0){
        for(let k=0; k<arr.length; k++){
          if(arr[k]!==e.value){
            arr.push(e.value);
          }
        };
  
        for(let j=0; j<arr2.length; j++){
          if(arr2[j] !== `${e.value}${sopt[0]}`) {
            arr2.push(`${e.value}${sopt[0]}`);
          } else if(arr2[j] !== `${e.value}${sopt[1]}`) {
            arr2.push(`${e.value}${sopt[1]}`);
          } else if(arr2[j] !== `${e.value}${sopt[2]}`) {
            arr2.push(`${e.value}${sopt[2]}`);
          } else if(arr2[j] !== `${e.value}${sopt[3]}`) {
            arr2.push(`${e.value}${sopt[3]}`);
          };
        };
      } else {
        arr.push(e.value);
        
        arr2.push(`${e.value}${sopt[0]}`);
        arr2.push(`${e.value}${sopt[1]}`);
        arr2.push(`${e.value}${sopt[2]}`);
        arr2.push(`${e.value}${sopt[3]}`);
      }
    } else {
      for (let i = 0; i < arr.length; i++) {
        if(e.value === arr[i]){
          for(let k=0; k<arr2.length; k++){
            if(arr2[k] === `${e.value}${sopt[0]}`) {
              arr2.splice(k, 1);
            } 
            
            if(arr2[k] === `${e.value}${sopt[1]}`) {
              arr2.splice(k, 1);
            } 
            
            if(arr2[k] === `${e.value}${sopt[2]}`) {
              arr2.splice(k, 1);
            }
            
            if(arr2[k] === `${e.value}${sopt[3]}`) {
              arr2.splice(k, 1);
            };
          };

          arr.splice(i, 1);
        }              
      };
    }

    console.log(arr, arr2);

    setOpts(arr); setSObj(arr2);
  };

  return (
    <div className='pt-4 m-3'>
      <div style={{ width: "100%" }} className='datatable'>
        <DataTable 
          value={tableData} removableSort scrollable scrollHeight="75vh"
          size='medium' globalFilter={filter} header={tableHeader}
          responsiveLayout="scroll" 
        >
          <Column header='Role Name' className=' text-truncate' field='USER_ROLE' sortable />
          <Column header='Description' className=' text-truncate' field='DESCRIPTION' sortable />
          <Column header='Action' body={actionHeader} />
        </DataTable>
      </div>

      {/* Add Pop-up */}
      <Dialog 
        visible={addOpen} style={{width: '80vw', height: 'auto'}} 
        draggable={false} onHide={() => { setAOpn(false); setDes(''); setRoleId('') }} 
        header={<div style={{textAlign: 'center'}}>Add Role</div>}
        footer={<div style={{ textAlign: 'center' }}>
          <Button onClick={handleSubmit} style={{ width: '18%', backgroundColor: '#00cd5c', border: '0px solid' }}>Submit</Button>
        </div>}
      >
        <Container style={{marginTop: '0.5%'}}>
          <Row>
            <Col xs={12} sm={6}>
              <label>Role *</label>
              <Dropdown 
                style={{ width: '100%' }} value={roleId} 
                onChange={handleChange} options={rolesDropDown} 
                optionValue='ID' optionLabel='LOOKUP_DISPLAY_NAME'
              />
            </Col>

            <Col xs={12} sm={6}>
              <label>Description *</label>
              <InputText
                style={{ width: '100%' }} value={des} 
                onChange={e => setDes(e.target.value)} 
              />
            </Col>

            <Col xs={12} sm={12} style={{border: '1px solid #ccc', borderRadius: '5px', marginTop: '5px'}}>
              <div style={{textAlign: 'center', marginBottom: '2%', textDecorationLine: 'underline'}}>Page Assignment</div>

              <ScrollPanel style={{width: '100%', height: '45vh', marginTop: '-1vh'}}>
                {list && list.map((itm, key) => <div key={key} style={{ display: 'flex', margin: '8px' }}>
                  <div style={{width: '26%'}}> 
                    <Checkbox 
                      inputId={itm} value={itm} 
                      checked={opts.some((it) => it === itm)} 
                      onChange={(e) => setSelRow(e)}
                    />&nbsp;&nbsp;
                    <label htmlFor={itm}>{itm}</label>
                  </div>

                  <div style={{width: '10%'}}> - </div>

                  <div style={{width: '16%'}}>
                    <Checkbox 
                      inputId={`${itm}-create`} value={`${itm}-create`} 
                      checked={selObj.some((it) => it === `${itm}-create`)} 
                      onChange={(e) => setRol(itm, e)}
                    />&nbsp;&nbsp;
                    <label htmlFor={`${itm}-create`}>Create</label>
                  </div>

                  <div style={{width: '16%'}}>
                    <Checkbox 
                      inputId={`${itm}-edit`} value={`${itm}-edit`} 
                      checked={selObj.some((it) => it === `${itm}-edit`)} 
                      onChange={(e) => setRol(itm, e)}
                    />&nbsp;&nbsp;
                    <label htmlFor={`${itm}-edit`}>Edit</label>
                  </div>

                  <div style={{width: '16%'}}>
                    <Checkbox 
                      inputId={`${itm}-delete`} value={`${itm}-delete`} 
                      checked={selObj.some((it) => it === `${itm}-delete`)} 
                      onChange={(e) => setRol(itm, e)}
                    />&nbsp;&nbsp;
                    <label htmlFor={`${itm}-delete`}>Delete</label>
                  </div>
                  
                  <div style={{width: '16%'}}>
                    <Checkbox 
                      inputId={`${itm}-view`} value={`${itm}-view`} 
                      checked={selObj.some((it) => it === `${itm}-view`)}
                      onChange={(e) => setRol(itm, e)}
                    />&nbsp;&nbsp;
                    <label htmlFor={`${itm}-view`}>View</label>
                  </div>
                </div>)}
              </ScrollPanel>
            </Col>
          </Row>
        </Container>
      </Dialog>

      {/* Edit Pop-up */}
      <Dialog 
        visible={editOpen} style={{width: '75vw'}} 
        draggable={false} onHide={() => {setEOpn(false); setDes(''); setRoleId(''); menus = {}; setERole(''); setRName('');}}
        header={<div style={{textAlign: 'center'}}>Edit Role - {editRole}</div>}
        footer={<div style={{textAlign: 'center', marginTop: '2%'}}>
          <Button type="submit" variant="contained" onClick={handleEditSubmit} style={{ width: '18%' }} color="primary"> Submit </Button>
        </div>}
      >
        <form onSubmit={handleSubmit} method='post'>
          <Container style={{marginTop: '1%'}}>
            <Row>
              <Col xs={6}>
                <label>User Role *</label>
                <Dropdown 
                  style={{ width: '100%' }} value={roleId} 
                  onChange={handleChange} options={rolesDropDown} 
                  optionLabel='LOOKUP_DISPLAY_NAME' optionValue='ID' 
                /> 
              </Col>

              <Col xs={6}>
                <label>Description *</label>
                <InputText
                  style={{ width: '100%' }} value={des} 
                  onChange={e => setDes(e.target.value)}
                /> 
              </Col>
            </Row>
          </Container>

          <Tree 
            value={objs} filter filterMode="strict" 
            selectionMode="checkbox" selectionKeys={selectedKeys3} 
            onSelectionChange={
              (e) => { setSelectedKeys3(e.value); addPage(Object.keys(e.value)) }
            } 
          />
        </form>
      </Dialog>

      {/* View Pop-up */}
      <Dialog 
        visible={view} onHide={() => setView(false)}
        header={<h3>View Role Details - {editRole.USER_ROLE}</h3>} 
      >
        <Container>
          <Row>
            <Col xs={3}>
              <div className='sub_heading_1'>User Type</div>
            </Col>

            <Col xs={1}>
              <b>:</b>
            </Col>

            <Col xs={8}>
              {editRole.USER_ROLE}
            </Col>

            <Col xs={3}>
              <div className='sub_heading_1'>Description</div>
            </Col>

            <Col xs={1}>
              <b>:</b>
            </Col>

            <Col xs={8}>
              {editRole.DESCRIPTION}
            </Col>
          </Row>
        </Container>

        <Tree 
          value={objs} filter filterMode="strict" 
          selectionMode="checkbox" selectionKeys={selectedKeys3} 
        />
      </Dialog>

      {/* Delete Pop-up */}
      <Dialog 
        visible={del} style={{width: '35vw'}} 
        header={<div>Do you want to delete user Role ?</div>} 
        onHide={() => setDel(false)}
      >
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <Container>
            <Row>
              <Col xs={6} style={{color: '#2F4F4F', textAlign: 'end'}} onClick={() => setDel(false)}>No</Col>
              <Col xs={6} style={{color: 'red', textAlign: 'start'}}>Yes</Col>
            </Row>
          </Container>
        </div>
      </Dialog>
    </div>
  );
};

export default RolesList;