import React, { useState } from 'react';

import { Container, Row, Col, Button } from 'reactstrap';

import axios from 'axios';

import { Password } from 'primereact/password';

import { useHistory } from 'react-router-dom';

import { CHANGE_PASSWORD } from '../../config/config.js';

const ChangePassword = () => {
  const history = useHistory();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const localSiteData = JSON.parse(sessionStorage.getItem('id'));
  const [notifyDialog, setNotifyDialog] = useState(false);

  const listPage = () => {
    history.push(`/home`);
  };

  const handleSubmit = () => {
    let PASSWORD = newPassword;
    
    if (newPassword === confirmPassword) {
      axios({
        method: 'put',
        url: CHANGE_PASSWORD + localSiteData,
        data: {
          PASSWORD
        },
        headers: { token: sessionStorage.getItem('token') }
      })
      .then(response => {
        if (response.status === 200) {
          setNotifyDialog(!notifyDialog)
        }
      })
      .catch(error => {
        console.log(error);
      })
    }
  };

  return (
    <div className='pt-4 m-4 text-center'>
      <div className='datatable'>
        <h3>Change Password</h3>
        <Container className='my-3' style={{backgroundColor:'#fff', padding: '1%', borderRadius: '5px'}}>
          <Row> 
            <Col xs={12} sm={12} style={{margin: '10px'}}>
              <label style={{width: '50%', textAlign: 'end'}}>New Password *</label>
              <Password
                value={newPassword} style={{width: '50%', paddingLeft: '10px'}}
                onChange={e => setNewPassword(e.target.value)}
              />
            </Col>

            <Col xs={12} sm={12} style={{margin: '10px'}}>
              <label style={{width: '50%', textAlign: 'end'}}>Confirm Password *</label>
              <Password
                value={confirmPassword} style={{width: '50%', paddingLeft: '10px'}}
                onChange={e => setConfirmPassword(e.target.value)}
              />
            </Col>

            <div className='status_button d-flex justify-content-center mt-3'>
              <div>
                <Button className='contained_danger mx-2 px-4' onClick={listPage}>Cancel </Button>
              </div>
              
              <div>
                <Button className='contained_primary mx-2 px-4' onClick={handleSubmit}>Update </Button>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default ChangePassword;