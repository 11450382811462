import React, { useState, useEffect } from 'react';

import { Container, Row, Col, Button } from 'reactstrap';

import axios from 'axios';

import Add from '../icons/add.svg';
import Edit from '../icons/edit.svg';
import View from '../icons/view.svg';
import Delete from '../icons/delete.svg';
import Banner from '../Sidebar/icons/banners.svg';

import { 
  CATEGORIES_LIST, EDIT_CTEGORIES, IMAGE_DOMAIN,
  ADD_CATEGORIES, UPLOAD_IMAGE_CATEGORIES, CATEGORYBULKDELETE 
} from '../../config/config.js';

import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column'; 
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Image } from 'primereact/image';
import { Paginator } from 'primereact/paginator';


const CategoriesList = () => {

  const [tableData, setTableData] = useState([]);
  const [open, setOpen] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [popup, setPopup] = useState(false);

  const [newId, setNewId] = useState([]);
  const [filter, setFilter] = useState('');
  const [load, setLoad] = useState(false);

  const [rowID, setRID] = useState('');
  const [ctype, setCType] = useState('');
  const [category, setCategory] = useState('');
  const [dname, setDName] = useState('');
  const [desc, setDesc] = useState('');
  const [imgs, setImgs] = useState(null);
  const [errs, setErrs] = useState('');
  const [selectCategory, setSCategory] = useState(null);
  const [selectId, setSId] = useState([]);
  const [slcat, setSCat] = useState(false);

  const [errPop, setEPop] = useState(false);
  const [errMsg, setEMsg] = useState('');

  const [totalRecord, setTRecord] = useState(0);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [page, setPage] = useState(0);

  let mainId = JSON.parse(sessionStorage.getItem('id'));

  const handleView = (rowData) => {
    setOpen(true);
  
    setCType(rowData.CATEGORY_DISPLAY_NAME);
    setCategory(rowData.CATEGORY); 
    setDName(rowData.CATEGORY_DISPLAY_NAME);
    setDesc(rowData.DESCRIPTION); 
    setImgs(IMAGE_DOMAIN + rowData.IMAGE_PATH + rowData.IMAGE_NAME);
  };

  const handleEdit = (rowData) => {
    setPopup(true);

    setRID(rowData.ID); setCategory(rowData.CATEGORY); setErrs('');
    setDName(rowData.CATEGORY_DISPLAY_NAME); setCType(rowData.CATEGORY_DISPLAY_NAME);
    setDesc(rowData.DESCRIPTION); setImgs(rowData.IMAGE_PATH + rowData.IMAGE_NAME);
  };

  const handleClickOpen = (event) => {
    setNewId(event.ID)
    setOpenPopup(true);
  };

  const handleCloseDelete = () => {
    setOpenPopup(false);
  };

  const handleHide = () => {
    setPopup(false); 
    
    setRID(''); setCategory('');
    setDName(''); setDesc('');
  };

  const handleDelete = () => {
    axios({
      method: 'delete',
      url: EDIT_CTEGORIES + newId,
      'Content-Type': 'multipart/form-data',
      headers: { token: sessionStorage.getItem('token') }
    }).then(res => {
      loadPage(page, filter, rows);
    }).catch((err) => {
      console.log(err);
    });
    setOpenPopup(false);
  };

  const handleBulkDel = () => {
    axios({
      method: 'PATCH',
      url: CATEGORYBULKDELETE,
      data:{"selectedids": selectId},
    }).then((res) => {
      loadPage(page, filter, rows);
    }).catch((err) => {
      console.log(err);
    });
  };

  const selectionArr = (e) => {
    setSCategory(e.value); let arr = [];

    if(e.value.length >= 1){
      setSCat(true); 

      for(let i=0; i<e.value.length; i++){
        arr.push(e.value[i].ID);
      };

      setSId(arr);
    }else{
      setSCat(false);
    };
  };

  const loadPage = (page, filter, rows) => {
    setLoad(true);

    axios({
      method: 'get',
      url: CATEGORIES_LIST+'limit='+rows+'&skip='+(page+1)+(filter!=='' ? '&search='+filter : '&search='),
    }).then((res) => {
      if (res.data) {
        setTableData(res.data.rows); setLoad(false); 
        setTRecord(res.data.total_count); 
      } else {
        setLoad(false); setTRecord(0);
      }
    }).catch((err) => {
      console.log(err);
    });
  };

  const handleFilter = (e) => {
    setTableData([]); setTRecord(0);

    if(e.target.value!==''){
      setFilter(e.target.value);
      loadPage(page, e.target.value, rows);
    } else {
      setFilter('');
      // loadPage(page, e.target.value, rows);
      setTimeout(() => { window.location.reload() }, 5);
    }
  };

  useEffect(() => {
    loadPage(page, filter, rows);
  }, [popup]);

  useEffect(() => {
    loadPage(page, filter, rows);
  }, [filter]);

  const tableHeader=()=>{
    return <div className='DataTable'>
      <div className='m-2'>
        <h3>Category List </h3>
      </div>
      
      <div className='table-header-action'>
        <InputText className='m-2' value={filter} onChange={(e) => handleFilter(e)} placeholder='Search...' />
        
        <Button variant="contained" className="table-button m-2" onClick={() => { 
          setPopup(true); setCType(''); setImgs(null); setErrs('') 
        }}>
          <img src={Add} style={{ width: '25px', height:'20px', marginRight: 0.5 }} />  Add
        </Button>

        {slcat === true && <Button onClick={() => handleBulkDel()}  variant="contained" className="table-button-danger m-2"><img src={Delete} style={{ width: '25px', height:'20px', marginRight: 0.5 }} /> Delete</Button>}
      </div>
    </div>
  };

  const imgHeader=(rowData)=>{
    return <Image src={IMAGE_DOMAIN + rowData.IMAGE_PATH + rowData.IMAGE_NAME} preview width='100%' height='25px' />
  };

  const actionHeader=(rowData)=>{
    return <div style={{display: 'flex', flexDirection:'row'}}>
      <img 
        src={View}
        alt='View Image'
        onClick={() => handleView(rowData)}
        style={{width: '25px', height:'20px'}}
      />
      
      <img
        src={Edit}
        alt='Edit Image'
        onClick={() => handleEdit(rowData)}
        style={{marginLeft: '12%', width: '25px', height:'20px'}}
      />
      
      <img
        src={Delete}
        alt='Delete Image'
        onClick={() => handleClickOpen(rowData)}
        style={{marginLeft: '10%', width: '25px', height:'20px'}}
      />
    </div> 
  };

  const pageTemplate = {
    layout: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport',
    'CurrentPageReport': (options) => {
      return (
        <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '150px', textAlign: 'center' }}>
          {options.first} - {options.last} of {options.totalRecords}
        </span>
      )
    },
  };

  const onPage = (e) => {
    setFirst(e.first); setRows(e.rows);
    setPage(e.page); loadPage(e.page, filter, e.rows);
  };

  const descHeader=(rowData)=>{
    const limit = 35

    if(rowData.DESCRIPTION.length >! limit){
      return <div>{rowData.DESCRIPTION}</div>
    }else{
      return <div>{rowData.DESCRIPTION.slice(0, limit) + '...'}</div>
    }
  };

  const categorySubmit = (e) => {
    e.preventDefault(); let formData = new FormData();

    if(imgs!==null && category!=='' && desc!=='' && dname!==''){
      let obj = {
        CATEGORY: category,
        CATEGORY_DISPLAY_NAME: dname,
        DESCRIPTION: desc,
        CREATED_BY: mainId,
        UPDATED_BY: mainId,
      };

      if(ctype===''){
        axios.post(
          ADD_CATEGORIES, 
          obj, 
          { token: sessionStorage.getItem('token') }
        ).then((res) => {
          if(res.data.data.ID){
            formData.append('issueId', res.data.data.ID);
            formData.append('categoryImage', imgs);
            axios.put(
              UPLOAD_IMAGE_CATEGORIES + res.data.data.ID, 
              formData, 
              { headers: { 'Content-Type': 'multipart/form-data' } }
            ).then((res) => {
              setPopup(false); setRID(''); setCategory('');
              setDesc(''); setDName(''); setErrs(''); loadPage(page, filter, rows);
            }).catch((err) => {
              switch(err.response.status){
                case 500:
                case 501:
                case 502:
                case 503:
                case 504:
                  setEPop(true);
                  break;
                default: 
                  setEMsg('');
                  break;
              };
            });
          };
        }).catch((err) => {
          console.log(err);
        });
      }else{
        let obj = {
          CATEGORY: category,
          CATEGORY_DISPLAY_NAME: dname,
          DESCRIPTION: desc,
          UPDATED_BY: mainId,
        };

        axios.put(
          EDIT_CTEGORIES + rowID,
          obj,
          {token: sessionStorage.getItem('token')}
        ).then((res) => {
          setPopup(false); setRID(''); setCategory('');
          setDesc(''); setDName(''); setErrs(''); loadPage(page, filter, rows);

          if(URL.createObjectURL(imgs)){ 
            formData.append('issueId', rowID);
            formData.append('categoryImage', imgs);

            axios.put(
              UPLOAD_IMAGE_CATEGORIES + rowID, 
              formData, 
              { headers: { 'Content-Type': 'multipart/form-data' } }
            ).then((res) => {
            }).catch((err) => {
              switch(err.response.status){
                case 500:
                case 501:
                case 502:
                case 503:
                case 504:
                  setEPop(true);
                  break;
                default: 
                  setEMsg('');
                  break;
              };
            });
          }else{
            setPopup(false); setRID(''); setCategory('');
            setDesc(''); setDName(''); setErrs(''); loadPage(page, filter, rows);
          };
        });
      };
    }else{
      let erros = 'Please Fill details -';

      if(category===''){
        erros += ' Category';
      };
      if(dname===''){
        erros += ' Category Display Name';
      };
      if(desc===''){
        erros += ' Description';
      };
      if(imgs===null){
        erros += ' Image';
      };

      setErrs(erros);
    };
  };

  return (
    <div className='pt-4 m-3'>
      <div style={{ width: "100%" }} className='datatable'>
        <DataTable 
          value={tableData} removableSort globalFilter={filter} header={tableHeader} loading={load}
          scrollable scrollHeight="63vh" selectionPageOnly selection={selectCategory} 
          onSelectionChange={selectionArr} dataKey="ID" selectionMode="checkbox"
        >
          <Column selectionMode="multiple" className='max-w-80' />
          <Column header='Catergory' field='CATEGORY' className=' text-truncate' sortable />
          <Column header='Display Name' field='CATEGORY_DISPLAY_NAME' className=' text-truncate' sortable />
          <Column header='Description' field='DESCRIPTION' className=' text-truncate' sortablebody={descHeader} />
          <Column header='Image' className=' text-truncate' body={imgHeader} />
          <Column header='Actions' className=' text-truncate' body={actionHeader}/>
        </DataTable>

        <Paginator rows={rows} first={first} totalRecords={totalRecord} onPageChange={onPage} template={pageTemplate} />
      </div>

      {/* View pop-up */}
      <Dialog 
        visible={open}
        onHide={() => {setOpen(false); setDesc(''); setDName(); setCategory()}}
        style={{width: '75vw', height: '65vh'}}
        header={<div style={{textAlign: 'center'}}>{`Category Details - ${ctype}`}</div>}
      >
        <Container>
          <Row>
            <Col xs={3}>
              <Image src={imgs} width = '235px' height = '200px' style={{border:'15px'}} preview />
            </Col>

            <Col xs={8} style={{marginLeft: '2%'}}>
              <Row>
                <Col xs={4}>
                  <div>Category</div>
                </Col>

                <Col xs={1}>:</Col>

                <Col xs={7}>
                  <div>{category}</div>
                </Col>

                <Col xs={4}>
                  <div>Category Display Name</div>
                </Col>

                <Col xs={1}>:</Col>

                <Col xs={7}>
                  <div>{dname}</div>
                </Col>

                <Col xs={4}>
                  <div>Description</div>
                </Col>

                <Col xs={1}>:</Col>

                <Col xs={7}>
                  <div>{desc}</div>
                </Col>

              </Row>
            </Col>
          </Row>
        </Container>
      </Dialog>

      {/* Add / Edit pop-up */}
      <Dialog
        visible={popup}
        onHide={handleHide}
        style={{width: '75vw', height: '60vh'}}
        header={<div style={{textAlign: 'center'}}>{ctype==='' ? 'Add Category' : `Edit Category (${ctype})`}</div>}
      >
        <Container>
          <Row>
            <Col xs={3}>
              <div style={{ textAlign: 'center', width:'75%', marginLeft:'2%', border:'1px dashed #ccc', borderRadius: '15px' }}>
                {imgs === null ? <label htmlFor='uploadi' style={{ margin:'0.5%' }}>
                  <img src={Banner} style={{ width: '55px', height:'65px', padding:'0.5%' }} />
                  <div>Upload Image *</div>
                </label> : 
                <>
                  <div onClick={() => setImgs(null)} style={{
                    position:'absolute', left:'17%', width:'25px', height:'25px', marginTop:'0.5%', 
                    border:'1px solid red', background:'red', borderRadius:'25px',
                    color: 'whitesmoke', fontSize:'15px', fontWeight:'600', cursor: 'pointer',
                  }}>x</div>

                  {ctype === '' 
                    ? <img src={URL.createObjectURL(imgs)} style={{width: '12vw', height:'20vh', borderRadius:'15px'}} />
                    : imgs!==''&& !imgs.name ? <img src={IMAGE_DOMAIN+imgs} style={{width: '12vw', height:'20vh', borderRadius:'15px'}} /> :
                    <img src={URL.createObjectURL(imgs)} style={{width: '12vw', height:'20vh', borderRadius:'15px'}} />
                  }
                </>}

                <input id='uploadi' type='file' accept='.jpg, .jpeg, .png' style={{display: 'none'}} onChange={(e) => {e.target.files ? setImgs(e.target.files[0]) : setImgs(null)}} />
              </div>
            </Col>

            <Col xs={8}>
              <Row>
                <Col xs={6}>
                  <label>Category *</label>
                  <InputText style={{width: '100%'}} value={category} onChange={(e)=>setCategory(e.target.value)} />
                </Col>

                <Col xs={6}>
                  <label>Display Name *</label>
                  <InputText style={{width: '100%'}} value={dname} onChange={(e)=>setDName(e.target.value)} />
                </Col>

                <Col xs={12}> 
                  <label>Description *</label>
                  <InputTextarea style={{width: '100%'}} value={desc} onChange={(e)=>setDesc(e.target.value)} />
                </Col>
              </Row>
            </Col>

            <Col xs={12} style={{textAlign: 'center', marginTop: '1%'}}>
              <Button color='error' variant='outlined' style={{
                marginRight:'5%', fontSize:'14px', background: 'red',
                fontWeight:'600', color: 'white', border: '0px solid'
              }} onClick={() => {
                setPopup(false); setRID(''); setCategory('');
                setDesc(''); setDName(''); 
              }}>Cancel</Button> 

              <Button color='primary' variant='outlined' onClick={categorySubmit} style={{
                marginLeft: '5%', color:'white', border: '0px solid', fontSize:'14px', fontWeight:'400', 
                background:'linear-gradient(140deg, rgba(0, 197, 205, 1) 0%, rgb(183, 253, 254) 80%)'
              }}>Submit</Button>
            </Col>
          </Row>
        </Container>
      </Dialog>
     
      {/* Delete pop-up */}
      <Dialog
        closable={false}
        visible={openPopup}
        onHide={handleCloseDelete}
        header={<div>You want to delete the record ?</div>}
      >
        <Button onClick={handleCloseDelete} style={{marginLeft: '15%'}}>No</Button>
        <Button onClick={handleDelete} autoFocus>Yes</Button>
      </Dialog>
    </div>
  );
};

export default CategoriesList;