import React, { useState, useEffect } from 'react';

import axios from 'axios';

import Add from '../icons/add.svg';
import Edit from '../icons/edit.svg';
import View from '../icons/view.svg';
import Delete from '../icons/delete.svg';
import Banner from '../Sidebar/icons/banners.svg';

import { Container, Row, Col, Button } from 'reactstrap';

import { Form, Field } from 'react-final-form'; 

import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils'; 
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Editor } from 'primereact/editor';
import { Image } from 'primereact/image';

import { 
    BLOG_LIST, IMAGE_DOMAIN, ADD_BLOG, 
    EDIT_BLOG, UPLOAD_IMAGE_BLOG,
} from '../../config/config.js';
import './Blog.css';

let initialValue = {
    title: '', content: '', 
    isPublish: false
}, month = [
    'Jan', 'Feb', 'Mar', 
    'Apr', 'May', 'Jun', 
    'Jul', 'Aug', 'Sept', 
    'Oct', 'Nov', 'Dec'
];

const Blog = () => {
    const [tableData, setTableData] = useState([]);
    const [open, setOpen] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);
    const [popup, setPopup] = useState(false);
    const [newId, setNewId] = useState([]);
    const [filter, setFilter] = useState('');
    const [load, setLoad] = useState(false);
    const [errPop, setEPop] = useState(false);
    const [errMsg, setEMsg] = useState('');

    const [totalRecord, setTRecords] = useState(0);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [page, setPage] = useState(0);

    const [imgs, setImgs] = useState(null);
    // const [blog, setBlog] = useState('');
    const [sBlog, setSBlog] = useState('');

    let mainId = JSON.parse(sessionStorage.getItem('id'));

    const handleView = (row) => {
        setPopup(true); setSBlog(row);
        setImgs(row.MEDIA_PATH);
    };

    const handleEdit = (row) => {
        setOpen(true); setNewId(row.ID); 
        setSBlog(row); setImgs(row.MEDIA_PATH);

        initialValue = {
            title: row.TITLE, 
            isPublish: row.IS_PUBLISHED,
            content: row.CONTENT[0] === '"' ? JSON.parse(row.CONTENT) : row.CONTENT,
        };
    };

    const handleClickOpen = (event) => {
        setNewId(event.ID); setOpenPopup(true);
    };

    const handleCloseDelete = () => {
        setOpenPopup(false); setNewId('');
    };

    const handleDelete = () => {
        axios({
        method: 'delete',
        url: EDIT_BLOG + newId,
        'Content-Type': 'multipart/form-data',
        }).then((res) => {
            loadData(); setOpenPopup(false);
        });
    };

    const loadData = (page, filter, row) => {
        setLoad(true);

        axios({
            method: 'get',
            url: BLOG_LIST+'&limit='+row+'&skip='+(page+1)+(filter!=='' && '&search='+filter),
        }).then((res) => {
            if (res.data) {
                setTableData(res.data.rows); setLoad(false); 
                setTRecords(res.data.total_count);
            } else {
                setLoad(false); setTRecords(0);
            }
        }).catch((err) => {
            console.log(err);
        });
    };

    useEffect(() => {
        loadData(page, filter, rows);
    }, [popup]);

    const tableHeader=()=>{
        return <div className='DataTable'>
            <div className='m-2'>
                <h3>Blog List</h3>
            </div>
        
            <div className='table-header-action'>
                <InputText className='m-2' value={filter} onChange={(e) => setFilter(e.target.value)} placeholder='Search...' />
                <Button variant="contained" className="table-button m-2" onClick={() => {
                    setImgs(null); setSBlog(''); setOpen(true); setNewId('');
                }}><img src={Add} style={{ width: '25px', height: '20px', marginRight: '0.5%' }} /> Add</Button>
            </div>
        
        </div>
    };

    const imgHeader=(rowData)=>{
        return <Image src={IMAGE_DOMAIN + rowData.MEDIA_PATH} preview width='100%' height='35px' />
    };

    const actionHeader=(rowData)=>{
        return <div style={{display: 'flex', flexDirection:'row'}}>
        <img
            src={View}
            alt='View Image'
            onClick={() => handleView(rowData)}
            style={{width: '25px', height:'20px'}}
        />
        
        <img
            src={Edit}
            alt='Edit Image'
            onClick={() => handleEdit(rowData)}
            style={{marginLeft: '12px', width: '25px', height:'20px'}}
        />
        
        <img
            src={Delete}
            alt='Delete Image'
            onClick={() => handleClickOpen(rowData)}
            style={{marginLeft: '12px', width: '25px', height:'20px'}}
        />
        </div> 
    };

    const pageTemplate = {
        layout: 'RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport',
        'RowsPerPageDropdown': (options) => {
          const dropdownOptions = [
            { label: 10, value: 10 },
            { label: 25, value: 25 },
            { label: 50, value: 50 },
            { label: 100, value: 100 },
          ];
    
          return (
            <React.Fragment>
              <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
            </React.Fragment>
          );
        },
        'CurrentPageReport': (options) => {
          return (
            <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '150px', textAlign: 'center' }}>
              {options.first} - {options.last} of {options.totalRecords}
            </span>
          )
        }
    };

    const onPage = (e) => {
        setFirst(e.first); setPage(e.page); 
        setRows(e.rows); loadData(e.page, filter, e.rows);
    };

    const contentHeader=(rowData)=>{
        const limit = 30;
        var divContainer= document.createElement("div");

        if(rowData.CONTENT[0]==='"'){ 
            divContainer.innerHTML = JSON.parse(rowData.CONTENT); 

            if(divContainer.innerText.length <= limit){
                return <div>{divContainer.innerText}</div>
            }else{
                return <div>{divContainer.innerText.slice(0, limit) + '...'}</div>
            }
        }else{
            divContainer.innerHTML = rowData.CONTENT;

             if(divContainer.innerText.length <= limit){
                return <div>{divContainer.innerText}</div>
            }else{
                return <div>{divContainer.innerText.slice(0, limit) + '...'}</div>
            }
        }
    };

    const publishHeader = (row) => {
        if(row.IS_PUBLISHED===1){
            return <div style={{color: 'forestgreen', fontWeight: '700'}}>Publish</div>
        }else{
            return <div style={{color: 'darkgoldenrod', fontWeight: '700'}}>Draft</div>
        }
    };

    const addBlog = (obj, form) => {
        axios({
            method: 'post',
            url: ADD_BLOG,
            data: obj,
        }).then((res) => {
            form.restart();

            if(res.data.data.ID && (imgs!=='' && imgs!==null)){
                let data = new FormData();

                data.append('issueId', res.data.data.ID);
                data.append('blogImage', imgs);   

                axios.put(
                    UPLOAD_IMAGE_BLOG + res.data.data.ID, 
                    data, 
                    { headers: {'Content-Type': 'multipart/form-data'} }
                ).then((res) => {
                    setOpen(false); loadData(page, filter, rows); setLoad(false);
                }).catch((err) => {
                    switch(err.response.status){
                        case 500:
                        case 501:
                        case 502:
                        case 503:
                        case 504:
                            setEPop(true);
                            break;
                        default: 
                            setEMsg('');
                            break;
                    };
                });
            }else{
                setOpen(false); setLoad(false);
                loadData(page, filter, rows);
            };
        }).catch((err) => {
            console.log(err);
        });  
    };

    const editBlog = (obj, form) => {
        axios({
            method: 'put',
            url: EDIT_BLOG + newId,
            data: obj,
        }).then((res) => {
            if(imgs.name){
                let data = new FormData();

                data.append('issueId', newId);
                data.append('blogImage', imgs);   

                axios.put(
                    UPLOAD_IMAGE_BLOG + newId, 
                    data, 
                    { headers: {'Content-Type': 'multipart/form-data'} }
                ).then((res) => {
                    setOpen(false); loadData(page, filter, rows); setLoad(false); 
                    setNewId(''); form.restart();
                }).catch((err) => {
                    switch(err.response.status){
                        case 500:
                        case 501:
                        case 502:
                        case 503:
                        case 504:
                            setEPop(true);
                            break;
                        default: 
                            setEMsg('');
                            break;
                    };
                });
            }else{
                setOpen(false); loadData(page, filter, rows); setLoad(false);
                setNewId('');
            };
        }).catch((err) => {
            console.log(err);
        });
    };

    const handleOnSubmit = (data, form) => {
        setLoad(true);  

        let obj = {
            'TITLE': data.title,
            'CONTENT': JSON.stringify(data.content),
            'IS_PUBLISHED': data.isPublish,
            'UPDATED_BY': mainId,
        }; 

        if (newId === '') {
            obj.CREATED_BY = mainId; 
            addBlog(obj, form);
        } else {
            editBlog(obj, form);
        };
    };

    const handleClose = () => {
        setOpen(false); setNewId('');
        setPopup(false); setImgs(null);

        initialValue = {
            title: '', isPublish: false,
            content: '',
        };
    };

    const validate = (data) => {
        let errors = {};
    
        if (!data.title) {
          errors.title = 'Title is required.';
        }
    
        if (!data.content) {
            errors.content = 'Content is required.';
        }

        return errors;
    };

    const handleDate = (row) => {
        if(row.PUBLISHED_AT!==null){
            let date = new Date(row.PUBLISHED_AT);

            return `${date.getDate()<10 ? '0'+date.getDate() : date.getDate()}-${month[date.getMonth()]}-${date.getFullYear()}`
        }else{
            return '-'
        }
    };
    
    const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
    
    const getFormErrorMessage = (meta) => {
        return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
    };

    return (
        <div className='pt-4 m-3'>
            <div style={{ width: "100%" }} className='datatable'>
                <DataTable 
                    value={tableData} removableSort size='medium' globalFilter={filter} 
                    loading={load} header={tableHeader} className="mt-6" scrollable 
                    scrollHeight="70vh" responsiveLayout="scroll"
                >
                    <Column header='Title' field='TITLE' className='text-truncate' sortable />
                    <Column header='Content' field='CONTENT' className='text-truncate' sortable body={contentHeader} />
                    <Column header='Attachments' className='text-truncate' body={imgHeader} />
                    <Column header='Status' className='text-truncate' body={publishHeader} />
                    <Column header='Action' body={actionHeader} />
                </DataTable>
                
                <Paginator first={first} rows={rows} totalRecords={totalRecord} onPageChange={(e) => onPage(e)} template={pageTemplate}></Paginator>
            </div>

            {/* View Blog Pop-up */}
            <Dialog 
                visible={popup}
                onHide={handleClose}
                style={{width: '75vw', height: '95vh'}}
                header={<div style={{textAlign: 'center'}}>{`Blog Details - ${sBlog.TITLE}`}</div>}
            >
                <Container style={{marginTop: '2%'}}>
                    <Row>
                        <Col xs={4}>
                            {imgs ? <Image src={IMAGE_DOMAIN + imgs} width='235px' height='200px' style={{border:'15px'}} preview /> : 'No Image Found.'}
                        </Col>

                        <Col xs={8}>
                            <Row>
                                <Col xs={3}>Title</Col>
                                <Col xs={1}>:</Col>
                                <Col xs={8}>{sBlog.TITLE}</Col>

                                <Col xs={3}>Is Publish</Col>
                                <Col xs={1}>:</Col>
                                <Col xs={8}>{sBlog.IS_PUBLISHED===1 ? 'Yes' : 'No, Drafted.'}</Col>

                                <Col xs={3}>Published Date</Col>
                                <Col xs={1}>:</Col>
                                <Col xs={8}>{handleDate(sBlog)}</Col>

                                <Col xs={3}>Content</Col>
                                <Col xs={12}>
                                    <Editor style={{height:'44vh'}} value={sBlog && JSON.parse(sBlog.CONTENT)} readOnly={true} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Dialog>

            {/* Add / Edit Blog Pop-up */}
            <Dialog 
                visible={open} onHide={handleClose}
                style={{width: '75vw', height: '90vh'}}
                header={<div style={{textAlign: 'center'}}>{!sBlog ? 'Add Blog' : `Edit Blog ${sBlog.TITLE}` }</div>}
            >
                <Container>
                    <Form
                        onSubmit={handleOnSubmit} 
                        initialValues={initialValue}
                        validate={validate}
                        render={({ handleSubmit }) => 
                            <form onSubmit={handleSubmit}>
                                <Row>
                                    <Col xs={4}>
                                        <div style={{ textAlign: 'center', width:'75%', marginLeft:'2%', border:'1px dashed #ccc', borderRadius: '15px' }}>
                                            {imgs === null ? <label htmlFor='uploadi' style={{ margin:'0.5%' }}>
                                                <img src={Banner} style={{ width: '55px', height:'65px', padding:'0.5%' }} />
                                                <div>Upload Web Image *</div>
                                            </label> : 
                                            <>
                                                <div onClick={() => setImgs(null)} style={{
                                                position:'absolute', left:'17%', width:'25px', height:'25px', marginTop:'0.5%', 
                                                border:'1px solid red', background:'red', borderRadius:'25px',
                                                color: 'whitesmoke', fontSize:'15px', fontWeight:'600', cursor: 'pointer',
                                                }}>x</div>

                                                {!sBlog 
                                                    ? <img src={URL.createObjectURL(imgs)} style={{width: '12vw', height:'20vh', borderRadius:'15px'}} /> 
                                                    : imgs===null ? '' : imgs.name ? <img src={URL.createObjectURL(imgs)} style={{width: '12vw', height:'20vh', borderRadius:'15px'}} /> 
                                                    : <img src={IMAGE_DOMAIN + imgs} style={{width: '12vw', height:'20vh', borderRadius:'15px'}} />
                                                }
                                            </>}

                                            <input id='uploadi' type='file' style={{display: 'none'}} onChange={(e) => {e.target.files ? setImgs(e.target.files[0]) : setImgs('')}} />
                                        </div>
                                    </Col>

                                    <Col xs={8}>
                                        <Row>
                                            <Col xs={8}>
                                                <Field name='title' render={({ input, meta }) => (
                                                    <>
                                                        <label className={classNames({ 'p-error': isFormFieldValid(meta) })}>Title *</label>
                                                        <InputText style={{ width: '100%' }} {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} />
                                                        {getFormErrorMessage(meta)}
                                                    </>
                                                )} />
                                            </Col>

                                            <Col xs={4} style={{ marginTop: '2%' }}>
                                                <Field name='isPublish' type='checkbox' render={({ input }) => (
                                                    <>
                                                        <span style={{ marginRight: '5%' }}>Is Publish </span>
                                                        <Checkbox {...input} onChange={(e) => input.onChange(e.checked)} ></Checkbox>
                                                    </>
                                                )} />
                                            </Col>

                                            <Col xs={12}>
                                                <Field name='content' render={({ input, meta }) => (
                                                    <>
                                                        <label className={classNames({ 'p-error': isFormFieldValid(meta) })}>Content *</label>
                                                        <Editor 
                                                            style={{height:'42vh'}} {...input}
                                                            onTextChange={(e) => input.onChange(e.htmlValue)}  
                                                            className={classNames({ 'p-invalid': isFormFieldValid(meta) })}
                                                        />
                                                        {getFormErrorMessage(meta)}
                                                    </>
                                                )} />

                                            </Col>

                                            <Col xs={12} className='status_button text-center'>
                                                <Button 
                                                    className='contained_primary mt-3' 
                                                    onClick={handleSubmit} style={{ width: '15vw', marginLeft: '-35%', padding: '1%' }}
                                                >Submit</Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </form>
                        }                  
                    />
                </Container>
            </Dialog>

            {/* Delete Blog Pop-up */}
            <Dialog
                visible={openPopup} onHide={handleCloseDelete}
                header={<div style={{textAlign: 'center'}}>You want to delete the record ?</div>}
            >
                <div style={{textAlign: 'center'}}>
                    <Button onClick={handleCloseDelete} style={{ marginRight: '3%' }}>No</Button>
                    <Button onClick={handleDelete} autoFocus>Yes</Button>
                </div>
            </Dialog>
        </div>
    );
};

export default Blog;