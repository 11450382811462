import React, { useEffect, useState } from 'react';

import axios from 'axios';

import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Paginator } from 'primereact/paginator';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';

import { Container, Row, Col } from 'reactstrap';

import { useHistory, useLocation } from 'react-router-dom';

import Add from '../icons/add.svg';
import Edit from '../icons/edit.svg';
import View from '../icons/view.svg';
import Delete from '../icons/delete.svg';
import Back from '../Sidebar/icons/back.svg';

import {
    PRODUCT_ATTRIBUTE_LIST, ADD_PRODUCT_ATTRIBUTE,
    PRODUCT_ATTRIBUTE_TYPES_LIST, EDIT_PRODUCT_ATTRIBUTE,
    PRODUCT_ATTRIBUTE_TYPE_NAME_LIST, CHARGE_TYPE_LIST,
} from '../../config/config';

import './productAttributes.css';

const ProductAttributes = () => {
    const [tableData, setTData] = useState([]);
    const [attributetype_data, setattributetype_data] = useState([]);
    const [attributename_data, setattributename_data] = useState([]);
    const [MeasurmentName_Data, setMeasurmentName_Data] = useState([]);
    
    const [productID, setPId] = useState('');
    const [attributeID, setAId] = useState('');
    const [attributeValue, setAVal] = useState(0);
    const [attributeType, setAType] = useState('');
    const [attributeNameLookup, setANLName] = useState('');
    const [attributeNameDisplay, setANDName] = useState('');
    const [attributeNameId, setANId] = useState('');
    const [measurementName, setMName] = useState('');
    const [measurementID, setMID] = useState('');
    const [measurementValue, setMVal] = useState(0);
    const [popType, setPType] = useState('');
    const [filter, setFilter] = useState(null);
    const [editID, setEId] = useState('');

    const [selAttrObj, setSAObj] = useState(null);

    const [errs, setErr] = useState(false);
    const [load, setLoad] = useState(false);
    const [openAttibute, setOAttibute] = useState(false);
    const [deleteAttribute, setDAttribute] = useState(false);
    const [viewAttribute, setVAttribute] = useState(false);

    const [totalRecord, setTRecords] = useState(0);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [page, setPage] = useState(0);

    const location = useLocation(), history = useHistory(); 
    const id = JSON.parse(sessionStorage.getItem('id'));
    const { Attribute_data } = location.state;

    const loadData = (page) => {
        setLoad(true); getProductAttributeList(page, rows); 
        getAttributeType(); getMeasurementList();
    };

    const getProductAttributeList = (page, row) => {
        axios({
            method: 'GET',
            url: PRODUCT_ATTRIBUTE_LIST+'&limit='+row+'&skip='+(page+1)+'&productId='+Attribute_data.ID,
        }).then((res) => {
            setTData(res.data.data.rows);
            setPId(Attribute_data.ID);

            if (res.data) {
                setTData(res.data.data.rows); setLoad(false);
                setTRecords(res.data.data.total_count); 
                setPId(Attribute_data.ID);
            } else {
                setLoad(false); setTRecords(0);
                setPId(Attribute_data.ID);
            }
        }).catch((err) => {
            console.log(err);
        });
    };

    const getMeasurementList = () => {
        axios({
            method: 'GET',
            url: CHARGE_TYPE_LIST,
        }).then((res) => {
            setMeasurmentName_Data(res.data);
        }).catch((err) => {
            console.log(err);
        });
    };

    const attributeTypeBody = (row) => {
        let textArr = row.ATTRIBUTE_TYPE_NAME.split("_");

        return textArr.length===2 ? 
            textArr[0].charAt(0).toUpperCase() + textArr[0].slice(1).toLowerCase()+' '+textArr[1].charAt(0).toUpperCase() + textArr[1].slice(1).toLowerCase() : 
            textArr[0].charAt(0).toUpperCase() + textArr[0].slice(1).toLowerCase();
    };

    const handleAttributeType = (id) => {
        let result = attributetype_data.filter((it) => id === it.ID);
        setAId(id); setAType(result[0].LOOKUP_NAME);
        getAttributeName(result[0].LOOKUP_NAME);
    };

    const handleMeasurement = (id) => {
        let result = MeasurmentName_Data.filter((it) => id === it.ID);
        setMID(id); setMName(result[0].LOOKUP_NAME);
    };

    const getAttributeName = (type) => {
        axios({
            method: 'GET',
            url: PRODUCT_ATTRIBUTE_TYPE_NAME_LIST + type,
        }).then((res) => {
            setattributename_data(res.data);
        }).catch((err) => {
            console.log(err);
        });
    };

    const handleAttributeName = (id) => {
        let result = attributename_data.filter((it) => id === it.ID);
        setANId(id); setANLName(result[0].LOOKUP_NAME);
        setANDName(result[0].LOOKUP_DISPLAY_NAME);
    };

    const getAttributeType = () => {
        axios({
            method: 'GET',
            url: PRODUCT_ATTRIBUTE_TYPES_LIST,
        }).then((res) => {
            setattributetype_data(res.data);
        }).catch((err) => {
            console.log(err);
        });
    };

    const actionBody = (row) => {
        return <div>
            <img
                src={View}
                style={{marginRight: '1vw', width: '25px', height: '20px'}}
                onClick={() => handleView(row)}
            />
            <img
                src={Edit}
                style={{marginRight: '1vw', width: '25px', height: '20px'}}
                onClick={() => handleEdit(row)}
            />
            <img
                src={Delete}
                onClick={() => handleDelete(row)}
                style={{width: '25px', height: '20px'}}
            />
        </div>
    };

    const handleClosePage = () => {
        history.goBack(); localStorage.removeItem('products');
    };

    const handleClose = () => {
        setANId(''); setANLName(''); setANDName(''); setErr(false);
        setMID(''); setMName(''); setMVal(0); setLoad(true);
        setAId(''); setAType(''); setAVal(0); loadData(page);
    };

    const handleSubmit = () => {
        if (attributeNameId !== '' && attributeID !== '' && (attributeValue !== '' && attributeValue > 0) && measurementID !== '' && (measurementValue !== '' && measurementValue > 0)) {
            let obj = {
                "PRODUCT_ID": productID,
                "ATTRIBUTE_NAME": attributeNameLookup,
                "ATTRIBUTE_DISPLAY_NAME": attributeNameDisplay,
                "ATTRIBUTE_NAME_ID": parseInt(attributeNameId),
                "ATTRIBUTE_TYPE_ID": parseInt(attributeID),
                "ATTRIBUTE_TYPE_NAME": attributeType,
                "ATTRIBUTE_VALUE": attributeValue,
                "MEASUREMENT_NAME": measurementName,
                "MEASUREMENT_ID": parseInt(measurementID),
                "MEASUREMENT_VALUE": measurementValue,
                "UPDATED_BY": id,
            };

            if (editID !== '') {
                axios({
                    method: 'PUT',
                    url: EDIT_PRODUCT_ATTRIBUTE + editID,
                    data: obj,
                }).then((res) => {
                    setOAttibute(false); handleClose();
                    loadData(); console.log(res); setErr(false);
                }).catch((err) => {
                    console.log(err);
                });
            } else {
                obj.CREATED_BY = id;

                axios({
                    method: 'POST',
                    url: ADD_PRODUCT_ATTRIBUTE,
                    data: obj,
                }).then((res) => {
                    setOAttibute(false); handleClose();
                    loadData(); console.log(res); setErr(false);
                }).catch((err) => {
                    console.log(err);
                });
            };
        } else {
            setErr(true);
        };
    };

    const handleDelete = (row) => {
        setDAttribute(true); setAId(row.ID);
    };

    const measurementNameBody = (row) => {
        return row.MEASUREMENT_NAME.charAt(0).toUpperCase() + row.MEASUREMENT_NAME.slice(1).toLowerCase();
    };

    const tableHeader = () => {
        return <div className='DataTable'>
            <div className='m-2'>
                <h3>
                    <img className='backIcon' src={Back} onClick={handleClosePage} style={{ fontWeight: '500', width: '25px', height: '20px' }} /> Product Attributes List - {Attribute_data ? Attribute_data.PRODUCT_NAME : ''}
                </h3>
            </div>

            <div className='table-header-action'>
                <InputText className='m-2' value={filter} onChange={(e) => setFilter(e.target.value)} placeholder='Search...' />

                <Button className='table-button m-2' icon={
                    <img
                        src={Add} 
                        style={{ width: '25px', height: '20px', marginRight: 0.5 }} 
                    />
                } onClick={() => { setOAttibute(true); setPType('') }} label='Add' />
            </div>
        </div>
    };

    const pageTemplate = {
        layout: 'RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport',
        'RowsPerPageDropdown': (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 25, value: 25 },
                { label: 50, value: 50 },
                { label: 100, value: 100 },
            ];

            return (
                <React.Fragment>
                <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                </React.Fragment>
            );
        },
        'CurrentPageReport': (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '150px', textAlign: 'center' }}>
                {options.first} - {options.last} of {options.totalRecords}
                </span>
            )
        }
    };

    const onPage = (e) => {
        setFirst(e.first); setRows(e.rows);
        setPage(e.page); loadData(e.page, e.rows);
    };

    useEffect(() => {
        loadData(page, rows);
    }, []);

    const handleEdit = (row) => {
        setEId(row.ID); setOAttibute(true); setPType(row);
        setAId(row.ATTRIBUTE_TYPE_ID); setANId(row.ATTRIBUTE_NAME_ID);
        setAVal(row.ATTRIBUTE_VALUE); setMID(row.MEASUREMENT_ID); setMVal(row.MEASUREMENT_VALUE);

        setMName(row.MEASUREMENT_NAME); setANLName(row.ATTRIBUTE_NAME);
        setAType(row.ATTRIBUTE_TYPE_NAME); setANDName(row.ATTRIBUTE_DISPLAY_NAME);

        getAttributeName(row.ATTRIBUTE_TYPE_NAME);
    };

    const handleView = (row) => {
        setSAObj(row); setVAttribute(true);
    };

    const submitDelete = () => {
        axios({
            method: 'DELETE',
            url: EDIT_PRODUCT_ATTRIBUTE + attributeID
        }).then((res) => {
            console.log(res); setDAttribute(false);
            handleClose(); loadData(page);
        }).catch((err) => {
            console.log(err);
        });
    };

    const attributeValueBody = (row) => {
        return row.MEASUREMENT_NAME==='AMOUNT' ? 
        `₹ ${new Intl.NumberFormat('en-IN').format(row.ATTRIBUTE_VALUE)}` : 
        `${new Intl.NumberFormat('en-IN').format(row.ATTRIBUTE_VALUE)}%`;
    };

    const measurementValueBody = (row) => {
        return row.MEASUREMENT_NAME==='AMOUNT' ? 
        `₹ ${new Intl.NumberFormat('en-IN').format(row.MEASUREMENT_VALUE)}` : 
        `${new Intl.NumberFormat('en-IN').format(row.MEASUREMENT_VALUE)}%`;
    };

    return (
        <div className='pt-4 m-3'>
            {/* Product Atrribute Table */}
            <div style={{ width: "100%" }} className='datatable'>
                <DataTable
                    value={tableData} removableSort size='medium' globalFilter={filter} 
                    header={tableHeader} loading={load} scrollable scrollHeight="65vh"
                >
                    <Column header="Attribute Type" field='ATTRIBUTE_TYPE_NAME' sortable body={attributeTypeBody} />
                    <Column header="Attribute Name" field='ATTRIBUTE_DISPLAY_NAME' sortable />
                    <Column header="Attribute Value" field='ATTRIBUTE_VALUE' sortable body={attributeValueBody} />
                    <Column header="Charge Type" field='MEASUREMENT_NAME' sortable body={measurementNameBody} />
                    <Column header="Charge Value" field='MEASUREMENT_VALUE' sortable body={measurementValueBody} />
                    <Column header="Action" body={actionBody} />
                </DataTable>

                <Paginator rows={rows} first={first} totalRecords={totalRecord} onPageChange={onPage} template={pageTemplate} />         
            </div>

            {/* Add / Edit Product Atrribute */}
            <Dialog
                visible={openAttibute} onHide={() => { setOAttibute(false); handleClose() }}
                header={
                    <div style={{ textAlign: 'center' }}>
                        {popType !== '' 
                            ? `Edit Attribute - ${Attribute_data && Attribute_data.PRODUCT_NAME} - ${popType.ATTRIBUTE_NAME}` 
                            : `Add Attribute - ${Attribute_data && Attribute_data.PRODUCT_NAME}`
                        }
                    </div>
                } style={{ width: '50vw' }}
            >
                <Container>
                    <Row>
                        <Col xs={12} sm={4}>
                            <label>Attribute Type *</label>
                            <Dropdown 
                                style={{ width: '100%' }} options={attributetype_data} 
                                optionLabel='LOOKUP_DISPLAY_NAME' optionValue='ID' value={attributeID}
                                onChange={(e) => handleAttributeType(e.value)}
                            />
                            {(errs===true&&attributeID==='') && <small style={{color: 'red'}}>Attribute Type is required.</small>}
                        </Col>

                        <Col xs={12} sm={4}>
                            <label>Attribute Name *</label>
                            <Dropdown 
                                style={{ width: '100%' }} options={attributename_data} 
                                optionLabel='LOOKUP_DISPLAY_NAME' optionValue='ID' value={attributeNameId}
                                onChange={(e) => handleAttributeName(e.value)}
                            />
                            {(errs===true&&attributeNameId==='') && <small style={{color: 'red'}}>Attribute Name is required.</small>}
                        </Col>

                        <Col xs={12} sm={4}>
                            <label>Attribute Value *</label>
                            <InputText 
                                style={{ width: '100%' }} value={attributeValue} 
                                onChange={(e) => { setAVal(e.target.value); setMVal(e.target.value)}} 
                                onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                            />
                            {(errs===true&&attributeValue===0) && <small style={{color: 'red'}}>Attribute Value is required.</small>}
                        </Col>

                        <Col xs={12} sm={4}>
                            <label>Charge Type *</label>
                            <Dropdown 
                                style={{ width: '100%' }} options={MeasurmentName_Data} 
                                optionLabel='LOOKUP_DISPLAY_NAME' optionValue='ID' 
                                value={measurementID} onChange={(e) => handleMeasurement(e.value)}
                            />
                            {(errs===true&&measurementID==='') && <small style={{color: 'red'}}>Charge Type is required.</small>}
                        </Col>

                        <Col xs={12} sm={4}>
                            <label>Charge Value *</label>
                            <InputText 
                                style={{ width: '100%' }} value={measurementValue} readOnly
                                onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                            />
                            {(errs===true&&measurementValue===0) && <small style={{color: 'red'}}>Charge Value is required.</small>}
                        </Col>

                        <Col xs={12} style={{ textAlign: 'center', marginTop: '2%' }}>
                            <Button 
                                style={{ marginRight: '1%', backgroundColor: 'red' }} 
                                onClick={() => { setOAttibute(false); handleClose() }} 
                                label='Cancel' 
                            />

                            <Button 
                                style={{ marginLeft: '1%' }} 
                                onClick={handleSubmit} 
                                label='Submit' 
                            />
                        </Col>
                    </Row>
                </Container>
            </Dialog>

            {/* View Product Atrribute */}
            <Dialog
                visible={viewAttribute} onHide={() => { setVAttribute(false); setSAObj('') }}
                header={<div style={{ textAlign: 'center' }}>Product Attribute View</div>}
                style={{ width: '45vw' }}
            >
                {selAttrObj && <Container style={{ marginTop: '1%' }}>
                    <Row>
                        <Col xs={5} className='mt-1'>Attribute Type</Col>
                        <Col xs={1} className='mt-1'>:</Col>
                        <Col xs={6} className='mt-1'>{attributeTypeBody(selAttrObj)}</Col>

                        <Col xs={5} className='mt-1'>Attribute Name</Col>
                        <Col xs={1} className='mt-1'>:</Col>
                        <Col xs={6} className='mt-1'>{selAttrObj.ATTRIBUTE_DISPLAY_NAME}</Col>

                        <Col xs={5} className='mt-1'>Attribute Value</Col>
                        <Col xs={1} className='mt-1'>:</Col>
                        <Col xs={6} className='mt-1'>{attributeValueBody(selAttrObj)}</Col>

                        <Col xs={5} className='mt-1'>Charge Type</Col>
                        <Col xs={1} className='mt-1'>:</Col>
                        <Col xs={6} className='mt-1'>{measurementNameBody(selAttrObj)}</Col>

                        <Col xs={5} className='mt-1'>Charge Value</Col>
                        <Col xs={1} className='mt-1'>:</Col>
                        <Col xs={6} className='mt-1'>{measurementValueBody(selAttrObj)}</Col>
                    </Row>
                </Container>}
            </Dialog>

            {/* Delete Product Atrribute */}
            <Dialog
                visible={deleteAttribute} style={{ width: '35vw' }}
                onHide={() => { handleClose(); setDAttribute(false) }}
                header={<div style={{textAlign: 'center'}}>Do you want to delete attribute ?</div>}
            >
                <div className='status_button d-flex justify-content-center'>
                    <Button 
                        className='contained_primary mx-2' label='Cancel'
                        onClick={() => { handleClose(); setDAttribute(false) }} 
                    />

                    <Button 
                        className='contained_danger' 
                        onClick={submitDelete} label='Delete' 
                    />
                </div>
            </Dialog>
        </div>
    );
};

export default ProductAttributes;