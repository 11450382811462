import React, { useEffect } from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import Layout from './components/layout/layout';
import Login from './components/login/Login';
import routes from './Routes/route';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.bundle'

function App() {

  useEffect(() => {
    let token =  sessionStorage.getItem('token');

    if(!token){
      return <Redirect to='/login'  />
    }
  }, []);

  return (
    <div className="App">
        <Router basename={'/'}>
          <Switch>
            <Route path={'/login'} component={Login}/>
            
            <Route path={'/'} render={props => { 
              return (
                <Layout {...props}>
                  <Switch>
                    {routes.map((route) => (
                      <Route
                        key={route.path}
                        path={route.path}
                        component={route.component}/>
                    ))} 
                    <Redirect from="/" to="/login"/>
                  </Switch>
                </Layout >  
              ) 
            }} />
          </Switch> 
        </Router>
    </div>
  );
};

export default App;