import React, { useState } from 'react';

import Header from '../header/header';
import Menubar from '../Sidebar/Sidebar';
import './layout.css';

function Layout(props) {
  const [open, setOpen] = useState(true);
  const [showSide, setShowSide] = useState(false);

  const handleDrawer = (action) => {
    setOpen(action);
  };

  return (
    <div className='d-flex'>
      <div>
        <Header {...props} handleDrawer={handleDrawer} showSide={showSide} setShowSide={setShowSide}/>
        {!showSide ? <Menubar {...props} drawerOpen={open} /> : ''}
      </div>

      <div style={showSide ? { width: '100%' } : { width: '83vw' }}>
        <main className={open===false ? 'content' :'content contentShift'}>
          <div className={showSide ? 'containerone' : 'containertwo'}>{props.children}</div>
        </main>
      </div>
    </div>
  );
};

export default Layout;