import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import axios from 'axios';

import { Container, Row, Col } from 'reactstrap';

import { Image } from 'primereact/image';
import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';

import Zip from '../icons/zip.svg';
import Add from '../icons/add.svg';
import Edit from '../icons/edit.svg';
import View from '../icons/view.svg';
import Close from '../icons/close.svg';
import Delete from '../icons/delete.svg';
import Excel from '../Sidebar/icons/excelFile.svg';
import Download from '../Sidebar/icons/excelDownload.svg';

import { 
  PRODUCTS_LIST, GLOBAL_DOMAIN, EDIT_PRODUCT, IMAGE_DOMAIN,
  BULK_UPLOAD_PRODUCTS, PRODUCTBULKDELETE, DOWNLOAD_PRODUCT_TEMPLATE
} from '../../config/config.js';
import './Products.css';

const ProductsList = () => {
  const [tableData, setTableData] = useState([]);
  const [open, setOpen] = useState(false);
  const [newId, setNewId] = useState([]);
  const [bulkUploadOpen, setBulkUploadOpen] = useState(false);
  const [filter, setFilter] = useState('');
  const [load, setLoad] = useState(false);
  const [zipFile, setZFile] = useState(null);
  const [xlFile, setXLFile] = useState(null);
  const [selectProduct, setSProduct] = useState(null);
  const [slprd, setSPrd] = useState(false);
  const [selectId, setSId] = useState([]);

  const [excelLoad, setELoad] = useState(false);

  const [totalRecord, setTRecords] = useState(0);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [page, setPage] = useState(0);

  const [delErr, setDErr] = useState('');
  const [delDisable, setDisable] = useState(false);

  const history = useHistory();

  const handleOpenBulkUpload = () => {
    setBulkUploadOpen(true);
  };

  const handleCloseBulkUpload = () => {
    setBulkUploadOpen(false); setZFile(null); setXLFile(null);
  };

  const getProductList = (page, filter, row) => {
    setLoad(true);

    axios({
      method: 'get',
      url: PRODUCTS_LIST+'&limit='+row+'&skip='+(page+1)+(filter!=='' && '&search='+filter),
    }).then((res) => {
      if (res.data) {
        setTableData(res.data.rows); setLoad(false);
        setTRecords(res.data.total_count);
      } else {
        setLoad(false); setTRecords(0);
      }
    }).catch((error) => {
      console.log(error); setLoad(false);
    });
  };

  const handleView = (rowData) => {
    history.push(`/product/view/${rowData.ID}`);
  };

  const handleEdit = (rowData) => {
    history.push(`/product/edit/${rowData.ID}`);
  };

  const handleClickOpen = (e) => {
    setNewId(e.ID); setOpen(true);
  };

  const handleDelete = () => {
    axios({
      method: 'get',
      url: EDIT_PRODUCT + newId
    }).then((res) => {
      if(res.data.QUANTITY<=0){
        axios({
          method: 'delete',
          url: EDIT_PRODUCT + newId,
          'Content-Type': 'multipart/form-data',
          headers: {token: sessionStorage.getItem('token')}
        }).then((res) => {
          getProductList(page, filter, rows); setOpen(false);
        });
      }else if(delErr!=='' && delDisable===false){
        axios({
          method: 'delete',
          url: EDIT_PRODUCT + newId,
          'Content-Type': 'multipart/form-data',
          headers: {token: sessionStorage.getItem('token')}
        }).then((res) => {
          getProductList(page, filter, rows); setOpen(false);
        });
      }else{
        setDErr('Product Quantity is more than 1');
      };
    }).catch((err) => {
      console.log(err);
    });
  };

  const handleBulkDel = () => {
    axios({
      method: 'PATCH',
      url: PRODUCTBULKDELETE,
      data: { "selectedids": selectId },
    }).then((res) => {
      console.log(res); getProductList(page, filter); setSPrd(false);
    }).catch((err) => {
      console.log(err);
    });
  };

  const exportExcel = () => {
    setLoad(true); setELoad(true);

    axios({
      method: 'get',
      url: PRODUCTS_LIST+'&limit='+totalRecord+'&skip=null',
    }).then((res) => {
      if (res.data) {
        import('xlsx').then(xlsx => {
          const worksheet = xlsx.utils.json_to_sheet(res.data.rows);
          const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
          const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
          saveAsExcelFile(excelBuffer, 'Products'); setLoad(false); setELoad(false);
        });
      }
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then(module => {
      if (module && module.default) {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer], {
          type: EXCEL_TYPE
        });

        module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      };
    });
  };

  const excelTemplate = () => {
    axios({
      method: 'patch',
      url: DOWNLOAD_PRODUCT_TEMPLATE
    }).then((res) => {
      if(res.data){
        window.open(GLOBAL_DOMAIN+res.data.data.path, '_blank');
      }
    }).catch((err) => {
      console.log(err);
    });
  };

  const selectionArr = (e) => {
    setSProduct(e.value); let arr = [];

    if (e.value.length >= 1) {
      console.log(e.value); setSPrd(true);

      for (let i = 0; i < e.value.length; i++) {
        arr.push(e.value[i].ID);
      };

      setSId(arr);
    } else {
      setSPrd(false);
    };
  };

  useEffect(() => {
    getProductList(page, filter, rows);
  }, [filter]);

  useEffect(() => {
    if (load === true) {
      const timer = setTimeout(() => { setLoad(false) }, 2500);
      return () => clearTimeout(timer);
    };
  }, [load]);

  const tableHeader = () => {
    return <div className='DataTable'>
      <div className='m-2'>
        <h3>Products List</h3>
      </div>

      <div className='table-header-action'>
        <Container>
          <Row>
            <Col lg={3} md={3} sm={3}>
              <div style={{display: 'flex'}}>
                {slprd === true && <div style={{ cursor: 'pointer', marginTop: '1.75vh' }} onClick={() => handleBulkDel()}>
                  <img src={Delete} style={{ color: 'red', width: 35, height: 'auto', border: '1px solid', borderRadius: '2.5px', padding: '5px' }} />
                </div>}

                <div>
                  <InputText className='m-2' value={filter} onChange={(e) => setFilter(e.target.value)} placeholder='Search for Name...' />
                </div>
              </div>
            </Col>

            <Col lg={3} md={3} sm={3}>
              <Button variant="contained" className='table-button m-2' style={{ lineHeight: '15px' }} onClick={handleOpenBulkUpload}>Bulk Upload</Button>
            </Col>

            <Col lg={3} md={3} sm={3}>
              <Button variant="contained" className='table-button m-2' onClick={() => history.push('/product/add')}>
                <img src={Add} style={{ width: 25, height: 20, marginRight: 0.2 }} />  Add
              </Button>
            </Col>

            <Col lg={3} md={3} sm={3}>
              <Button variant="contained" className='m-2 table-button' onClick={exportExcel} disabled={excelLoad}><img src={Download} style={{ width: 25, height: 20, marginRight: 0.2 }} />Excel</Button>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  };

  const imgHeader = (rowData) => {
    return <div>
      <Image src={IMAGE_DOMAIN + rowData.IMAGE_PATH1} preview width='45px' height='25px' />
      {(rowData.IMAGE_PATH2 !== null && rowData.IMAGE_PATH2 !== '') && <Image src={IMAGE_DOMAIN + rowData.IMAGE_PATH2} preview width='45px' height='25px' />}
      {(rowData.IMAGE_PATH3 !== null && rowData.IMAGE_PATH3 !== '') && <Image src={IMAGE_DOMAIN + rowData.IMAGE_PATH3} preview width='45px' height='25px' />}
    </div>
  };

  const actionHeader = (rowData) => {
    return <div style={{ display: 'flex', flexDirection: 'row' }}>
      <img
        src={Add}
        alt='Add Product Attributes'
        onClick={() => handleAttributes(rowData)}
        style={{width: 25, height: 20}}
      />

      <img
        src={View}
        alt='View Product'
        style={{ marginLeft: '5px', width: 25, height: 20 }}
        onClick={() => handleView(rowData)}
      />

      <img
        src={Edit}
        alt='Edit Product'
        onClick={() => handleEdit(rowData)}
        style={{ marginLeft: '5px', width: 25, height: 20 }}
      />

      <img
        src={Delete}
        alt='Delete Product'
        onClick={() => handleClickOpen(rowData)}
        style={{ marginLeft: '5px', width: 25, height: 20 }}
      />

      {/* {rowData.IS_DEFAULT_PRODUCT===1 && <img
        src={Default} 
        alt='Default Product'
        onClick={() => handleDefault(rowData)}
        style={{ marginLeft: '5px', width: 25, height: 20 }}
      />} */}
    </div>
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    var formData = new FormData();
    formData.append('productsData', xlFile)
    formData.append('productsImages', zipFile)
    axios({
      method: 'patch',
      url: BULK_UPLOAD_PRODUCTS,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      if (response) {
        getProductList(page, filter, rows); setBulkUploadOpen(false);
        // setZFile(null); setXLFile(null);
        // acceptedFiles.splice(acceptedFiles.length, 1); 
      };
    }).catch((err) => {
      console.log(err);
    });
  };

  const pageTemplate = {
    layout: 'RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        { label: 10, value: 10 },
        { label: 25, value: 25 },
        { label: 50, value: 50 },
        { label: 100, value: 100 },
      ];

      return (
        <React.Fragment>
          <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
        </React.Fragment>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '150px', textAlign: 'center' }}>
          {options.first} - {options.last} of {options.totalRecords}
        </span>
      )
    }
  };

  const onPage = (e) => {
    setFirst(e.first); setRows(e.rows);
    setPage(e.page); getProductList(e.page, filter, e.rows);
  };

  const descHeader = (rowData) => {
    const limit = 25

    if (rowData.DESCRIPTION.length <= limit) {
      return <div>{rowData.DESCRIPTION}</div>
    } else {
      return <div>{rowData.DESCRIPTION.slice(0, limit) + '...'}</div>
    }
  };

  const priceBody = (row) => {
    return <div> {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(row.PRICE)}</div>
  };

  const onExcelUpload = (e) => {
    if (e.target.files) {
      setXLFile(e.target.files[0]);
    } else {
      setXLFile(null);
    };
  };

  const onZipUpload = (e) => {
    if (e.target.files) {
      setZFile(e.target.files[0]);
    } else {
      setZFile(null);
    };
  };

  const removeFile = (file) => {
    if (file) {
      if (file.type === 'application/zip') {
        setZFile(null);
      } else {
        setXLFile(null);
      };
    };
  };

  const quantityCheck = (row) => {
    return row.QUANTITY <= 0
      ? <div style={{ color: 'red', fontWeight: '700', textAlign: 'left' }}>Out Of Stock</div>
      : row.QUANTITY;
  };

  const handleAttributes = (row) => {
    localStorage.setItem('products', JSON.stringify({ row }));
    let AttributeData = {Attribute_data : row}
    history.push('/productattributes', AttributeData);
  };

  const handleDelClose = () => {
    setOpen(false); setDErr('');
  };

  const handlePDel = () => {
    setDisable(false);
  };

  return (
    <div className='pt-4 m-3'>
      <div style={{ width: "100%" }} className='datatable'>
        <DataTable
          value={tableData} removableSort size='medium' header={tableHeader} 
          loading={load} scrollable scrollHeight="63vh" selection={selectProduct} 
          onSelectionChange={selectionArr} dataKey="ID" selectionMode="checkbox"         
        >
          <Column selectionMode="multiple" style={{ maxWidth: '60px' }} />
          <Column header='Name' field='PRODUCT_NAME' sortable />
          <Column header='Category' field='CATEGORY_DISPLAY_NAME' sortable />
          <Column header='Product Family' field='PRODUCT_FAMILY' sortable />
          <Column header='Price' field='PRICE' sortable body={priceBody} />
          <Column header='Quantity' field='QUANTITY' sortable body={quantityCheck} />
          <Column header='Description' field='DESCRIPTION' sortable body={descHeader} />
          <Column header='Attachments' body={imgHeader} />
          <Column header='Actions' body={actionHeader} />
        </DataTable>

        <Paginator rows={rows} first={first} totalRecords={totalRecord} onPageChange={onPage} template={pageTemplate} />
      </div>

      {/* Bulk Upload pop-up */}
      <Dialog
        visible={bulkUploadOpen} onHide={handleCloseBulkUpload}
        header={<div style={{ lineHeight: '15px' }}>Bulk Upload</div>}
        footer={<Container>
          <Row>
            <Col xs={6} style={{ width: '50%', textAlign: 'right', padding: '2.5%' }}>
              <Button variant="contained" onClick={() => { setBulkUploadOpen(false); setZFile(null); setXLFile(null) }} style={{ backgroundColor: "red", color: "white" }}>Cancel </Button>
            </Col>

            <Col xs={6} style={{ width: '50%', textAlign: 'left', padding: '2.5%' }}>
              <Button type="submit" variant="contained" onClick={(zipFile !== null && xlFile !== null) && handleSubmit} color={(zipFile !== null && xlFile !== null) ? "primary" : ""}>Submit </Button>
            </Col>
          </Row>         
        </Container>}
        style={{ height: '75vh', width: '45vw' }}
      >
        <Container style={{ marginTop: '1%', paddingLeft: '6%' }}>
          <Row>
            <Col xs={12} style={{ fontWeight: '700' }}>
              Download Upload XLSX Format - <span style={{ color: 'darkgreen' }} onClick={excelTemplate}>
                <img src={Download} style={{width: 25, height: 20}} />
              </span>
            </Col>

            <Col xs={11} style={{ marginTop: '2%', border: '1px dotted grey', borderRadius: '5px', padding: '5%', textAlign: 'center' }}>
              <label for="exampleFileXlsx">
                {xlFile !== null ? <span>Excel File -</span> : <span style={{ fontWeight: '700' }}>Choose Excel File</span>}
              </label>

              <input
                style={{ display: 'none' }}
                id="exampleFileXlsx"
                name="file"
                type="file"
                accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                onChange={(e) => onExcelUpload(e)}
              />

              {xlFile !== null && <div>
                <img src={Excel} style={{ color: 'green', width: 25, height: 20 }} />&nbsp;&nbsp;{xlFile.name} - {(xlFile.size / 1048576).toFixed(2)} MB&nbsp;&nbsp;
                <img src={Close} style={{ color: 'red', width: 25, height: 20 }} onClick={() => removeFile(xlFile)} />
              </div>}
            </Col>

            <Col xs={11} style={{ marginTop: '8%', border: '1px dotted grey', borderRadius: '5px', padding: '5%', textAlign: 'center' }}>
              <label for="exampleFileZip">
                {zipFile !== null ? <span> Zip File - </span> : <span style={{ fontWeight: '700' }}>Choose Zip File</span>}
              </label>

              <input
                style={{ display: 'none' }}
                id="exampleFileZip"
                name="file"
                type="file"
                accept='application/zip'
                onChange={(e) => onZipUpload(e)}
              />

              {zipFile !== null && <div>
                <img src={Zip} style={{ color: 'brown', width: 25, height: 20 }} />&nbsp;&nbsp;{zipFile.name} - {(zipFile.size / 1048576).toFixed(2)} MB&nbsp;&nbsp;
                <img src={Close} style={{ color: 'red', width: 25, height: 20 }} onClick={() => removeFile(zipFile)} />
              </div>}
            </Col>
          </Row>
        </Container>
      </Dialog>
 
      {/* Delete pop-up  */}
      <Dialog
        visible={open} onHide={handleDelClose} closable={false}
        header={<div>You want to delete the record ?</div>}
      >
        <div style={{textAlign: 'center'}}>
          {delErr && <div 
            style={{
              textAlign: 'center', color: 'red', 
              fontWeight: '700'
            }}>
              {delErr}

              <div style={{ 
                textAlign: 'center', 
                marginTop: '8px', marginBottom: '10px' 
              }}>
                <span onClick={handlePDel} style={{ 
                  color: '#ffb900', border: '1px solid #ffb900', 
                  borderRadius: '5px', padding: '2%', cursor: 'default'
                }}>Accept</span>
              </div>
            </div>
          }
          <Button onClick={handleDelClose} style={{ marginRight: '2%' }}>No</Button>
          <Button onClick={handleDelete} style={{ marginLeft: '2%' }} disabled={delDisable}>Yes</Button>
        </div>
      </Dialog>
    </div>
  );
};

export default ProductsList;