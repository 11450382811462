import React, { useState, useEffect, Fragment } from 'react';

import { Button, Container, Row, Col } from 'reactstrap';

import axios from 'axios';

import { InputNumber } from 'primereact/inputnumber';
import { Paginator } from 'primereact/paginator';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Image } from 'primereact/image';

import View from '../icons/view.svg';
import Check from '../Sidebar/icons/check.svg';
import ExcelFile from '../Sidebar/icons/excelFile.svg';
import Wallet from '../Sidebar/icons/wallet.svg';
import Store from '../Sidebar/icons/store.svg';
import Order from '../Sidebar/icons/order.svg';
import Delete from '../icons/delete.svg';

import { 
  END_USERS_LIST, USER_CART_LIST, 
  USER_ORDER_LIST, CART, IMAGE_DOMAIN,
  USER_COUPON_LIST, USER_TRANSACTIONS_LIST,
  // SUBMITORDER,
} from '../../config/config.js';

const EndUsersList = () => {
  const [tableData, setTableData] = useState([]);
  const [filter, setFilter] = useState('');  
  // const [orderFilter, setOFilter] = useState('');
  // const [walletFilter, setWFilter] = useState('');
  // const [cartFilter, setCFilter] = useState('');
  const [selectName, setName] = useState('');
  const [cart, setCart] = useState(false);
  const [order, setOrder] = useState(false);
  const [wallet, setWallet] = useState(false);
  const [load, setLoad] = useState(false);

  const [totalRecord, setTRecords] = useState(0);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [page, setPage] = useState(0);

  const [cartList, setCList] = useState([]);
  const [orderList, setOList] = useState([]);
  const [walletList, setWList] = useState([]);
  const [product, setProduct] = useState([]);
  const [coupons, setCpns] = useState([]);
  const [userView, setUserView] = useState(false);
  const [view, setView] = useState(false);
  const [edata, setEData] = useState(false);
  const [startD, setStartD] = useState(null);
  const [end, setEnd] = useState(null);
  const [selObj, setSObj] = useState('');

  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

  const viewCart = (rowData) => {
    setLoad(true);
    setName(rowData.FIRST_NAME+" "+rowData.LAST_NAME);
    
    axios.get(
      USER_CART_LIST+rowData.ID
    ).then((res) => { 
      // cartArr.push(res.data.rows);
      setCList(res.data.rows);
      setLoad(false); setCart(true); 
    }).catch((err) => {
      console.log(err);
    });
  };

  const viewOrder = (rowData) => {
    setLoad(true);
    setName(rowData.FIRST_NAME+" "+rowData.LAST_NAME);

    axios.get(
      USER_ORDER_LIST + rowData.ID,
    ).then((res) => {
      setOList(res.data.rows); 
      setLoad(false); setOrder(true); 
    }).catch((err) => {
      console.log(err);
    });
  };

  const viewWallet = (row) => {
    setLoad(true);
    setName(row.FIRST_NAME+" "+row.LAST_NAME);

    axios.get(
      USER_TRANSACTIONS_LIST+row.ID
    ).then((res) => {
      setWList(res.data.rows); setLoad(false); setWallet(true);
    }).catch((err) => {
      console.log(err);
    });
  };

  const loadData = (page, filter, row) => {
    setLoad(true);

    axios({
      method: 'get',
      url: END_USERS_LIST+'&limit='+row+'&skip='+(page+1)+(filter!=='' && '&search='+filter),
    }).then((res) => {
      if (res.data) {
        setLoad(false); setTableData(res.data.rows);
        setTRecords(res.data.total_count);
      } else {
        setLoad(false); setTRecords(0);
      };
    }).catch((err) => {
      console.log(err);
    });
  };

  useEffect(() => {
    loadData(page, filter, rows);
  }, [filter]);

  const tableHeader=()=>{
    return <div className='DataTable'>
      <div className='m-2'>
        <h3>End Users List</h3>
      </div>      

      <div className='table-header-action'>
        <InputText className='m-2' value={filter} onChange={(e) =>setFilter(e.target.value)} placeholder='Search...' />

        <Button className="table-button m-2" onClick={() => setEData(true)} disabled={true}>
          <img src={ExcelFile} style={{ width: '25px', height:'20px', marginRight: '12px' }} /> Export
        </Button>
      </div>
    </div>
  };

  const nameBody = (rowData) => {
    return rowData.LAST_NAME!==null ? <span>{rowData.FIRST_NAME +" "+rowData.LAST_NAME}</span>: rowData.FIRST_NAME ;
  };

  const imgHeader = (rowData) => {
    return <Image src={IMAGE_DOMAIN + rowData.IMAGE_PATH1} preview width='100%' height='25px' />
  };

  const orderDate = (row) => {
    let dt = new Date(row.CREATED_AT);

    return dt.getDate() + '-' + months[dt.getMonth()] + '-' + dt.getFullYear();
  };

  const orderStatus = (rowData) => {
    return <div className='status_button'> 
      {rowData.STATUS === "ORDERED" && <Button label="ORDER PLACED" variant="outlined" className="primary">ORDER PLACED</Button>} 
      {rowData.STATUS === "DELIVERED" && <Button label="DELIVERED" variant="outlined" className="success">DELIVERED</Button>} 
      {rowData.STATUS === "DISPATCHED" && <Button label="DISPATCHED" variant="outlined" className="warning">DISPATCHED</Button>} 
      {rowData.STATUS === "CANCELLED" && <Button label="CANCELLED" variant="outlined" className="danger"> CANCELLED</Button>} 
      {rowData.STATUS === "PREBOOK" && <Button label="CANCELLED" variant="outlined" className="secondary"> PREBOOK</Button> }
    </div>
  };

  const statusHeader = (rowData) => {
    return <div className='status_button'>
      {rowData.PAYMENT_STATUS === null && <Button label="ERROR" variant="outlined" className="order_placed danger"> ERROR </Button>}
      {rowData.PAYMENT_STATUS === 'SUCCESS' && <Button label="SUCCESS" variant="outlined" className="success"> SUCCESS</Button>}
      {rowData.PAYMENT_STATUS === 'PENDING' && <Button label="PENDING" variant="outlined" className="warning">PENDING</Button>}
      {rowData.PAYMENT_STATUS === 'FAILED' && <Button label="FAILED" variant="outlined" className="danger"> FAILED</Button>}
    </div>
  };

  const payModeHeader = (rowData) => {
    return <div className='status_button'>
      {rowData.PAYMENT_MODE === 'COD' && <Button label="Cash On Delivery" variant="contained" className="contained_primary" >Cash On Delivery</Button>}
      {rowData.PAYMENT_MODE === 'ONLINE' && <Button label='Payment Online' variant="contained" className="contained_success">Payment Online</Button>}
      {rowData.PAYMENT_MODE === 'ATUM_WALLET' && <Button label='ATUM Wallet' variant="contained" className="contained_success">ATUM Wallet</Button>}
    </div>
  };

  const delRow = (data) => {
    let arr = [...product], tData = [...cartList];

    if(product.length>0){
      for(let i=0; i<arr.length; i++){
        if(arr[i].ID !== data.ID){
          arr.push(data);
        }else if(arr[i].ID ===  data.ID){
          arr.splice(arr.indexOf(data.ID), 1);
        }
      }
    }else{
      arr.push(data);
    };

    for(let j=0; j<tData.length; j++){
      if(tData[j].ID ===  data.ID){
        tData.splice(tData.indexOf(data.ID), 1);
      }
    };

    axios({
      method: 'delete',
      url: CART + data.ID,
    }).then((res) => {
      viewWallet(data);
    }).catch((err) => {
      console.log(err);
    });
    
    setCList(tData);
  };

  const pageTemplate = {
    layout: 'RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        { label: 10, value: 10 },
        { label: 25, value: 25 },
        { label: 50, value: 50 },
        { label: 100, value: 100 },
      ];

      return (
        <React.Fragment>
          <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
        </React.Fragment>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '150px', textAlign: 'center' }}>
          {options.first} - {options.last} of {options.totalRecords}
        </span>
      )
    }
  };

  const quantityEditor = (options) => {
    if(options.field === 'QUANTITY'){
      return <InputNumber className='qunty'
        inputId="minmax" style={{width: '25px'}}
        value={options.value} 
        onValueChange={(e) => options.editorCallback(e.value)} 
        mode="decimal" min={1} max={100}
      />
    };
  };

  const isPositiveInteger = (val) => {
    let str = String(val);

    str = str.trim();
    
    if(!str){
      return false;
    };

    str = str.replace(/^0+/, "") || "0";

    let n = Math.floor(Number(str));

    return n !== Infinity && String(n) === str && n >= 0;
  };

  const quantityChange = (e) => {
    let { rowData, newValue, field, originalEvent: event } = e;

    switch (field) {
      case 'QUANTITY':
        if(isPositiveInteger(newValue))
          rowData[field] = newValue;
        else
          event.preventDefault();
        break;

      default:
        if(newValue.trim().length > 0)
          rowData[field] = newValue;
        else
          event.preventDefault();
        break;
    }
  };

  const quantitySubmit = (data) => {
    axios({
      method: 'put',
      url: CART + data.ID,
      data: {
        QUANTITY: data.QUANTITY
      } 
    }).then((res) => {

    }).catch((err) => {
      console.log(err);
    });
  };

  // const submitCart = () => {
  //   axios({
  //     method: 'POST',
  //     url: SUBMITORDER,
  //     data: {
  //       'orderItems': cartList,
  //       PAYMENT_STATUS:"SUCCESS",
  //       PAYMENT_MODE: "ONLINE",
  //       ORDER_TYPE: 'OFFLINE',
  //       PLATFORM: 'STORE_VISIT',
  //     }
  //   }).then((res) => {
  //     setCList([]); setCart(false);
  //   });
  // };

  const actionBody = (data) => {
    return <div>
      <img src={Delete} style={{width: '25px', height:'20px'}} onClick={() => delRow(data)} />
      <img src={Check} style={{marginLeft: '25%', width: '25px', height:'20px'}} onClick={() => quantitySubmit(data)} /> 
    </div>
  };

  const actionHeader = (row) => {
    return <div style={{display: 'flex', flexDirection:'row'}}>
      <img 
        src={Wallet}
        style={{width: '25px', height: '20px'}}
        onClick={() => viewWallet(row)}
      />

      <img 
        src={Store}
        style={{width: '25px', height: '20px', marginLeft: '12px'}}
        onClick={() => viewCart(row)}
      />

      <img
        src={Order}
        alt='orders'
        style={{width: '25px', height: '20px', marginLeft: '12px'}}
        onClick={() => viewOrder(row)}
      />

      <img
        src={View}
        alt='View Image'
        style={{width: '25px', height: '20px', marginLeft: '12px'}}
        onClick={() => handleView(row)}
      />
    </div>
  };

  const handleView =(row)=>{
    axios({
      method: 'get',
      url: USER_COUPON_LIST + row.ID,
    }).then((res) => {
      setCpns(res.data.data.rows); 
      setUserView(true); setSObj(row);
    }).catch((err) => {
      console.log(err);
    });
  };

  const priceBody = (row) => {
    return <div>{new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(row.ORDER_AMOUNT)}</div>
  };

  const amountBody = (row) => {
    return <div>{new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(row.ORDER_TOTAL_AMOUNT)}</div>
  };

  const handleClose =()=>{
    setUserView(false);
  };

  const handleXData = () => {

  };

  const onPage = (e) => {
    setFirst(e.first); setPage(e.page); 
    setRows(e.rows); loadData(e.page, filter, e.rows);
  };

  return (
    <div  className="pt-4 m-3">      
      <div style={{ width: "100%" }} className='datatable'>
        <DataTable
          removableSort scrollable scrollHeight='64vh'
          size='medium' globalFilter={filter} header={tableHeader}
          loading={load} value={tableData} responsiveLayout="scroll"
        >
          <Column header='User Name' field='FIRST_NAME' style={{maxWidth: '25vw'}} sortable body={nameBody} />
          <Column header='Email' field='EMAIL' style={{maxWidth: '22vw'}} sortable />
          <Column header='Mobile' field='MOBILE_NUMBER' style={{maxWidth: '25vw', textAlign: 'center'}} sortable />
          <Column header='Actions' body={actionHeader} style={{maxWidth: '25vw'}} />
        </DataTable>

        <Paginator rows={rows} first={first} totalRecords={totalRecord} onPageChange={(e) => onPage(e)} template={pageTemplate} />
      </div>

      {/* User CART Pop-up */}
      <Dialog 
        visible={cart} maximizable onHide={() => setCart(false)}
        header={<div>{`Cart Details - ${selectName}`}</div>}
      >
        <DataTable 
          value={cartList} removableSort size='medium' scrollable
          loading={load} editMode="cell" className='mb-4'
        >
          <Column header='Product Name' className=' text-truncate' field='PRODUCT_NAME' />
          <Column header='Quantity' className=' text-truncate' field='QUANTITY' editor={(options) => quantityEditor(options)} onCellEditComplete={quantityChange} />
          <Column header='Price' className=' text-truncate' field='PRICE'/>
          <Column header='Extra Charges' className='text-truncate' field='extraCharges' />
          <Column header='Image' className=' text-truncate' body={imgHeader} />
          <Column header='Action' className=' text-truncate' body={actionBody}/>
        </DataTable>

        {/* <div className='status_button text-center mt-3'>
          <Button variant="contained" label='Submit' className='contained_success px-5' onClick={submitCart}> Submit </Button>
        </div> */}
      </Dialog>

      {/* User ORDER Pop-up */}
      <Dialog
        visible={order} maximizable onHide={() => setOrder(false)}        
        header={<div>{`Order Details - ${selectName}`}</div>}
      >
        <DataTable 
          value={orderList} removableSort
          loading={load} scrollable size='medium' 
        >
          <Column header='Order ID' className='text-truncate text_wrap_180' field='ORDER_TRACKER_ID' />
          <Column header='Total Amount' className='text-truncate w80' body={amountBody} />
          <Column header='Order Date' className='text-truncate w80' body={orderDate} />
          <Column header='Payment Status' className='text-truncate w80' body={statusHeader} />
          <Column header='Payment Mode' className='text-truncate w80' body={payModeHeader} />
          <Column header='Order Status' className='text-truncate w80' body={orderStatus} />
        </DataTable>
      </Dialog>

      {/* Product Details Pop-up */}
      <Dialog 
        visible={view} onHide={() => setView(false)} 
        header={<div>{`Product Details - ${product.PRODUCT_NAME}`}</div>}
      >
        <Container>
          <Row>
            <Col xs={3}>
              <Image src={IMAGE_DOMAIN + product.IMAGE_PATH1} width = '235px' height = '200px' style={{border:'15px'}} preview />
            </Col>

            <Col xs={8}>
              <Row>
                {/* Product Name */}
                <Col xs={3}>
                  <div>Product Name</div>
                </Col>

                <Col xs={1}>
                  :
                </Col>

                <Col xs={8}>
                  <div>{product.PRODUCT_NAME}</div>
                </Col>
                {/* Category */}
                <Col xs={11}>

                </Col>
                <Col xs={3}>
                  <div>Category</div>
                </Col>

                <Col xs={1}>
                  :
                </Col>

                <Col xs={8}>
                  <div>{product.CATEGORY}</div>
                </Col>
                {/* Price */}
                <Col xs={11} >

                </Col>
                <Col xs={3}>
                  <div>Price</div>
                </Col>

                <Col xs={1}>
                  :
                </Col>

                <Col xs={8}>
                  <div>{product.PRICE}</div>
                </Col>
                {/* Quantity */}
                <Col xs={11} >

                </Col>
                <Col xs={3}>
                  <div>Quantity</div>
                </Col>

                <Col xs={1}>
                  :
                </Col>

                <Col xs={8}>
                  <div>{product.QUANTITY}</div>
                </Col>
                {/* Description */}
                <Col xs={11} >

                </Col>
                <Col xs={3}>
                  <div>Description</div>
                </Col>
                <Col xs={1}>
                  :
                </Col>
                <Col xs={8}>
                  <div>{product.DESCRIPTION}</div>
                </Col>
                {/* Bar Code */}
                <Col xs={11} >

                </Col>
                <Col xs={3}>
                  <div>Bar Code</div>
                </Col>
                <Col xs={1}>
                  :
                </Col>
                <Col xs={8}>
                  <div>{product.BARCODE}</div>
                </Col>
              </Row>      
            </Col>
          </Row>
        </Container>
      </Dialog>

      {/* View popup*/}
      <Dialog 
        visible={userView} onHide={handleClose}
        header={<div> User Information </div>}
      >
        <Container>
          <Row>
            <Col xs={3}>
              {selObj.IMAGE_NAME && <Image src={IMAGE_DOMAIN + selObj.IMAGE_PATH + selObj.IMAGE_NAME} width='200px' height='200px' style={{ border: '15px' }} preview />}
            </Col>
            
            <Col xs={8}>
              <Row>
                {/* User name */}
                <Col xs={4}>
                  <div className='sub_heading_1'>User name</div>
                </Col>
                <Col xs={1}>
                  <b>:</b>
                </Col>
                <Col xs={7}>
                  <div>{selObj.FIRST_NAME +' '+ selObj.LAST_NAME}</div>
                </Col>

                {/*Mail */}
                <Col xs={4}>
                  <div className='sub_heading_1'>Mail</div>
                </Col>
                <Col xs={1} >
                  <b>:</b>
                </Col>
                <Col xs={7} >
                  <div>{selObj.EMAIL}</div>
                </Col>

                {/*Mobile */}
                <Col xs={4}>
                  <div className='sub_heading_1'>Mobile No</div>
                </Col>
                <Col xs={1}>
                <b>:</b>
                </Col>
                <Col xs={7}>
                <div>{selObj.MOBILE_NUMBER}</div>
                </Col>

                {/*Atum Coins */}
                <Col xs={4}>
                  <div className='sub_heading_1'>Atum Coins</div>
                </Col>
                <Col xs={1}>
                <b>:</b>
                </Col>
                <Col xs={7}>
                <div>{selObj.TOTAL_ATUM_COINS}</div>
                </Col>

                {/* Wallet Amount */}
                <Col xs={4}>
                  <div className='sub_heading_1'>Wallet Amount</div>
                </Col>
                <Col xs={1} >
                  <b>:</b>
                </Col>
                <Col xs={7} >
                  <div>{selObj.TOTAL_WALLET_AMOUNT}</div>
                </Col>

                  {/* Referral Code */}
                {selObj.REFERRAL_CODE && <Fragment>
                  <Col xs={4}>
                    <div className='sub_heading_1'>Referral Code</div>
                  </Col>
                  <Col xs={1} >
                    <b>:</b>
                  </Col>
                  <Col xs={7} >
                    <div>{selObj.REFERRAL_CODE}</div>
                  </Col>
                </Fragment>}

                {/* Is Student */}
                <Col xs={4}>
                  <div className='sub_heading_1'>Is Student</div>
                </Col>
                <Col xs={1} >
                  <b>:</b>
                </Col>
                <Col xs={7} >
                  <div>{selObj.IS_STUDENT===0 ? 'No' : 'Yes'}</div>
                </Col>

                {selObj.IS_STUDENT===1 && <Fragment>
                  <Col xs={4}>
                    <div className='sub_heading_1'>Organisation Name</div>
                  </Col>
                  <Col xs={1} >
                    <b>:</b>
                  </Col>
                  <Col xs={7} >
                    <div>{selObj.ORGANIZATION_NAME}</div>
                  </Col>

                  <Col xs={4}>
                    <div className='sub_heading_1'>Facebook ID</div>
                  </Col>
                  <Col xs={1} >
                    <b>:</b>
                  </Col>
                  <Col xs={7} >
                    <div>{selObj.FACEBOOK ? selObj.FACEBOOK : '-'}</div>
                  </Col>

                  <Col xs={4}>
                    <div className='sub_heading_1'>Twitter ID</div>
                  </Col>
                  <Col xs={1} >
                    <b>:</b>
                  </Col>
                  <Col xs={7} >
                    <div>{selObj.TWITTER ? selObj.TWITTER : '-'}</div>
                  </Col>

                  <Col xs={4}>
                    <div className='sub_heading_1'>Instagram ID</div>
                  </Col>
                  <Col xs={1} >
                    <b>:</b>
                  </Col>
                  <Col xs={7} >
                    <div>{selObj.INSTAGRAM ? selObj.INSTAGRAM : '-'}</div>
                  </Col>

                  <Col xs={4}>
                    <div className='sub_heading_1'>Location</div>
                  </Col>
                  <Col xs={1}>
                    <b>:</b>
                  </Col>
                  <Col xs={7}>
                    <div>{selObj.LOCATION ? selObj.LOCATION : '-'}</div>
                  </Col>

                  <Col xs={4}>
                    <div className='sub_heading_1'>Identity Proof</div>
                  </Col>
                  <Col xs={1} >
                    <b>:</b>
                  </Col>
                  <Col xs={7} >
                    <Image src={IMAGE_DOMAIN + selObj.IDENTITY_PROOF_IMAGE} width='200px' height='200px' style={{ border: '15px' }} preview />
                  </Col>
                </Fragment>}
              </Row>
            </Col>
          </Row>
        </Container>

        <DataTable value={coupons} responsiveLayout="scroll">
          <Column field="COUPON_NAME" className=' text-truncate' header="Coupon Name" />
          <Column field="COUPON_CODE" className=' text-truncate' header="Coupon Code" />
          <Column field="MIN_ORDER_VALUE" className=' text-truncate' header="Min Order Value" />
          <Column field="MAX_DISCOUNT" className=' text-truncate' header="Max Discount" />
        </DataTable>
      </Dialog>
 
      {/* Excel Download */}
      <Dialog
        visible={edata} onHide={() => setEData(false)}
        style={{width: '35vw', height: 'auto'}}
        header={<div style={{textAlign: 'center'}}>Excel Data - Orders</div>}
        footer={<div style={{textAlign: 'center'}}>
          <button style={{backgroundColor: '#338D7e', color: '#fff', padding: '1%', border: '0px solid', paddingLeft: '5%', paddingRight: '5%', borderRadius: '5px'}} onClick={handleXData}>Submit</button>
        </div>}
      >
        <Container>
          <Row>
            <Col xs={6}>
              <div className='field'>
                <Calendar
                  value={startD} placeholder='Start Date'
                  onChange={(e) => setStartD(e.value)} 
                  dateFormat="dd-mm-yy" showIcon style={{width: '98%', height: '35px'}}
                />    
              </div>
            </Col>

            <Col xs={6}>
              <div className='field'>
                <Calendar
                  value={end} placeholder='End Date'
                  onChange={(e) => setEnd(e.value)} 
                  dateFormat="dd-mm-yy" showIcon style={{width: '98%', height: '35px', marginLeft: '1%'}}
                />    
              </div>
            </Col>
          </Row>
        </Container>
      </Dialog>

      {/* Wallet Transactions */}
      <Dialog
        visible={wallet} maximizable onHide={() => setWallet(false)}
        header={<div>{`Wallet Transactions - ${selectName}`}</div>}
      >
        <DataTable 
          value={walletList} removableSort size='medium' loading={load} 
          scrollHeight='70vh' responsiveLayout="scroll" style={{ marginTop: '-5px' }}
        >
          <Column header='Order ID' className='text-truncate' field='ORDER_ID' style={{width: '30%'}} />
          <Column header='Transaction ID' className='text-truncate' field='TRANSACTION_ID' style={{width: '30%'}} />
          <Column header='Total Amount' body={priceBody} style={{width: '10%', textAlign: 'center'}} />
          <Column header='Gate Way' field='GATEWAY_NAME' style={{width: '5%'}} />
          <Column header='Payment Type' field='PAYMENT_TYPE' style={{width: '7%'}} />
          <Column header='Order Date' body={orderDate} style={{width: '18%'}} />
        </DataTable>
      </Dialog>
    </div>
  );
};

export default EndUsersList;