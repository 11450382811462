import React, { useState, useEffect } from 'react';

import axios from 'axios';

import { Form, Field } from 'react-final-form';

import Add from '../icons/add.svg';
import Edit from '../icons/edit.svg';
import View from '../icons/view.svg';
import Delete from '../icons/delete.svg';
import Banner from '../Sidebar/icons/banners.svg';

import { Container, Row, Col, Button } from 'reactstrap'; 

import { InputTextarea } from 'primereact/inputtextarea';
import { MultiSelect } from 'primereact/multiselect';
import { Paginator } from 'primereact/paginator';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { classNames } from 'primereact/utils';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Image } from 'primereact/image';

import { 
  BANNERS_LIST, GLOBAL_DOMAIN, EDIT_BANNER, 
  ADD_BANNERS, UPLOAD_IMAGE_BANNER, BANNER_TYPE_LIST, 
  BANNER_CATEGORY, BANNER_PRODUCTS, OFFER_BANNER_TYPE_LIST,
  BANNER_PLATFORM_LIST, IMAGE_DOMAIN,
} from '../../config/config.js';

let initialValue = {
  name: '', description: '', publish: false,
  sequence: '', bannerTypeId: 0, offerID: 0,
  discountType: '', discountValue: 0, platformId: 0, 
  offerCat: '', offerProd: '', start: '', end: ''
};

const BannersList = () => {
  const [tableData, setTableData] = useState([]);
  const [bannerTypeList, setBTList] = useState([]);
  const [categoryList, setCList] = useState([]);
  const [productList, setPList] = useState([]);
  const [offerBannerList, setOBList] = useState([]);
  const [platformBannerList, setPBList] = useState([]);

  const [bOVal, setBOVal] = useState(null);
  const [open, setOpen] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [popup, setPopup] = useState(false);
  const [newId, setNewId] = useState('');
  const [bTypeName, setBTName] = useState('');
  const [bValue, setBValue] = useState('');
  const [filter, setFilter] = useState('');
  const [offerType, setOType] = useState('');
  const [startDate, setSDate] = useState(null);
  const [endDate, setEDate] = useState(null);
  const [bannerPType, setBPType] = useState('');

  const [load, setLoad] = useState(false);
  const [errPop, setEPop] = useState(false);
  const [errMsg, setEMsg] = useState('');

  const [totalRecord, setTRecords] = useState(0);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [page, setPage] = useState(0);

  const [btype, setBType] = useState('');
  const [imgs, setImgs] = useState(null);
  const [imgErr, setIErr] = useState(false);

  let month = [
    'Jan', 'Feb', 'Mar', 
    'Apr', 'May', 'Jun', 
    'Jul', 'Aug', 'Sept', 
    'Oct', 'Nov', 'Dec'
  ], mainId = JSON.parse(sessionStorage.getItem('id'));  

  const handleView = (row) => {
    setPopup(true); setBValue(row);
    setBType(row.BANNER_NAME);
  };

  const handleEdit = (row) => {
    initialValue = {
      name: row.BANNER_NAME, 
      description: row.DESCRIPTION, 
      sequence: row.SEQUENCE,
      publish: row.IS_PUBLISHED,
      bannerTypeId: row.BANNER_TYPE_ID, 
      offerID: row.OFFER_TYPE_ID,
      discountType: row.DISCOUNT_TYPE,
      discountValue: row.DISCOUNT_VALUE,
      platformId: row.PLATFORM_ID,
      offerCat: JSON.parse(row.OFFER_TYPE_VALUES),
      offerProd: JSON.parse(row.OFFER_TYPE_VALUES),
      start: new Date(row.START_DATE),
      end: new Date(row.START_DATE),
    };

    setNewId(row.ID); setOpen(true); setBType(row.BANNER_NAME);
    setImgs(row.WEB_IMAGE_PATH); setOType(row.OFFER_TYPE); handlePBanner( row.PLATFORM_ID);
    setBOVal(JSON.parse(row.OFFER_TYPE_VALUES)); setBTName(row.BANNER_TYPE);
    setSDate(new Date(row.START_DATE)); setEDate(new Date(row.END_DATE));
  };

  const handleClickOpen = (row) => {
    setNewId(row.ID); setOpenPopup(true);
  };

  const handleCloseDelete = () => {
    setOpenPopup(false); setNewId('');
  };

  const handleClose = () => {
    setBType(''); setNewId(''); setOpen(false); 
    setBOVal(null); setOType(''); setBTName('');
    setImgs(null); 

    initialValue = {
      name: '', description: '', publish: false,
      sequence: '', bannerTypeId: 0, offerID: 0,
      discountType: '', discountValue: 0, platformId: 0, 
      offerCat: '', offerProd: '', start: '', end: ''
    };
  };

  const handleDelete = () => {
    axios({
      method: 'delete',
      url: EDIT_BANNER + newId,
    }).then((res) => {
      loadData(page, filter, rows); setNewId(''); 
      setOpenPopup(false); setBType(''); 
    }).catch((err) => {
      console.log(err);
    });
  };

  const getBannerType = () => {
    axios({
      method: 'get',
      url: BANNER_TYPE_LIST,
    }).then((res) => {
      setBTList(res.data);
    }).catch((err) => {
      console.log(err);
    });
  };

  const getCategory = () => {
    axios({
      method: 'get',
      url: BANNER_CATEGORY,
    }).then((res) => {
      setCList(res.data);
    }).catch((err) => {
      console.log(err);
    });
  };

  const getProducts = () => {
    axios({
      method: 'get',
      url: BANNER_PRODUCTS,
    }).then((res) => {
      setPList(res.data.rows);
    }).catch((err) => {
      console.log(err);
    });
  };

  const getOfferBanners = () => {
    axios({
      method: 'get',
      url: OFFER_BANNER_TYPE_LIST,
    }).then((res) => {
      setOBList(res.data);
    }).catch((err) => {
      console.log(err);
    })
  };

  const getBannerPlatform = () => {
    axios({
      method: 'get',
      url: BANNER_PLATFORM_LIST,
    }).then((res) => {
      setPBList(res.data);
    }).catch((err) => {
      console.log(err);
    })
  };

  useEffect(() => {
    loadData(page, filter, rows); getBannerType();
    getCategory(); getBannerPlatform();
    getOfferBanners(); getProducts();
  }, [(tableData.length===0)]);

  const loadData = (page, filter, row) => {
    setLoad(true);

    axios({
      method: 'get',
      url: BANNERS_LIST+'&limit='+row+'&skip='+(page+1)+(filter!=='' ? '&search='+filter : '&search='),
    }).then((res) => {
      if (res.data) {
        setTableData(res.data.rows);
        setTRecords(res.data.total_count);
      } else {
        setTRecords(0); 
      }
    }).catch((err) => {
      console.log(err);
    });

    setLoad(false); 
  };

  const tableHeader = () => {
    return <div className='DataTable'>
      <div className='m-2'>
        <h3>Banner List</h3>
      </div>
      
      <div className='table-header-action'>
        <InputText className='m-2' value={filter} onChange={(e) => setFilter(e.target.value)} placeholder='Search...' />

        <Button className="table-button m-2" onClick={() => {
          setBType(''); setOpen(true); 
          setNewId(''); setImgs(null);   
          setBOVal(null); setOType(''); 
          setBTName('');
         
          initialValue = {
            name: '', description: '', publish: false,
            sequence: '', bannerTypeId: 0, offerID: 0,
            discountType: '', discountValue: 0, platformId: 0, 
            offerCat: '', offerProd: '', start: '', end: ''
          };
        }}>
          <img src={Add} style={{ width: '25px', height: '20px', marginRight: '0.5%' }} /> Add
        </Button>
      </div>
      
    </div>
  };

  const imgHeader = (row) => {
    return row.IMAGE_PATH!==null ? <Image src={IMAGE_DOMAIN + row.WEB_IMAGE_PATH} preview height='35px' /> : ""
  };

  const actionHeader = (rowData) => {
    return <div style={{display: 'flex', flexDirection:'row'}}>
      <img
        src={View} 
        alt='View Image'
        style={{width: '25px', height: '20px'}}
        onClick={() => handleView(rowData)}
      />
      
      <img
        src={Edit}
        alt='Edit Image'
        onClick={() => handleEdit(rowData)}
        style={{marginLeft: '15px',width: '25px', height: '20px'}}
      />
      
      <img
        src={Delete}
        alt='Delete Image'
        onClick={() => handleClickOpen(rowData)}
        style={{marginLeft: '15px',width: '25px', height: '20px'}}
      />
    </div> 
  };

  const template2 = {
    layout: 'RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        { label: 10, value: 10 },
        { label: 25, value: 25 },
        { label: 50, value: 50 },
        { label: 100, value: 100 },
      ];

      return (
        <React.Fragment>
          <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
        </React.Fragment>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '150px', textAlign: 'center' }}>
          {options.first} - {options.last} of {options.totalRecords}
        </span>
      )
    }
  };

  const onPage = (e) => {
    setFirst(e.first); setPage(e.page); 
    setRows(e.rows); loadData(e.page, filter, e.rows);
  };

  const descHeader = (rowData) => {
    const limit = 15;

    if(rowData.DESCRIPTION.length <= limit){
      return <div>{rowData.DESCRIPTION}</div>
    }else{
      return <div>{rowData.DESCRIPTION.slice(0, limit) + '...'}</div>
    }
  };

  const submitFiles = (formData, form, id) => {
    if(((typeof(imgs) !=='string') && URL.createObjectURL(imgs))){ 
      if(bannerPType==='WEBSITE'){
        formData.append('issueId', id);
        formData.append('bannerImage', imgs);  
      }else{
        formData.append('issueId', id);  
        formData.append('bannerImage', imgs);
      }
      
      axios.put(
        UPLOAD_IMAGE_BANNER + id, 
        formData, 
        { headers: {'Content-Type': 'multipart/form-data'} }
      ).then((res) => {
        setOpen(false); form.restart();
        loadData(page, filter, rows);
      }).catch((err) => {
        switch(err.response.status){
          case 500:
          case 501:
          case 502:
          case 503:
          case 504:
            setEPop(true);
            break;
          default: 
            setEMsg('');
            break;
        };
      });
    } else {
      setOpen(false); form.restart();
      loadData(page, filter, rows);
    };
  };

  const addBanner = (obj, form) => {
    const formData = new FormData();

    axios.post(
      ADD_BANNERS, 
      obj,
    ).then((res) => {
      if(res.data.data.ID){
        form.restart();
        submitFiles(formData, form, res.data.data.ID);
      };
    }).catch((err) => {
      console.log(err);
    });
  };

  const editBanner = (obj, form) => {
    const formData = new FormData();

    axios.put(
      EDIT_BANNER + newId,
      obj,
    ).then((res) => {
      form.restart();
      submitFiles(formData, form, newId);
    }).catch((err) => {
      console.log(err);
    });
  };

  const handleOnSubmit = (data, form) => {
    let obj = {
      BANNER_NAME: data.name,
      DESCRIPTION: data.description,
      SEQUENCE: parseInt(data.sequence),
      BANNER_TYPE_ID: data.bannerTypeId,
      BANNER_TYPE: bTypeName,
      OFFER_TYPE_ID: data.offerID,
      OFFER_TYPE: offerType,
      OFFER_TYPE_VALUES: JSON.stringify(bOVal),
      IS_PUBLISHED: data.publish,
      DISCOUNT_TYPE: data.discountType,
      DISCOUNT_VALUE: data.discountValue,
      PLATFORM_ID: data.platformId,
      PLATFORM: bannerPType,
      START_DATE: startDate,
      END_DATE: endDate,
      UPDATED_BY: mainId,
    };

    if(imgs!==null){
      if(newId===''){
        obj.CREATED_BY = mainId;
  
        addBanner(obj, form);
      } else {
        editBanner(obj, form);
      }
    } else {
      setIErr(true);
    } 
  };

  const handleBannerTypeName = (id) => {
    let name = bannerTypeList.filter((it) => id === it.ID);

    setBTName(name[0].LOOKUP_NAME);
  };

  const handleOfferBanner = (id) => {
    let arr = offerBannerList.filter((it) => id === it.ID);

    setOType(arr[0].LOOKUP_NAME);
  };

  const handlePBanner = (id) => {
    let arr = platformBannerList.filter((it) => id === it.ID);
    setBPType(arr[0].LOOKUP_NAME);
  };

  const validate = (data) => {
    let errors = {};

    if (!data.name) {
      errors.name = 'Name is required.';
    }

    if (!data.sequence) {
      errors.sequence = 'Sequence is required.';
    }

    if (!data.platformId) {
      errors.platformId = 'Platform Type is required.'
    }

    if(!data.description){
      errors.description = 'Description is required.';
    }

    if(!data.bannerTypeId){
      errors.bannerTypeId = 'Banner Type is required.';
    }

    if(bTypeName==='OFFER_BANNER' && !data.discountType){
      errors.discountType = 'Offer Type is required.';
    }

    if(bTypeName==='OFFER_BANNER' && !data.discountValue){
      errors.discountValue = 'Offer Value is required.';
    }

    if(bTypeName==='OFFER_BANNER' && !data.offerID){
      errors.offerID = 'Offer On is required.';
    }

    if(bTypeName==='OFFER_BANNER' && offerType==='CATEGORY' && !data.offerCat){
      errors.offerCat = 'Category is required.';
    }

    if(bTypeName==='OFFER_BANNER' && offerType==='PRODUCTS' && !data.offerProd){
      errors.offerProd = 'Product is required.';
    }

    if(bTypeName==='OFFER_BANNER' && !data.start){
      errors.start = 'Start Date is required.';
    }

    if(bTypeName==='OFFER_BANNER' && !data.end){
      errors.end = 'End Date is required.';
    }

    return errors;
  };

  const handleDate = (dt) => {
    let date = new Date(dt);

    return date.getDate() + '/' + month[date.getMonth()] + '/' + date.getFullYear();
  };

  const platformBody = (row) => {
    if(row.PLATFORM_ID && platformBannerList.length){  
      let arr = platformBannerList.filter((itm) => itm.ID === row.PLATFORM_ID);

      return arr ? arr[0].LOOKUP_DISPLAY_NAME : '';
    }else{
      return '-';
    };
  };

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);

  const bannerTypeBody = (row) => {
    if(row.BANNER_TYPE){
      let arr = row.BANNER_TYPE.split('_');

      return arr[0][0].toUpperCase()+arr[0].slice(1).toLowerCase() + ' ' + arr[1][0].toUpperCase()+arr[1].slice(1).toLowerCase();
    }else{
      return '-'
    }    
  };
 
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  // const handleOfferValue = (vals) => {
  //   if(vals!==null&&vals!=='null'){
  //     let val2 = JSON.parse(vals), rvals = '';

  //     for(let r=0; r<val2.length; r++){
  //       if(bValue.OFFER_TYPE==='CATEGORY'){
  //         for(let i=0; i<categoryList.length; i++){
  //           if(categoryList[i].ID === val2[r]){
  //             rvals += categoryList[i].NAME+', '; 
  //           }
  //         }
  //       }else{
  //         for(let i=0; i<productList.length; i++){
  //           if(productList[i].ID === val2[r]){
  //             rvals += productList[i].PRODUCT_NAME+', ';
  //           }
  //         }
  //       };
  //     };
  
  //     return rvals;
  //   }else{
  //     return '-';
  //   }
  // };

  return (
    <div className='pt-4 m-3'>
      <div style={{ width: "100%" }} className='datatable'>
        <DataTable 
          value={tableData} removableSort size='medium' globalFilter={filter} header={tableHeader}
          loading={load} scrollable scrollHeight="500px" responsiveLayout="scroll" className="mt-6"
        >
          <Column header='Banner Name' field='BANNER_NAME' className='text-truncate' sortable />
          <Column header='Sequence' field='SEQUENCE' className='text-truncate' sortable />
          <Column header='Banner Type' field='BANNER_TYPE' className='text-truncate' sortable body={bannerTypeBody} />
          <Column header='Banner Image' className='text-truncate' body={imgHeader} />
          <Column header='Platform Type' field='PLATFORM_ID' className='text-truncate' body={platformBody} sortable />
          <Column header='Description' field='DESCRIPTION' className='text-truncate' sortable body={descHeader} />
          <Column header='Action' body={actionHeader} />
        </DataTable>

        <Paginator rows={rows} first={first} totalRecords={totalRecord} onPageChange={(e) => onPage(e)} template={template2} />
      </div>

      {/* View Banner Pop-up */}
      <Dialog 
        visible={popup} onHide={() => setPopup(false)} style={{width: '75vw'}}
        header={<div style={{textAlign: 'center'}}>{`Banner Details - ${btype}`}</div>}
      >
        <Container>
          <Row>
            <Col xs={12} sm={4}>
              {bValue.WEB_IMAGE_PATH && <div>
                <Image src={IMAGE_DOMAIN + bValue.IMAGE_PATH} width='200px' height='90px' style={{border:'15px'}} preview />
              </div>}
            </Col>

            <Col xs={12} sm={8}> 
              <Row>
                <Col xs={4}>Banner Name</Col>
                <Col xs={1}>:</Col>
                <Col xs={5}>{bValue.BANNER_NAME}</Col>
              </Row>

              <Row style={{ marginTop: '2%' }}>
                <Col xs={4}>Sequence</Col>
                <Col xs={1}>:</Col>
                <Col xs={5}>{bValue.SEQUENCE}</Col>
              </Row>

              <Row style={{ marginTop: '2%' }}>
                <Col xs={4}>Platform Type</Col>
                <Col xs={1}>:</Col>
                <Col xs={5}>{bValue && platformBody(bValue)}</Col>
              </Row>

              <Row style={{ marginTop: '2%' }}>
                <Col xs={4}>Banner Type</Col>
                <Col xs={1}>:</Col>
                <Col xs={5}>{bValue && bannerTypeBody(bValue)}</Col>
              </Row>

              {bValue.BANNER_TYPE==='OFFER_BANNER' && <>
                <Row style={{ marginTop: '2%' }}>
                  <Col xs={4}>Discount Type</Col>
                  <Col xs={1}>:</Col>
                  <Col xs={5}>{(bValue.DISCOUNT_TYPE)}</Col>
                </Row>

                <Row style={{ marginTop: '2%' }}>
                  <Col xs={4}>Discount Value</Col>
                  <Col xs={1}>:</Col>
                  <Col xs={5}>{bValue.DISCOUNT_VALUE}</Col>
                </Row>

                <Row>
                  <Col xs={4}>Start Date</Col>
                  <Col xs={1}>:</Col>
                  <Col xs={5}>{handleDate(bValue.START_DATE)}</Col>
                </Row>

                <Row>
                <Col xs={4}>End Date</Col>
                  <Col xs={1}>:</Col>
                  <Col xs={5}>{handleDate(bValue.END_DATE)}</Col>
                </Row>

                <Row style={{ marginTop: '2%' }}>
                  <Col xs={4}>Offer Type</Col>
                  <Col xs={1}>:</Col>
                  <Col xs={5}>{bValue.OFFER_TYPE}</Col>
                </Row>
              </>}

              <Row style={{ marginTop: '2%' }}>
                <Col xs={4}>Description</Col>
                <Col xs={1}>:</Col>
                <Col xs={5}>{bValue.DESCRIPTION}</Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Dialog>

      {/* Add / Edit Banner Pop-up */}
      <Dialog 
        visible={open} onHide={handleClose}
        style={{width: '75vw', height: 'auto'}}
        header={<div style={{textAlign: 'center'}}>
          {newId === '' ? 'Add Banner' : `Edit Banner ${btype}`}
        </div>} 
      >
        <Container>
          <Form 
            onSubmit={handleOnSubmit} 
            initialValues={initialValue}
            validate={validate}
            render = {({ handleSubmit }) =>
              <form onSubmit={handleSubmit}>
                <Row>
                  <Col xs={4}>
                    <div style={{ textAlign: 'center', width:'75%', marginLeft:'2%', border:'1px dashed #ccc', borderRadius: '15px' }}>
                      {imgs === null ? <label htmlFor='uploadi' style={{ margin:'0.5%' }}>
                        <img src={Banner} style={{ width: '55px', height:'65px', padding:'0.5%' }} />
                        <div style={(imgErr===true&&imgs===null) ? {color: 'red'} : {}}>Upload Banner Image *</div>
                      </label> : 
                      <>
                        <div onClick={() => setImgs(null)} style={{
                          position:'absolute', left:'23.5%', width:'25px', height:'25px', marginTop:'0.5%', 
                          border:'1px solid red', background:'red', borderRadius:'25px',
                          color: 'whitesmoke', fontSize:'15px', fontWeight:'600', cursor: 'pointer',
                        }}>x</div>

                        {btype==='' 
                          ? <img src={URL.createObjectURL(imgs)} style={{width: '220px', height:'20vh', borderRadius:'15px'}} />
                          : imgs===null ? '' : imgs.name ? <img src={URL.createObjectURL(imgs)} style={{width: 'auto', height:'20vh', borderRadius:'15px'}} />
                          : <img src={IMAGE_DOMAIN + imgs} style={{width: '100%', height:'20vh', borderRadius:'15px'}} />
                        }
                      </>}

                      <input id='uploadi' type='file' style={{display: 'none'}} onChange={(e) => {e.target.files ? setImgs(e.target.files[0]) : setImgs('')}} />
                      {(imgErr===true&&imgs===null) && <div>
                        <small style={{color: 'red'}}>Image is required</small>
                      </div>}
                    </div>
                  </Col>

                  <Col xs={8}> 
                    <Row>
                      <Col xs={12} sm={5}>
                        <Field name='name' render={({ input, meta }) => (
                          <>
                            <label className={classNames({ 'p-error': isFormFieldValid(meta) })}>Banner Name *</label>
                            <InputText 
                              {...input} style={{ width: '100%' }} 
                              className={classNames({ 'p-invalid': isFormFieldValid(meta) })} 
                            />
                            {getFormErrorMessage(meta)}
                          </>
                        )} />
                      </Col>

                      <Col xs={12} sm={2}>
                        <Field name='sequence' render={({ input, meta }) => (
                          <>
                            <label className={classNames({ 'p-error': isFormFieldValid(meta) })}>Sequence *</label>
                            <InputText 
                              {...input} style={{ width: '100%' }} 
                              className={classNames({ 'p-invalid': isFormFieldValid(meta) })} 
                            />
                            {getFormErrorMessage(meta)}
                          </>
                        )} />
                      </Col>

                      <Col xs={12} sm={5}>
                        <Field name='bannerTypeId' render={({ input, meta }) => (
                          <>
                            <label className={classNames({ 'p-error': isFormFieldValid(meta) })}>Banner Type *</label>
                            <Dropdown 
                              {...input} style={{ width: '100%' }} options={bannerTypeList}
                              className={classNames({ 'p-invalid': isFormFieldValid(meta) })} 
                              optionLabel='LOOKUP_DISPLAY_NAME' optionValue='ID' 
                              onChange={(e) => {input.onChange(e.value); handleBannerTypeName(e.value)}}
                            />
                            {getFormErrorMessage(meta)}
                          </>
                        )} />
                      </Col>

                      <Col xs={12} sm={bTypeName!=='OFFER_BANNER' ? 5 : 4}>
                        <Field name='platformId' render={({ input, meta }) => (
                          <>
                            <label className={classNames({ 'p-error': isFormFieldValid(meta) })}>Platform Type *</label>
                            <Dropdown
                              {...input} options={platformBannerList} style={{width: '100%'}} 
                              className={classNames({ 'p-invalid': isFormFieldValid(meta) })} 
                              optionLabel='LOOKUP_DISPLAY_NAME' optionValue='ID'
                              onChange={(e) => { input.onChange(e.value); handlePBanner(e.value) }}
                            />
                            {getFormErrorMessage(meta)}
                          </>
                        )} />
                      </Col>

                      {(bTypeName!=='' && bTypeName==='OFFER_BANNER') && <>
                        <Col xs={12} sm={4}>
                          <Field name='discountType' render={({ input, meta }) => (
                            <>
                              <label className={classNames({ 'p-error': isFormFieldValid(meta) })}>Offer Type *</label>
                              <Dropdown 
                                {...input} style={{ width: '100%' }} options={[
                                  {'Name': 'Percentage', 'Value': 'PERCENTAGE'}, 
                                  {'Name': 'Amount', 'Value': 'AMOUNT'}
                                ]} optionLabel='Name' optionValue='Value'
                                className={classNames({ 'p-invalid': isFormFieldValid(meta) })} 
                              />
                              {getFormErrorMessage(meta)}
                            </>
                          )} />
                        </Col>

                        <Col xs={12} sm={4}>
                          <Field name='discountValue' render={({ input, meta }) => (
                            <>
                              <label className={classNames({ 'p-error': isFormFieldValid(meta) })}>Offer Value *</label>
                              <InputText 
                                {...input} style={{ width: '100%' }} 
                                className={classNames({ 'p-invalid': isFormFieldValid(meta) })} 
                                onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                              />
                              {getFormErrorMessage(meta)}
                            </>
                          )} />
                        </Col>

                        <Col xs={12} sm={(offerType!=='') ? 3 : 4}>
                          <Field name='offerID' render={({ input, meta }) => (
                            <>
                              <label className={classNames({ 'p-error': isFormFieldValid(meta) })}>Offer On *</label>
                              <Dropdown
                                {...input} style={{width: '100%'}} options={offerBannerList} 
                                optionLabel='LOOKUP_DISPLAY_NAME' optionValue='ID'
                                onChange={(e) => { input.onChange(e.value); handleOfferBanner(e.value) }}
                                className={classNames({ 'p-invalid': isFormFieldValid(meta) })} 
                              />
                              {getFormErrorMessage(meta)}
                            </>
                          )} />
                        </Col>

                        {(offerType!=='' && offerType==='CATEGORY') && <Col xs={12} sm={3}>
                          <Field name='offerCat' render={({ input, meta }) => (
                            <>
                              <label className={classNames({ 'p-error': isFormFieldValid(meta) })}>Category *</label>
                              <MultiSelect 
                                {...input} style={{ width: '100%' }} options={categoryList} 
                                optionLabel='NAME' optionValue='ID'
                                onChange={(e) => { input.onChange(e.value); setBOVal(e.value) }}
                                className={classNames({ 'p-invalid': isFormFieldValid(meta) })} 
                              />
                              {getFormErrorMessage(meta)}
                            </>
                          )} />
                        </Col>}

                        {(offerType!=='' && offerType==='PRODUCTS') && <Col xs={12} sm={3}>
                          <Field name='offerProd' render={({ input, meta }) => (
                            <>
                              <label className={classNames({ 'p-error': isFormFieldValid(meta) })}>Product *</label>
                              <MultiSelect 
                                {...input} style={{ width: '100%' }} options={productList} 
                                optionLabel='NAME' optionValue='ID'
                                onChange={(e) => { input.onChange(e.value); setBOVal(e.value) }}
                                className={classNames({ 'p-invalid': isFormFieldValid(meta) })} 
                              />
                              {getFormErrorMessage(meta)}
                            </>
                          )} />
                        </Col>}
                      
                        <Col xs={12} sm={(offerType!=='') ? 3 : 4}>
                          <Field name='start' type='date' render={({input, meta }) => (
                            <>
                              <label className={classNames({ 'p-error': isFormFieldValid(meta) })}>Start Date *</label>
                              <Calendar 
                                {...input} dateFormat="dd/mm/yy" showIcon style={{width: '100%'}}
                                onChange={(e) => { input.onChange(e.value); setSDate(e.value) }}
                                className={classNames({ 'p-invalid': isFormFieldValid(meta) })} 
                              />
                              {getFormErrorMessage(meta)}
                            </>
                          )} />
                        </Col>

                        <Col xs={12} sm={(offerType!=='') ? 3 : 4}>
                          <Field name='end' type='date' render={({input, meta }) => (
                            <>
                              <label className={classNames({ 'p-error': isFormFieldValid(meta) })}>End Date *</label>
                              <Calendar 
                                {...input} dateFormat="dd/mm/yy" showIcon style={{width: '100%'}}
                                onChange={(e) => { input.onChange(e.value); setSDate(e.value) }}
                                className={classNames({ 'p-invalid': isFormFieldValid(meta) })} 
                              />
                              {getFormErrorMessage(meta)}
                            </>
                          )} />
                        </Col>
                      </>}

                      <Col xs={12} style={{ marginTop: '1%' }}>
                        <Field name='description' render={({ input, meta }) => (
                          <>
                            <label className={classNames({ 'p-error': isFormFieldValid(meta) })}>Description *</label>
                            <InputTextarea 
                              {...input} style={{ width: '100%' }} 
                              rows={4} cols={10} autoResize 
                              className={classNames({ 'p-invalid': isFormFieldValid(meta) })} 
                            />
                            {getFormErrorMessage(meta)}
                          </>
                        )} />
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={12} className='status_button text-center'>
                    <Button 
                      color='primary' className='contained_primary mx-2' onClick={handleSubmit}
                      style={{ width: '15vw', marginRight: '3%', marginTop: '3%' }}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </form>
            } 
          />
        </Container>
      </Dialog>

      {/* Delete Banner Pop-up */}
      <Dialog
        visible={openPopup} onHide={handleCloseDelete} style={{ width: '35vw', height: 'auto' }}
        header={<div style={{textAlign: 'center'}}>You want to delete the record ?</div>}
      >
        <div style={{textAlign: 'center'}}>
          <Button onClick={handleCloseDelete} style={{ marginRight: '3%' }}>No</Button>
          <Button onClick={handleDelete}>Yes</Button>
        </div>
      </Dialog>
    </div>
  );
};

export default BannersList;