import React, { useState, useEffect } from 'react';

import { Container, Row, Col } from 'reactstrap';

import axios from 'axios';

import { useHistory, useParams } from 'react-router-dom';

import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { ScrollPanel } from 'primereact/scrollpanel';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button'; 
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Image } from 'primereact/image';

import Add from '../icons/add.svg';
import Edit from '../icons/edit.svg';
import Video from '../icons/video.svg';
import Delete from '../icons/delete.svg';
import Back from '../Sidebar/icons/back.svg';
import Banner from '../Sidebar/icons/banners.svg';
import { 
  ADD_STORE_CATEGORIES_LIST, STORE_LIST, PRODUCTS, UPLOAD_MEDIA,
  EDIT_PRODUCT, MEASUREMENT_LIST, PRODUCT_FAMILY_LIST, IMAGE_DOMAIN,
  COLOR_LIST, PRODUCT_FAMILY_DETAILS, MEASUREMENT_VALUE_LIST,
  DELETE_PRODUCT_IMAGE, UPDATE_PRODUCT_IMAGE, ADD_PRODUCT_FAMILY,
} from '../../config/config.js';
import EditIcon from '../icons/edit.svg';
import DeleteIcon from '../icons/delete.svg';
import './Products.css';

export default function AddProduct () {
  const [name, setName] = useState('');
  const [rowID, setRID] = useState('');
  const [openStore, setOStore] = useState(false);
  const [family, setFamily] = useState(false);
  const [isFamily, setIFam] = useState(false);
  const [fname, setFName] = useState(''); 
  const [err, setErr] = useState(false);

  const [imgs1, setImgs1] = useState(null);
  const [imgs2, setImgs2] = useState(null);
  const [imgs3, setImgs3] = useState(null);
  const [video, setVideo] = useState(null);

  const [pName, setPName] = useState('');
  const [pFId, setPFId] = useState(0);
  const [pFName, setPFName] = useState('');
  const [pfErr, setPFErr] = useState('');
  const [category, setCategory] = useState('');
  const [price, setPrice] = useState('');
  const [dType, setDType] = useState('');
  const [dVal, setDVal] = useState('');
  const [coins, setCoins] = useState('');
  const [qnty, setQnty] = useState(0);
  const [bCode, setBCode] = useState('');
  const [dflt, setDflt] = useState(false);
  const [mType, setMType] = useState(0);
  const [mName, setMName] = useState('');
  const [mVal, setMVal] = useState(0);
  const [mDName, setMDName] = useState('');
  const [clr, setClr] = useState(false);
  const [measure, setMeasure] = useState(false);
  const [cVal, setCVal] = useState('');
  const [desc, setDesc] = useState('');
  const [sustain, setSustain] = useState('');
  const [inventory, setIList] = useState([]);
  const [delStore, setDStore] = useState([]);

  const [disItms, setDItms] = useState(false);

  const [edit, setEdit] = useState(false);
  const [editStore, setEStore] = useState(false);
  const [cmsr, setCmsr] = useState(true);
  const [store, setStore] = useState('');
  const [storeIdx, setIdx] = useState('');
  const [sQuantity, setSQuantity] = useState(0);

  const [obj, setObj] = useState('');
  const [del, setDel] = useState(false);

  const [defts, setDEfts] = useState('');
  const [selObj, setSObj] = useState('');
  const [pDStore, setPDStore] = useState([]);
  const [storeList, setSList] = useState([]);
  const [popDeflt, setPDflt] = useState(false);
  const [colorList, setClrList] = useState([]);
  const [measureList, setMList] = useState([]);
  const [categoryList, setCList] = useState([]);
  const [projFamilyList, setPFList] = useState([]);
  const [measureValList, setMVList] = useState([]);

  const discount = [
    { name: 'Percentage', code: 'PERCENTAGE' },
    { name: 'Amount', code: 'AMOUNT' },
  ];

  const history = useHistory();
  const { id } = useParams();

  let mainId = JSON.parse(sessionStorage.getItem('id')) ? JSON.parse(sessionStorage.getItem('id')) : 1;
  
  const submitAdd = () => {
    let obj = {
      PRODUCT_NAME: pName,
      CATEGORY_ID: category,
      PRICE: parseInt(price),
      QUANTITY: parseInt(qnty),
      DESCRIPTION: desc,
      PRODUCT_BARCODE: bCode,
      PRODUCT_SUSTAINABILITY: sustain,
      ELIGIBLE_ATUM_COINS: parseInt(coins),
      DISCOUNT_TYPE: dType !== '' ? dType : 'PERCENTAGE',
      DISCOUNT_VALUE: dVal !== '' ? parseFloat(dVal) : 0.00,
      CREATED_BY: mainId,
      UPDATED_BY: mainId,
      PRODUCT_FAMILY_ID: pFId,
      PRODUCT_FAMILY: pFName,
      IS_COLOR_VARIANT: clr,
      PRODUCT_COLOR: clr===true ? cVal : '',
      MEASUREMENT_ID: parseInt(mType),
      MEASUREMENT_NAME: mName, 
      MEASUREMENT_VALUE: mVal,
      MEASUREMENT_DISPLAY_NAME: mDName,
      STORES_INFO: inventory,
      DELETED_STORES: delStore,
      IS_DEFAULT_PRODUCT: dflt,
      NEW_PRODUCT_FAMILY: isFamily,
    };

    axios.post(
      PRODUCTS,
      obj,
    ).then((res) => {
      productImage(res.data.data.ID);
    }).catch((err) => {
      console.log(err);
    });
  };
  
  const submitEdit = () => {
    let obj = {
      ID: rowID,
      PRODUCT_NAME: pName,
      CATEGORY_ID: category,
      PRICE: parseInt(price),
      QUANTITY: parseInt(qnty),
      DESCRIPTION: desc,
      PRODUCT_BARCODE: bCode,
      PRODUCT_SUSTAINABILITY: sustain,
      ELIGIBLE_ATUM_COINS: parseInt(coins),
      DISCOUNT_TYPE: dType !== '' ? dType : 'PERCENTAGE',
      DISCOUNT_VALUE: dVal !== '' ? parseFloat(dVal) : 0.00,
      CREATED_BY: mainId,
      UPDATED_BY: mainId,
      PRODUCT_FAMILY_ID: pFId,
      PRODUCT_FAMILY: pFName,
      IS_COLOR_VARIANT: clr,
      PRODUCT_COLOR: clr===true ? cVal : '',
      MEASUREMENT_ID: parseInt(mType),
      MEASUREMENT_NAME: mName, 
      MEASUREMENT_VALUE: mVal,
      MEASUREMENT_DISPLAY_NAME: mDName,
      STORES_INFO: inventory,
      DELETED_STORES: delStore,
      IS_DEFAULT_PRODUCT: dflt,
      NEW_PRODUCT_FAMILY: isFamily,
    };

    axios.put(
      EDIT_PRODUCT+rowID,
      obj,
    ).then((res) => {
      productImage(rowID);
    }).catch((err) => {
      console.log(err);
    });
  };

  const productImage = (data) => {
    let edit_ID = data;
    
    let formData = new FormData();
    formData.append('productImage1', imgs1);
    formData.append('productImage2', imgs2);
    formData.append('productImage3', imgs3);
    formData.append('productVideo', video);

    axios({
      method: 'PUT',
      url: EDIT_PRODUCT + edit_ID,
      data: formData,
      headers: {'Content-Type': 'multipart/form-data'}
    }).then((res) => {
      setErr(false); history.goBack();
    }).catch((err) => {
      console.log(err);
    });
  };

  const setImg = (e, type) => {
    if (type === 'img1') {
      if (e.target.files[0]) {
        setImgs1(e.target.files[0]);
      } else {
        setImgs1(null);
      }
    } else if (type === 'img2') {
      if (e.target.files[0]) {
        setImgs2(e.target.files[0]);
      } else {
        setImgs2(null);
      }
    } else if (type === 'img3') {
      if (e.target.files[0]) {
        setImgs3(e.target.files[0]);
      } else {
        setImgs3(null);
      }
    };
  };

  const loadFamily = () => {
    axios({
      method: 'GET',
      url: PRODUCT_FAMILY_LIST,
    }).then((res) => {
      setPFList(res.data);
    }).catch((err) => {
      console.log(err);
    });
  };

  const categoryData = () => {
    axios({
      method: 'GET',
      url: ADD_STORE_CATEGORIES_LIST,
    }).then((res) => {
      setCList(res.data);
    }).catch((err) => {
      console.log(err);
    });
  };

  const storeData = () => {
    axios({
      method: 'GET',
      url: STORE_LIST,
    }).then((res) => {
      setSList(res.data);
    }).catch((err) => {
      console.log(err);
    });
  };

  const measurementData = () => {
    axios({
      method: 'GET',
      url: MEASUREMENT_LIST,
    }).then((res) => {
      setMList(res.data);
    }).catch((err) => {
      console.log(err);
    });
  };

  const measurementValData = (id) => {
    let filter = measureList.findIndex(it => id === it.ID);
    
    setMDName(measureList[filter].LOOKUP_DISPLAY_NAME);
    setMName(measureList[filter].LOOKUP_NAME); 

    axios({
      method: 'GET',
      url: MEASUREMENT_VALUE_LIST+measureList[filter].LOOKUP_NAME,
    }).then((res) => {
      setMVList(res.data);
    }).catch((err) => {
      console.log(err);
    });
  };

  const colorData = () => {
    axios({
      method: 'GET',
      url: COLOR_LIST,
    }).then((res) => {
      setClrList(res.data);
    }).catch((err) => {
      console.log(err);
    });
  };

  const handleDefault = () => {
    setPDflt(true); setDEfts('');

    for(let i=0; i<selObj.length; i++){
      if(selObj[i].IS_DEFAULT_PRODUCT===1){
        setDEfts(selObj[i]);
      }
    };
  };
  
  const checkBody = (row) => {
    return <Checkbox 
      inputId={row.ID} name={row.PRODUCT_NAME} 
      value={defts} onChange={() => checkChange(row)} 
      checked={row.ID===defts.ID} 
    />
  };

  const checkChange = (obj) => {
    setDEfts(obj);

    axios({
      method: 'GET',
      url: UPLOAD_MEDIA+obj.ID
    }).then((res) => {
      setPDStore(res.data.STORES_INFO);
    }).catch((err) => {
      console.log(err);
    });
  }; 

  const pImgBody = (row) => {
    return <Image src={IMAGE_DOMAIN + row.IMAGE_PATH1} preview width='45px' height='25px' />
  };

  const submitProduct = () => {
    let obj = {
      ID: defts.ID,
      PRODUCT_NAME: defts.PRODUCT_NAME,
      CATEGORY_ID: defts.CATEGORY_ID,
      PRICE: parseInt(defts.PRICE),
      QUANTITY: parseInt(defts.QUANTITY),
      DESCRIPTION: defts.DESCRIPTION,
      PRODUCT_BARCODE: defts.PRODUCT_BARCODE,
      PRODUCT_SUSTAINABILITY: defts.PRODUCT_SUSTAINABILITY,
      ELIGIBLE_ATUM_COINS: parseInt(defts.ELIGIBLE_ATUM_COINS),
      DISCOUNT_TYPE: defts.DISCOUNT_TYPE,
      DISCOUNT_VALUE: parseFloat(defts.DISCOUNT_VALUE),
      CREATED_BY: mainId,
      UPDATED_BY: mainId,
      PRODUCT_FAMILY_ID: defts.PRODUCT_FAMILY_ID,
      PRODUCT_FAMILY: defts.PRODUCT_FAMILY,
      IS_COLOR_VARIANT: defts.IS_COLOR_VARIANT,
      PRODUCT_COLOR: defts.PRODUCT_COLOR,
      MEASUREMENT_ID: parseInt(defts.MEASUREMENT_ID),
      MEASUREMENT_NAME: defts.MEASUREMENT_NAME, 
      MEASUREMENT_VALUE: parseInt(defts.MEASUREMENT_VALUE),
      MEASUREMENT_DISPLAY_NAME: defts.MEASUREMENT_DISPLAY_NAME,
      STORES_INFO: pDStore,
      DELETED_STORES: [],
      IS_DEFAULT_PRODUCT: parseInt(1),
      NEW_PRODUCT_FAMILY: false,
    };

    axios.put(
      EDIT_PRODUCT+defts.ID,
      obj,
    ).then((res) => {
      setPDflt(false); setDEfts('');
      handleEdit(id);
    });
  };

  const handleEdit = (id) => {
    setEdit(true);

    axios({
      method: 'GET',
      url: UPLOAD_MEDIA+id,
    }).then((res) => {     
      setPName(res.data.PRODUCT_NAME); setPrice(res.data.PRICE); setBCode(res.data.PRODUCT_BARCODE); setSustain(res.data.PRODUCT_SUSTAINABILITY);
      setDType(res.data.DISCOUNT_TYPE); setCoins(res.data.ELIGIBLE_ATUM_COINS); setMType(res.data.MEASUREMENT_ID); setRID(id);
      setMVal(res.data.MEASUREMENT_VALUE); setDflt(res.data.IS_DEFAULT_PRODUCT===1 ? true : false); setCVal(res.data.PRODUCT_COLOR); 
      setName(res.data.PRODUCT_NAME); setDVal(res.data.DISCOUNT_VALUE); setImgs3(res.data.IMAGE_PATH3!=='' ? res.data.IMAGE_PATH3 : null); setVideo(res.data.VIDEO_PATH!=='' ? res.data.VIDEO_PATH : null); 
      setImgs1(res.data.IMAGE_PATH1!=='' ? res.data.IMAGE_PATH1 : null); setImgs2(res.data.IMAGE_PATH2!=='' ? res.data.IMAGE_PATH2 : null); setIList(res.data.STORES_INFO); handleStore(res.data.STORES_INFO);
      setPFId(res.data.PRODUCT_FAMILY_ID); setPFName(res.data.PRODUCT_FAMILY); setCategory(res.data.CATEGORY_ID); setDesc(res.data.DESCRIPTION);
      setSObj(res.data.PRODUCT_FAMILYINFO);

      if(res.data.PRODUCT_FAMILY_ID){
        setDItms(true);
      };

      if(res.data.IS_COLOR_VARIANT===1){
        setCmsr(false); setClr(true);
      }else if(res.data.MEASUREMENT_ID!==0){
        setCmsr(false); setMeasure(true);
      }else if(res.data.IS_COLOR_VARIANT===1 && res.data.MEASUREMENT_ID!==0){
        setCmsr(false); setClr(true); 
        setMeasure(true);
      }else{
        setCmsr(true); setClr(false); 
        setMeasure(false);
      };
    }).catch((err) => {
      console.log(err);
    });
  };

  const handleStore = (res) => {
    let qty = 0;

    for(let i=0; i<res.length; i++){
      qty += res[i].QUANTITY;
    };

    setQnty(qty);
  };

  useEffect(() => {
    loadFamily(); colorData();
    measurementData(); storeData();
    categoryData(); if(id){ handleEdit(id) };
  }, []);

  const handleStoreClose = () => {
    setOStore(!openStore); setEStore(false);
    setStore(''); setSQuantity(0);
  };
  
  const handleIArr = () => {
    const arrs = inventory, storeArr = storeList;
    
    if (store!=='' && sQuantity>=0) {
      
      if(editStore===false){
        let stores = storeArr.filter((it) => store === it.ID);
          
        if(arrs.length>0){
          for(let i=0; i<arrs.length; i++){
            if(arrs[i].STORE_ID!==stores[0].ID){
              arrs.push({
                'ID': arrs.length,
                'STORE_ID': stores[0].ID, 
                'STORE_NAME': stores[0].STORE_NAME, 
                'QUANTITY': sQuantity,
                'NEW_STORE': true,
              });
              break;
            }else{
              arrs[i].QUANTITY += sQuantity; 
              break;
            }
          }
        }else{
          arrs.push({
            'ID': arrs.length,
            'STORE_ID': stores[0].ID, 
            'STORE_NAME': stores[0].STORE_NAME, 
            'QUANTITY': sQuantity,
            'NEW_STORE': true,
          });
        };

        handleStore(arrs); setOStore(false);
        setStore(''); setSQuantity(0); setIList(arrs); 
      }else{
        let stores = storeArr.filter((it) => store === it.ID);


        for(let i=0; i<arrs.length; i++){
          if(storeIdx === arrs[i].ID){
            arrs[i].STORE_ID = stores[0].ID;
            arrs[i].STORE_NAME = stores[0].STORE_NAME;
            arrs[i].QUANTITY =  sQuantity;
            arrs[i].NEW_STORE = false;
          }
        };
        
        setIList(arrs); setOStore(false);
        handleStore(arrs); setSQuantity(0);
        setStore(''); setEStore(false); setIdx(''); 
      };
    };
  };

  const handleOnSubmit = () => {
    if(cmsr===true){
      if(imgs1!==null && pName!=='' && pFName!=='' && (category!=='' && category>0) && (price!=='' && price>0) && bCode!=='' && desc!=='' && sustain!=='' && ((clr===true&&cVal!=='') || (measure===true&&mType!==0&&mVal!==0))){
        if(rowID){
          submitEdit();
        } else {
          submitAdd();
        }
      } else {
        setErr(true);
      }
    }else if(cmsr===false){
      if(imgs1!==null && pName!=='' && pFName!=='' && (category!=='' && category>0) && (price!=='' && price>0) && bCode!=='' && desc!=='' && sustain!=='' && ((clr===true&&cVal!=='') || (measure===true&&mType!==0&&mVal!==0))){
        if(rowID){
          submitEdit();
        } else {
          submitAdd();
        }
      } else {
        setErr(true);
      }
    }
  };

  const handleBack = () => {
    history.goBack(); setEdit(false);

    setPName(''); setCategory(''); setPrice(''); setBCode(''); setSustain(''); 
    setPFName(''); setDType(''); setDVal(''); setCoins(''); setMType(''); setQnty('');
    setMVal(''); setDflt(false); setClr(false); setCVal(''); setDesc(''); setName(''); 
    setImgs3(null); setRID(''); setVideo(null); setIList([]); setImgs1(null); setImgs2(null);
  };

  const submitFamily = () => {
    if(fname){
      setPFName(fname); setDItms(false);
      setIFam(true); setSustain(''); setCategory('');
      setDesc('');

      let obj = {
        FAMILY_NAME: fname,
        COLOR_VARIANTS_ALLOWED: clr,
        MEASURMENT_VARIANTS_ALLOWED: measure,
        CREATED_BY: mainId,
        UPDATED_BY: mainId,
      };

      axios({
        method: 'POST',
        url: ADD_PRODUCT_FAMILY,
        data: obj,
      }).then((res) => {
        loadFamily(); setDflt(true); setFamily(false);
        setPFId(res.data.data.ID);
      }).catch((err) => {
        setPFErr(err.response.data.message); 
        setFamily(true); 
      });
    };
  };

  const storeEdit = (row) => {
    setSQuantity(row.QUANTITY); setEStore(true);
    setStore(row.STORE_ID); setIdx(row.ID); setOStore(true);

    if((qnty - row.QUANTITY) >= 0){
      setQnty(qnty - row.QUANTITY);
    }
  };

  const storeDelete = (row) => {
    let arrs = inventory, arrs2 = [], delArr = delStore;
    let result = arrs.findIndex(item => row.STORE_NAME === item.STORE_NAME);
    
    for(let i=0; i<arrs.length; i++){
      if(i!==result){
        arrs2.push(arrs[i]);
      }else{
        delArr.push(arrs[i]);
      }
    };
  
    if((qnty - row.QUANTITY) >= 0){
      setQnty(qnty - row.QUANTITY);
    }; setIList(arrs2); setDStore(delArr);
  };

  const actionBody = (row) => {
    return(
      <div style={{width: '25%', display: 'flex'}}>
        <div style={{width: '50%'}}> 
          <img src={Edit} style={{width: '25px', height: '20px'}} onClick={() => storeEdit(row)} /> 
        </div>

        <div style={{width: '50%'}}> 
          <img src={Delete} style={{width: '25px', height: '20px'}} onClick={() => storeDelete(row)} /> 
        </div>
      </div>
    )
  };

  const handleProductFamily = (id) => {
    setPFName(id); setPFErr('');

    for(let f=0; f<projFamilyList.length; f++){
      if(projFamilyList[f].FAMILY_NAME === id){
        setPFId(projFamilyList[f].ID); 
        setMeasure(projFamilyList[f].MEASURMENT_VARIANTS_ALLOWED===0 ? false : true);
        setClr(projFamilyList[f].COLOR_VARIANTS_ALLOWED===0 ? false : true);

        if(projFamilyList[f].COLOR_VARIANTS_ALLOWED===1){
          setCmsr(false);
        }else if(projFamilyList[f].MEASUREMENT_VARIANTS_ALLOWED===1){
          setCmsr(false);
        }else if(projFamilyList[f].COLOR_VARIANTS_ALLOWED===1 && projFamilyList[f].MEASUREMENT_VARIANTS_ALLOWED===1){
          setCmsr(false);
        }else{
          setCmsr(true);
        }
      }
    };

    axios({
      method: 'PATCH',
      url: PRODUCT_FAMILY_DETAILS,
      data: { 
        "PRODUCT_FAMILY": id
      }
    }).then((res) => {
      setDesc(res.data.data.DESCRIPTION);
      setSustain(res.data.data.PRODUCT_SUSTAINABILITY);
      setCategory(res.data.data.CATEGORY_ID); setDItms(true);
    }).catch((err) => {
      console.log(err);
    });
  };

  const handleUpdateImg = (img, imgName) => {
    let form = new FormData();

    form.append('PATH', img);
    form.append('NAME', imgName);

    axios({
      method: 'PATCH',
      url: UPDATE_PRODUCT_IMAGE+id+'?action=updateImages',
      data: form,
    }).then((res) => {
      handleEdit(id);
    }).catch((err) => {
      console.log(err);
    });
  };

  const handleDel = (img, imgName) => {
    setObj({'Path': img, 'Name': imgName});
    setDel(true);
  };

  const delImg = () => {
    axios({
      method: 'PATCH',
      url: DELETE_PRODUCT_IMAGE,
      data: {'PATH': obj.Path, 'NAME': obj.Name, 'ID': id}
    }).then((res) => {
      handleEdit(id); setDel(false);
    }).catch((err) => {
      console.log(err);
    });
  };

  return (
    <ScrollPanel style={{ backgroundColor: '#fff', height: '100%', paddingTop: '5vh' }}>
      <Container>
        <Row>
          <Col xs={12}>
            <h4>
              <img src={Back} style={{width: '30px', height: '35px'}} className='backIcon' onClick={handleBack} /> 
              &nbsp;&nbsp;{name!=='' ? `Edit Product - ${name}` : 'Add Product'}
            </h4>
          </Col>

          <Col xs={6} md={3}>
            <div className='product_image'>
              {imgs1 === null ? <label htmlFor='uploadi1' className='pt-3'>
                <img src={Banner} style={{ width: '55px', height: '65px', padding: '0.5%' }} />
                <div className={(err===true&&imgs1===null) ? 'p-error' : ''}>Upload Image 1 *</div>
              </label> :
                <React.Fragment>
                  <div
                    style={{width: '24px', height: '24px'}}
                    onClick={() => setImgs1(null)}
                    className='product_cancel_icon'
                  >x</div>
                  {imgs1==='' ? '' : imgs1!==null && imgs1.name ? <img src={URL.createObjectURL(imgs1)} className="product_view_image" /> : <img src={IMAGE_DOMAIN+imgs1} className='product_view_image' />}
                </React.Fragment>}
              <input id='uploadi1' type='file' style={{ display: 'none' }} onChange={(e) => setImg(e, 'img1')} accept="image/jpeg, image/png," />
              
              {edit===true && <div style={{display: 'flex', position: 'sticky'}}>
                <div style={{textAlign: 'center', width: '100%', cursor: 'pointer'}}>
                  <img src={EditIcon} style={{width: '20px', padding: '1px', border: '1px solid #ffb900', borderRadius: '5px'}} onClick={() => handleUpdateImg(imgs1, 'IMAGE_PATH1')} />
                </div>
              </div>}
            </div>

            {(err===true&&imgs1===null) && <small className='p-error'>Image 1 is required.</small>}
          </Col>

          <Col xs={6} md={3}>
            <div className='product_image'>
              {imgs2 === null ? <label htmlFor='uploadi2' className='pt-3'>
                <img src={Banner} style={{ width: '55px', height: '65px' }} />
                <div>Upload Image 2</div>
              </label> :
                <React.Fragment>
                  <div
                    style={{width: '24px', height: '24px'}}
                    onClick={() => setImgs2(null)}
                    className='product_cancel_icon'
                  >x</div>
                  {imgs2==='' ? '' : imgs2!==null && imgs2.name ? <img src={URL.createObjectURL(imgs2)} className="product_view_image" /> : <img src={IMAGE_DOMAIN+imgs2} className='product_view_image' />}
                </React.Fragment>}
              <input id='uploadi2' type='file' style={{ display: 'none' }} onChange={(e) => setImg(e, 'img2')} accept="image/*" />

              {edit===true && <div style={imgs2===null ? {display: 'none'} : {display: 'flex', position: 'sticky'}}>
                <div style={{textAlign: 'center', width: '50%', cursor: 'pointer'}}>
                  <img src={EditIcon} style={{width: '20px', padding: '1px', border: '1px solid #ffb900', borderRadius: '5px'}} onClick={() => handleUpdateImg(imgs2, 'IMAGE_PATH2')} />
                </div>

                <div style={{textAlign: 'center', width: '50%', cursor: 'pointer'}}>
                  <img src={DeleteIcon} style={{width: '20px', padding: '1px', border: '1px solid red', borderRadius: '5px'}} onClick={() => handleDel(imgs2, 'IMAGE_PATH2')} />
                </div>
              </div>}
            </div>
          </Col>

          <Col xs={6} md={3}>
            <div className='product_image'>
              {imgs3 === null ? <label htmlFor='uploadi3' className='pt-3'>
                <img src={Banner} style={{ width: '55px', height: '65px', padding: '0.5%' }} />
                <div>Upload Image 3</div>
              </label> : <React.Fragment>
                <div
                  style={{width: '24px', height: '24px'}}
                  onClick={() => setImgs3(null)}
                  className='product_cancel_icon'
                >x</div>
                {imgs3!==null && imgs3.name ? <img src={URL.createObjectURL(imgs3)} className="product_view_image" /> : <img src={IMAGE_DOMAIN+imgs3} className='product_view_image' />}
              </React.Fragment>}
              <input id='uploadi3' type='file' style={{ display: 'none' }} onChange={(e) => setImg(e, 'img3')} accept="image/*" />
              
              {edit===true && <div style={imgs3===null ? {display: 'none'} : {display: 'flex', position: 'sticky'}}>
                <div style={{textAlign: 'center', width: '50%', cursor: 'pointer'}}>
                  <img src={EditIcon} style={{width: '20px', padding: '1px', border: '1px solid #ffb900', borderRadius: '5px'}} onClick={() => handleUpdateImg(imgs3, 'IMAGE_PATH3')} />
                </div>

                <div style={{textAlign: 'center', width: '50%', cursor: 'pointer'}}>
                  <img src={DeleteIcon} style={{width: '20px', padding: '1px', border: '1px solid red', borderRadius: '5px'}} onClick={() => handleDel(imgs3, 'IMAGE_PATH3')} />
                </div>
              </div>}
            </div>
          </Col>

          <Col xs={6} md={3}>
            <div className='product_image'>
              {video === null ? <label htmlFor='uploadv1' className='pt-3'>
                <img src={Video} style={{ width: '55px', height: '65px', padding: '0.5%' }} />
                <div>Upload Video</div>
              </label> :
              <React.Fragment>
                <div
                  style={{zIndex: 1001, width: '23px', height: '24px'}}
                  onClick={() => setVideo(null)}
                  className='product_cancel_icon'
                >x</div>
                {video==='' ? '' : video!==null && video.name ? <video controls className="product_view_image">
                  <source src={URL.createObjectURL(video)} type='video/mp4' />
                </video> : <video controls className="product_view_image">
                  <source src={IMAGE_DOMAIN+video} type='video/mp4' />
                </video>}
              </React.Fragment>}
              <input id='uploadv1' type='file' style={{ display: 'none' }} onChange={(e) => e.target.files ? setVideo(e.target.files[0]) : setVideo(null)} accept='video/mp4' />

              {edit===true && <div style={video===null ? {display: 'none'} : {display: 'flex', position: 'sticky'}}>
                <div style={{textAlign: 'center', width: '50%', cursor: 'pointer'}}>
                  <img src={EditIcon} style={{width: '20px', padding: '1px', border: '1px solid #ffb900', borderRadius: '5px'}} onClick={() => handleUpdateImg(video, 'VIDEO_PATH')} />
                </div>

                <div style={{textAlign: 'center', width: '50%', cursor: 'pointer'}}>
                  <img src={DeleteIcon} style={{width: '20px', padding: '1px', border: '1px solid red', borderRadius: '5px'}} onClick={() => handleDel(video, 'VIDEO_PATH')} />
                </div>
              </div>}
            </div>
          </Col>

          <Col xs={12} sm={3} className={edit===true ? 'mt-4' : 'mt-1'}>
            <label className={(err===true&&pName==='') ? 'p-error' : ''}>Product Name *</label>
            <InputText style={{width: '95%'}} value={pName} onChange={(e) => setPName(e.target.value)}  />
            {(err===true&&pName==='') && <small className="p-error">Product Name is required.</small>}
          </Col>
          
          <Col xs={12} sm={3} className={edit===true ? 'mt-4' : 'mt-1'}>
            <label className={(err===true&&pFName===''&&pfErr!=='') ? 'p-error' : ''}>Product Family *</label>

            <div style={{display: 'flex', flexDirection: 'row'}}>
              <div style={{width: '82%'}}>
                <Dropdown 
                  style={{width: '98%'}} options={projFamilyList} filter
                  optionValue='FAMILY_NAME' optionLabel='FAMILY_NAME' value={pFName}
                  onChange={(e) => handleProductFamily(e.value)} filterBy='FAMILY_NAME'
                />
                {(err===true&&pFName==='') && <small className="p-error">{pfErr==='' ? 'Product Family is required.' : pfErr}</small>}
              </div>
            
              <div style={{width: '10%'}} onClick={() => setFamily(true)}>
                <img src={Add} style={{
                  width: '25px', height: '20px', 
                  marginLeft: '2px',
                }} />
              </div>
            </div>
          </Col>

          <Col xs={12} sm={3} className={edit===true ? 'mt-4' : 'mt-1'}>
            <label className={(err===true&&category==='') ? 'p-error' : ''}>Category *</label>
            <Dropdown 
              options={categoryList} optionLabel='NAME' optionValue='ID'
              filter filterBy='NAME' style={{width: '95%'}}
              value={category} onChange={(e) => setCategory(e.value)}
              disabled={disItms}
            />
            {(err===true&&category==='') && <small className='p-error'>Category is required.</small>}
          </Col>

          <Col xs={12} sm={3} className={edit===true ? 'mt-4' : 'mt-1'}>
            <label className={(err===true&&price==='') ? 'p-error' : ''}>Price *</label>
            <InputText 
              style={{width: '95%'}} value={price} 
              onChange={(e) => setPrice(e.target.value)} 
              onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
            />
            {(err===true&&price==='') ? <small className='p-error'>Price is required.</small> : (err===true&&price<=0) ? <small className='p-error'>Price should not be 0 (or) less than 0</small> : ''}
          </Col>

          <Col xs={12} sm={3}>
            <label>Discount Type</label>
            <Dropdown 
              style={{width: '95%'}} options={discount} value={dType}
              optionLabel="name" optionValue='code' onChange={(e) => setDType(e.target.value)}
            />
          </Col>

          <Col xs={12} sm={3}>
            <label>Discount Value</label>
            <InputText 
              style={{width: '95%'}} value={dVal} 
              onChange={(e) => setDVal(e.target.value)}
              onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} 
            />
          </Col>

          <Col xs={12} sm={3}>
            <label className={(err===true&&coins==='') ? 'p-error' : ''}>Eligible ATUM Coins *</label>
            <InputText 
              style={{width: '95%'}} value={coins}
              onChange={(e) => setCoins(e.target.value)} 
              onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} 
            />
            {(err===true&&coins==='') && <small className='p-error'>Eligible ATUM Coins is required.</small>}
          </Col>

          <Col xs={12} sm={3}>  
            <label>Quantity</label>
            <InputText style={{width: '95%'}} value={qnty} />        
          </Col>
          
          <Col xs={12} sm={3}>
            <label className={(err===true&&bCode==='') ? 'p-error' : ''}>Barcode *</label>
            <InputText 
              style={{width: '95%'}} value={bCode}
              onChange={(e) => setBCode(e.target.value)}
              onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} 
            />
            {(err===true&&bCode==='') && <small className='p-error'>Barcode is required.</small>}
          </Col>

          <Col xs={12} sm={2} className='mt-4'>
            <Checkbox checked={dflt} onChange={(e) => setDflt(e.checked)} disabled={edit} />&nbsp;&nbsp;
            <label>Default</label>
            {edit && <Button label='Change Default' 
              onClick={handleDefault} style={{ 
              marginTop: '5px', padding: '4px',
              paddingLeft: '6px', paddingRight: '6px' 
            }} />}
          </Col>

          <Col xs={12} sm={2} className='mt-4'>
            <Checkbox checked={clr} disabled={true} />&nbsp;&nbsp;
            <label>Color Variant</label> 
          </Col>

          <Col xs={12} sm={2} className='mt-4'>
            <Checkbox checked={measure} disabled={true} />&nbsp;&nbsp;
            <label>Measure Variant</label> 
          </Col>
          
          {clr===true && <Col xs={12} sm={3}>
            <label className={(err===true&&clr===true&&cVal==='') ? 'p-error': ''}>Color *</label>
            <Dropdown 
              style={{width: '95%'}} value={cVal}
              onChange={(e) => setCVal(e.value)} options={colorList} 
              optionLabel='LOOKUP_DISPLAY_NAME' optionValue='LOOKUP_NAME' 
            />  
            {(err===true&&clr===true&&cVal==='') && <small className='p-error'>Color is required.</small>}
          </Col>}

          {measure===true && <Col xs={12} sm={3}>
            <label className={(err===true&&mType===0) ? 'p-error': ''}>Measurement Type *</label>
            <Dropdown 
              optionValue='ID' style={{width: '95%'}} value={mType}
              options={measureList} optionLabel='LOOKUP_DISPLAY_NAME' 
              onChange={(e) => {measurementValData(e.value); setMType(e.value)}}
            /> 
            {(err===true&&mType===0) ?<small className='p-error'>Measurement Type is required.</small> : ''}
          </Col>}

          {measure===true && <Col xs={12} sm={3}>
            <label className={(err===true&&mVal===0) ? 'p-error': ''}>Measurement Value *</label>
            <Dropdown 
              style={{width: '95%'}} value={mVal}
              onChange={(e) => setMVal(e.value)} optionValue='LOOKUP_DISPLAY_NAME'
              options={measureValList} optionLabel='LOOKUP_DISPLAY_NAME' 
            />
            {(err===true&&mVal===0) ? <small className='p-error'>Measurement Value is required.</small>
            :''}
          </Col>}

          {measure===true && <Col xs={12} sm={4}>&nbsp;</Col>}

          <Col xs={12} sm={6} className='mt-2'>
            <label className={(err===true&&desc==='') ? 'p-error' : ''}>Description *</label>
            <InputTextarea 
              style={{width: '98%'}} value={desc} 
              onChange={(e) => setDesc(e.target.value)}
              disabled={disItms}
            />
            {(err===true&&desc==='') && <small className='p-error'>Description is required.</small>}
          </Col>

          <Col xs={12} sm={6} className='mt-2'>
            <label className={(err===true&&sustain==='') ? 'p-error': ''}>Product Sustainability *</label>
            <InputTextarea 
              style={{width: '98%'}} value={sustain} 
              onChange={(e) => setSustain(e.target.value)}
              disabled={disItms}
            />
            {(err===true&&sustain==='') && <small className='p-error'>Product Sustainability is required.</small>}
          </Col>

          <Col xs={12}>
            <div style={{textAlign: 'center'}}>
              <Button label='Add Product Inventory' className='mt-2' onClick={() => setOStore(true)} />
              
              <DataTable value={inventory} removableSort dataKey="id">
                <Column header='Store Name' field='STORE_NAME' sortable />
                <Column header='Quantity' field='QUANTITY' sortable />
                <Column header='Actions' body={actionBody} />
              </DataTable>
            </div>
          </Col>

          <Col xs={12}>
            <div style={{margin: '2%', marginRight: '0%', textAlign: 'end'}}>
              <Button label='cancel' onClick={handleBack} style={{marginRight: '2vw'}} />
              <Button label='Submit' onClick={handleOnSubmit} />
            </div>
          </Col>
        </Row>  
      </Container>

      {/* Add Product Inventory Dialog */}
      <Dialog 
        visible={openStore} header={<div style={{textAlign: 'center'}}>
          Product Inventory
        </div>} onHide={handleStoreClose}
      >
        <Container>
          <Row>
            <Col xs={12} sm={6}>
              <label>Stores *</label>
              <Dropdown 
                options={storeList} optionLabel='STORE_NAME'
                style={{width: '95%'}} value={store} optionValue='ID'
                onChange={(e) => setStore(e.value)} 
              />
            </Col>

            <Col xs={12} sm={6}>
              <label>Quantity *</label>
              <InputNumber 
                style={{width: '95%'}} value={sQuantity} 
                onValueChange={(e) => setSQuantity(e.target.value)} 
              />
            </Col>

            <Col xs={12} className='mt-3' style={{textAlign: 'center'}}>
              <Button label='Submit' onClick={handleIArr} />
            </Col>
          </Row>
        </Container>
      </Dialog>

      {/* Default Product pop-up */}
      <Dialog 
        visible={popDeflt} onHide={() => setPDflt(false)}
        header={<div>Default Product</div>} style={{width: '45vw', height: '65vh'}}
        footer={<div style={{ textAlign: 'center' }}>
          <Button label='Submit' onClick={submitProduct} />
        </div>}
      >
        <div>
          <DataTable value={selObj}>
            <Column header='Image' body={pImgBody} />
            <Column header='Product name' field='PRODUCT_NAME' />
            <Column header='Default' body={checkBody} />
          </DataTable>
        </div>
      </Dialog>

      {/* Add Product Family Dialog */}
      <Dialog 
        visible={family} onHide={() => setFamily(false)}
        header={<div>Add Product Family</div>} style={{width: '30vw'}}
      >
        <div className='mt-1'>
          <h6 className={pfErr!=='' ? 'p-error' : ''}>Product Family</h6>
          <InputText 
            style={{width: '98%'}} value={fname}
            onChange={(e) => { setFName(e.target.value); setPFErr('')}} 
          />
          {pfErr!=='' && <small className='p-error'>{pfErr}</small>}
        </div>

        <div className='mt-2' style={{display: 'flex'}}>
          <div style={{width: '50%'}}>
            <Checkbox checked={clr} onChange={(e) => setClr(e.checked)} />
            &nbsp;&nbsp;<label>Color Variant</label>
          </div>

          <div style={{width: '50%'}}>
            <Checkbox checked={measure} onChange={(e) => setMeasure(e.checked)} />
            &nbsp;&nbsp;<label>Measure Variant</label>
          </div>
        </div>

        <div style={{textAlign: 'center', marginTop: '2vh'}}>
          <Button 
            label='Submit' style={{width: '95%'}} 
            disabled={pfErr!=='' ? true : false} 
            onClick={submitFamily}
          />
        </div>
      </Dialog>

      {/* Delete Image  */}
      <Dialog 
        visible={del} onHide={() => setDel(false)}
        header='Do you want to delete the Image ?' style={{width: '30vw'}}
      >
        <div style={{textAlign: 'center'}}>
          <Button onClick={() => {setObj(''); setDel(false)}} style={{marginRight: '5px'}}>No</Button>
          <Button onClick={delImg} style={{marginLeft: '5px'}}>Yes</Button>
        </div>
      </Dialog>
    </ScrollPanel>
  );
};