import React, { useState, useEffect } from "react";

import axios from 'axios';

import { Container, Row, Col, Button } from "reactstrap";

import { useHistory } from 'react-router-dom';

import { InputText } from "primereact/inputtext";
import { Password } from 'primereact/password';
import { Dropdown } from "primereact/dropdown";

import { EDIT_USERS } from '../../config/config.js';

const Profile = () => {
    const history = useHistory();

    let fileInput = {}; fileInput.value = '';
    const localSiteData = JSON.parse(sessionStorage.getItem('id'));

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [gender, setGender] = useState('');
    const [password, setPass] = useState('');

    const sex = [
        {
            value: 'Male',
            label: 'Male',
        },
        {
            value: 'Female',
            label: 'Female',
        },
        {
            value: 'Other',
            label: 'Other',
        }
    ];

    useEffect(() => {
        axios({
            method: 'get',
            url: EDIT_USERS + localSiteData
        }).then((res) => {
            setFirstName(res.data.FIRST_NAME);
            setLastName(res.data.LAST_NAME);
            setEmail(res.data.EMAIL);
            setMobile(res.data.MOBILE_NUMBER);
            setGender(res.data.GENDER);
            setPass(res.data.PASSWORD);
        }).catch((err) => {
            console.log(err);
        })
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        let FIRST_NAME = firstName;
        let LAST_NAME = lastName;
        let EMAIL = email;
        let MOBILE_NUMBER = mobile;
        let GENDER = gender;

        axios({
            method: 'put',
            url: EDIT_USERS + localSiteData,
            'Content-Type': 'multipart/form-data',
            data: {
                FIRST_NAME,
                LAST_NAME,
                EMAIL,
                MOBILE_NUMBER,
                GENDER
            },
            headers: { token: sessionStorage.getItem('token') }
        }).then(response => {
            if (response) {
                history.push(`/home`);
            }
        });
    };

    return (
        <div className="pt-4 m-3">
            <div style={{ width: "100%", backgroundColor: '#fff' }} className='p-4'>
                <h3 className="mb-3">Profile</h3>
                <Container>
                    <Row>
                        <Col xs={3}>
                            <div style={{border: '1px solid #ccc', borderRadius: '5px', height: '100%'}}>&nbsp;</div>
                        </Col>

                        <Col xs={9}>
                            <Row>
                                <Col xs={6} style={{textAlign: 'left'}}>
                                    <label>First Name</label>
                                    <InputText style={{width: '100%'}} value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                </Col>

                                <Col xs={6} style={{textAlign: 'left'}}>
                                    <label>Last Name</label>
                                    <InputText style={{width: '100%'}} value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                </Col>

                                <Col xs={6} style={{textAlign: 'left'}}>
                                    <label>Email ID</label>
                                    <InputText style={{width: '100%'}} value={email} onChange={(e) => setEmail(e.target.value)} />
                                </Col>

                                <Col xs={6} style={{textAlign: 'left'}}>
                                    <label>Password</label> 
                                    <Password style={{width: '100%'}} value={password} onChange={(e) => setPass(e.target.value)} disabled={true} />
                                </Col>

                                <Col xs={6} style={{textAlign: 'left'}}>
                                    <label>Mobile Number</label>
                                    <InputText style={{width: '100%'}} value={mobile} onChange={(e) => setMobile(e.target.value)} />
                                </Col>

                                <Col xs={6} style={{textAlign: 'left'}}>
                                    <label>Gender</label>
                                    <Dropdown 
                                        style={{width: '100%'}} value={gender} 
                                        onChange={(e) => setGender(e.target.value)} 
                                        options={sex} optionLabel="label" optionValue="value"
                                    />
                                </Col>
                            </Row> 
                        </Col>

                        <Col xs={12} className='status_button text-center'>
                            <Button type="submit" variant="contained" onClick={handleSubmit} className='contained_primary px-5'>Submit </Button>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default Profile;