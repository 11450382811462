import React, { useState } from 'react';

import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';

import Filter from '../Sidebar/icons/filter.svg';
import './index.css';

export default function Inventory () {

    const [tableData, setTableData] = useState([]);
    const [filter, setFilter] = useState('');

    const tableHeader = () => {
        return(
            <div className='DataTable'>
                <div className='m-2'>
                    <h3>Product Inventory List</h3>
                </div>

                <div className='table-header-action'>
                    <div style={{ marginRight: '0.5vw' }}>
                        <Dropdown placeholder='Select Product' value={filter} className='dropzone1' />
                    </div>

                    <div>
                        <Button label='Filter' style={{ height: '6vh' }} icon={<img src={Filter} style={{marginRight: '3px', width: '25px', height: '20px'}} />}  />
                    </div>
                </div>
            </div>
        )
    };

    return (
        <div className='pt-4 m-3'>
            <div style={{ width: "100%" }} className='datatable'>
                <DataTable 
                    removableSort header={tableHeader} 
                    value={tableData}
                >
                    <Column header='Store Name' sortable />
                    <Column header='Quantity' sortable />
                </DataTable>
            </div>
        </div>
    )
};